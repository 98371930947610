<form class="space-y-5 p-5" [formGroup]="form" (ngSubmit)="submitForm()" novalidate>
  <div class="grid grid-cols-1 gap-5 md:grid-cols-4">
    <div class="md:col-span-2" [ngClass]="form.controls['start'].touched ? (form.controls['start'].errors ? 'has-error' : 'has-success') : ''">
      <label for="customStart">{{ "travel_create.begin" | translate }}</label>
      <input #startInput id="customStart" type="text" class="form-input" formControlName="start" class="form-input" placeholder="__.__.____" [textMask]="{ mask: dateMask }" />
      <ng-container *ngIf="form.controls['start'].touched && !form.controls['start'].errors">
        <p class="mt-1 text-[#1abc9c]">{{ "travel_create.looks_good" | translate }}</p>
      </ng-container>
      <ng-container *ngIf="form.controls['start'].touched && form.controls['start'].errors">
        <p class="mt-1 text-danger">{{ "travel_create.please_provide_begin" | translate }}</p>
      </ng-container>
    </div>
    <div [ngClass]="form.controls['days'].touched ? (form.controls['days'].errors ? 'has-error' : 'has-success') : ''" (focusout)="setDaysNights()">
      <label for="customDays">{{ "travel_create.days" | translate }}</label>
      <input id="customDays" type="text" placeholder="{{ 'travel_create.num_of_days' | translate }}" class="form-input" formControlName="days" />
      <ng-container *ngIf="form.controls['days'].touched && form.controls['days'].errors">
        <p class="mt-1 text-danger">{{ "travel_create.please_provide_days" | translate }}</p>
      </ng-container>
    </div>
    <div [ngClass]="form.controls['nights'].touched ? (form.controls['nights'].errors ? 'has-error' : 'has-success') : ''">
      <label for="customNights">{{ "travel_create.nights" | translate }}</label>
      <input id="customNights" type="text" placeholder="{{ 'travel_create.num_of_nights' | translate }}" class="form-input" formControlName="nights" />
      <ng-container *ngIf="form.controls['nights'].touched && form.controls['nights'].errors">
        <p class="mt-1 text-danger">{{ "travel_create.please_provide_nights" | translate }}</p>
      </ng-container>
    </div>
  </div>
  <div class="grid grid-cols-1 gap-5 md:grid-cols-4">
    <div class="md:col-span-2" [ngClass]="form.controls['end'].touched ? (form.controls['end'].errors ? 'has-error' : 'has-success') : ''">
      <label for="customEnd">{{ "travel_create.finish" | translate }}</label>
      <input (blur)="onEndBlur()" id="customEnd" type="text" class="form-input" formControlName="end" placeholder="__.__.____" [textMask]="{ mask: dateMask }" />
      <ng-container *ngIf="form.controls['end'].touched && !form.controls['end'].errors">
        <p class="mt-1 text-[#1abc9c]">{{ "travel_create.looks_good" | translate }}</p>
      </ng-container>
      <ng-container *ngIf="form.controls['end'].touched && form.controls['end'].errors">
        <p class="mt-1 text-danger">{{ "travel_create.please_provide_finish" | translate }}</p>
      </ng-container>
    </div>
    <div class="md:col-span-2" [ngClass]="form.controls['departure'].touched ? (form.controls['departure'].errors ? 'has-error' : 'has-success') : ''" *ngIf="form.controls['type'].value?.id !== 7">
      <label for="customDeparture">{{ "travel_create.departure" | translate }}</label>
      <app-autocomplete-remote
        [type]="EntityPicklistType.Destinations"
        labelName="description"
        placeholder="{{ 'travel_create.enter_departure' | translate }}"
        [selectControl]="form"
        labelForId="customDeparture"
        formControlName="departure"
        [addCallback]="utilService.destinationAdd('departure', form).bind(utilService)"
      ></app-autocomplete-remote>
      <ng-container *ngIf="form.controls['departure'].touched && !form.controls['departure'].errors">
        <p class="mt-1 text-[#1abc9c]">{{ "travel_create.looks_good" | translate }}</p>
      </ng-container>
      <ng-container *ngIf="form.controls['departure'].touched && form.controls['departure'].errors">
        <p class="mt-1 text-danger">{{ "travel_create.depart" | translate }}</p>
      </ng-container>
    </div>
  </div>
  <div class="grid grid-cols-1 gap-5 md:grid-cols-3">
    <div [ngClass]="form.controls['type'].touched ? (form.controls['type'].errors ? 'has-error' : 'has-success') : ''">
      <label for="customType">{{ "travel_create.type" | translate }}</label>
      <app-select-remote id="customType" [type]="PicklistType.Arragement" [selectControl]="form" labelForId="customType" formControlName="type"></app-select-remote>
      <ng-container *ngIf="form.controls['type'].touched && !form.controls['type'].errors">
        <p class="mt-1 text-[#1abc9c]">{{ "travel_create.looks_good" | translate }}</p>
      </ng-container>
      <ng-container *ngIf="form.controls['type'].touched && form.controls['type'].errors">
        <p class="mt-1 text-danger">{{ "travel_create.please_provide_type" | translate }}</p>
      </ng-container>
    </div>
    <div [ngClass]="form.controls['organizer'].touched ? (form.controls['organizer'].errors ? 'has-error' : 'has-success') : ''">
      <label for="customOrganizer">{{ "travel_create.organizer" | translate }}</label>
      <app-autocomplete-remote
        id="customOrganizer"
        [type]="EntityPicklistType.Organizers"
        labelName="name"
        placeholder="{{ 'travel_create.enter_organizer' | translate }}"
        [selectControl]="form"
        labelForId="customOrganizer"
        formControlName="organizer"
        [addCallback]="utilService.organizerAdd('organizer', form).bind(utilService)"
      ></app-autocomplete-remote>
      <ng-container *ngIf="form.controls['organizer'].touched && !form.controls['organizer'].errors">
        <p class="mt-1 text-[#1abc9c]">{{ "travel_create.looks_good" | translate }}</p>
      </ng-container>
      <ng-container *ngIf="form.controls['organizer'].touched && form.controls['organizer'].errors">
        <p class="mt-1 text-danger">{{ "travel_create.please_provide_organizer" | translate }}</p>
      </ng-container>
    </div>
    <div [ngClass]="form.controls['avans'].touched ? (form.controls['avans'].errors ? 'has-error' : 'has-success') : ''" *ngIf="showAvans">
      <label for="avans">{{ "travel_create.avans" | translate }}</label>
      <input id="avans" type="number" appOnlyNumber placeholder="{{ 'travel_create.avans_placeholder' | translate }}" class="form-input" formControlName="avans" />
      <ng-container *ngIf="form.controls['avans'].touched && form.controls['avans'].errors">
        <p class="mt-1 text-danger">{{ "travel_create.please_provide_avans" | translate }}</p>
      </ng-container>
    </div>
  </div>
</form>
