import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BaseHttpService } from "src/app/service/base.http.service";
import { FormGroup } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class UtilService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }
  destinationAdd(key: string, form: FormGroup) {
    return (value: string) => {
      this.http.post(`${this.apiUrl}/api/destinations`, { description: value }).subscribe((res) => {
        form.get(key)?.setValue(res);
      });
    };
  }

  organizerAdd(key: string, form: FormGroup) {
    return (value: string) => {
      this.http.post(`${this.apiUrl}/api/organizers`, { name: value }).subscribe((res) => {
        form.get(key)?.setValue(res);
      });
    };
  }

  companiesAdd(key: string, form: FormGroup) {
    return (value: string) => {
      this.http.post(`${this.apiUrl}/api/companies`, { name: value }).subscribe((res) => {
        form.get(key)?.setValue(res);
      });
    };
  }

  hotelsAdd(key: string, form: FormGroup) {
    return (value: string) => {
      this.http.post(`${this.apiUrl}/api/hotels`, { name: value }).subscribe((res) => {
        form.get(key)?.setValue(res);
      });
    };
  }
}
