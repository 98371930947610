import { Component, OnChanges, Input, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { StatisticOrganizerProfit } from "src/app/domain/statisticsmoney/models/statisticorganizer";

@Component({
  selector: "app-organizer-statistics-profit",
  templateUrl: "./organizer-statistics-profit.component.html",
})
export class OrganizerStatisticsProfitComponent implements OnChanges {
  constructor(public translate: TranslateService) {}

  @Input() statisticsOrganizerProfit: StatisticOrganizerProfit[] = [];
  simpleColumnStackedOrganizerProfit: any;

  ngOnChanges(changes: SimpleChanges) {
    if (changes["statisticsOrganizerProfit"] && this.statisticsOrganizerProfit.length > 0) {
      this.updateChartOrganizerProfit();
    }
  }
  updateChartOrganizerProfit() {
    if (this.statisticsOrganizerProfit && this.statisticsOrganizerProfit.length > 0) {
      const organizerNames = this.statisticsOrganizerProfit.map((item) => item.organizerName);
      const profit = this.statisticsOrganizerProfit.map((item) => item.profit);
      this.simpleColumnStackedOrganizerProfit = {
        chart: {
          height: 350,
          type: "bar",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 1,
        },
        colors: ["#2196f3", "#3b3f5c", "#ff9800", "#4caf50"],
        xaxis: {
          categories: organizerNames,
          axisBorder: {
            color: "#191e3a",
          },
        },
        yaxis: {
          opposite: false,
          reversed: false,
        },
        grid: {
          borderColor: "#ADD8E6",
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        fill: {
          opacity: 0.8,
        },
        series: [
          {
            name: "Profit",
            data: profit,
          },
        ],
      };
    }
  }
}
