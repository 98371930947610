export enum PicklistType {
  Service = "service",
  Arragement = "arrangement",
  Value3 = "String3",
  Vehicle = "Vehicle",
  OtherServices = "other-services",
	PaymentType = "payment-type",
}

export enum EntityPicklistType {
  Destinations = "destinations",
  Companies = "companies",
  Organizers = "organizers",
  Hotels = "hotels",
  Customers = "customers",
}
