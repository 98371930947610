import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { colDef } from "@bhplugin/ng-datatable";
import { Organizer } from "../models/oragnizers";
import { OrganizersService } from "../services/organizers.service";
import { animate, style, transition, trigger } from "@angular/animations";
import { TranslateService } from "@ngx-translate/core";
import { ModalComponent } from "angular-custom-modal";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { PopupType } from "src/app/shared/enums/popup-types";
import { BaseTableComponent, DatatableColumn } from "src/app/shared/components/base-table/base-table.component";
import { TableService } from "src/app/shared/services/table.service";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/store/index.selector";
import { selectFromTo } from "src/app/store/datepicker/datetime.selector";
import { map, Observable } from "rxjs";
import { MessagePortalComponent } from "src/app/portal/portal";
import { PositioningService } from "src/app/shared/services/positioning.service";

@Component({
  templateUrl: "./organizers.table.component.html",
  animations: [
    trigger("toggleAnimation", [
      transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
      transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
    ]),
  ],
})
export class OrganizersTableComponent extends BaseTableComponent<Organizer> {
  @ViewChild("modal") modal!: ModalComponent;
  public organizers: Organizer[] = [];
  public editMode = false;

  organizerId = 0;
  form!: FormGroup;
  mergeMode = false;
  foundOrganizer: Organizer[] = [];
  name: any = "";
  filteredOrganizers: Organizer[] = [];
  selectedOrganizerIds: number[] = [];
  constructor(
    private organizersService: OrganizersService,
    public override translate: TranslateService,
    private fb: FormBuilder,
    public override tableService: TableService,
    public override store: Store<AppState>,
    private positioningService: PositioningService,
    private ref: ChangeDetectorRef
  ) {
    super(store, translate, selectFromTo, tableService, `organizers/paginated`);
  }
  @ViewChild("dropdownPlaceholder") dropdownPlaceholder!: ElementRef;
  @ViewChild(MessagePortalComponent) messagePortalComponent!: MessagePortalComponent;

  onPortalAttached() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 0);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.initForm();
    this.loadOrganizers();
  }
  loadOrganizers() {
    this.filteredOrganizers = [];
    this.foundOrganizer = [];
    this.selectedOrganizerIds = [];

    this.organizer = this.organizersService.getOrganizernew().pipe(map((organizers: Organizer[]) => organizers.filter((organizer) => organizer.id !== this.organizerId)));
  }
  organizer!: Observable<Organizer[]>;
  selectedOrganizer: Organizer[] = [];

  mergeSelectedOrganizer() {
    this.selectedOrganizerIds = this.selectedOrganizer.map((person: Organizer) => person.id);

    if (this.selectedOrganizerIds.length > 0 && this.organizerId) {
      this.organizersService.mergeOrganizers(this.organizerId, this.selectedOrganizerIds).subscribe((response) => {
        if (response.includes("Merged organizers")) {
          showMessage(PopupType.Success, this.translate.instant("hotel_data.hotels_merged_successfully"));
          this.modal.close();
        } else {
          showMessage(PopupType.Warning, this.translate.instant("hotel_data.error_merging_hotels"));
        }
      });
    } else {
      showMessage(PopupType.Warning, this.translate.instant("hotel_data.select_hotels_to_merge"));
    }
  }

  initForm() {
    this.form = this.fb.group({
      name: ["", Validators.required],
      firstName: [""],
      lastName: [""],
      phone: [""],
      licence: [""],
      email: ["", Validators.required],
      address: [""],
    });
  }

  private fetchOrganizers() {
    this.organizersService.getOragnizersWithHeaders(this.pageNumber, this.pageSize, this.filterType).subscribe((response) => {
      this.organizers = response.body;

      const pagination = JSON.parse(response.headers.get("X-Pagination") || "");
      this.pageNumber = pagination.CurrentPage;
      this.pageSize = pagination.PageSize;
      this.totalRows = pagination.TotalCount;
    });
  }

  // Delete request is missing !
  override getColumns(): void {
    this.cols = [
      { field: "id", title: this.translate.instant("organizator_info.id"), isUnique: true, hide: true } as DatatableColumn,
      { field: "name", title: this.translate.instant("organizator_info.name"), hide: false } as DatatableColumn,
      { field: "fullName", title: this.translate.instant("organizator_info.agency_representative"), hide: false, sort: false } as DatatableColumn,
      { field: "createdAt", title: this.translate.instant("organizator_info.created_at"), hide: false } as DatatableColumn,
      { field: "actions", title: this.translate.instant("organizator_info.actions"), hide: false, sort: false } as DatatableColumn,
    ];
  }
  public exportCols = [
    { field: "customerNameAndSurname", title: this.translate.instant("organizers-export.customerNameAndSurname"), isUnique: true, hide: true } as DatatableColumn,
    { field: "customerPhoneNumber", title: this.translate.instant("organizers-export.customerPhoneNumber"), hide: false, sort: false } as DatatableColumn,
    { field: "numberOfPassangers", title: this.translate.instant("organizers-export.numberOfPassangers"), hide: false } as DatatableColumn,
    { field: "checkInDate", title: this.translate.instant("organizers-export.checkInDate"), hide: false } as DatatableColumn,
    { field: "caheckOutDate", title: this.translate.instant("organizers-export.caheckOutDate"), hide: false } as DatatableColumn,
    { field: "bruto", title: this.translate.instant("organizers-export.bruto"), hide: false } as DatatableColumn,
    { field: "neto", title: this.translate.instant("organizers-export.neto"), hide: false } as DatatableColumn,
    { field: "profit", title: this.translate.instant("organizers-export.profit"), hide: false } as DatatableColumn,
    { field: "status", title: this.translate.instant("organizers-export.status"), hide: false } as DatatableColumn,
    { field: "purchaseDate", title: this.translate.instant("organizers-export.purchaseDate"), hide: false } as DatatableColumn,
    { field: "user", title: this.translate.instant("organizers-export.user"), hide: false } as DatatableColumn,
  ];

  addOrganizer() {
    this.editMode = false;
    this.mergeMode = false;
    this.form.reset();
    this.modal.open();
  }

  updateOrganizer(id: number) {
    this.editMode = true;
    this.modal.open();
    this.organizerId = id;
    this.getOrganizer(id);
    this.mergeMode = false;
  }

  addMerge(id: number, name: any) {
    this.organizerId = id;
    this.name = name;
    this.mergeMode = true;
    this.editMode = false;
    this.modal.open();
    this.foundOrganizer = [];
    this.filteredOrganizers = [];
    this.selectedOrganizer = [];
  }

  submitForm() {
    if (this.form.valid) {
      if (this.editMode) {
        this.organizersService.updateOrganizer(this.form.value, this.organizerId).subscribe(() => {
          this.fetchOrganizers();
          this.modal.close();
          showMessage(PopupType.Success, this.translate.instant("organizer_data.updated_organizer_successfully"));
        });
      } else {
        this.organizersService.addOrganizer(this.fetchOrganizerData()).subscribe(() => {
          this.fetchOrganizers();
          this.modal.close();
          showMessage(PopupType.Success, this.translate.instant("organizer_data.added_organizer_successfully"));
        });
      }
    } else {
      this.form.markAllAsTouched();
      showMessage(PopupType.Warning, this.translate.instant("organizer_data.please_fill_all_fields"));
    }
  }

  fetchOrganizerData() {
    const { name, firstName, lastName, phone, licence, email, address } = this.form.value;
    return { name, firstName, lastName, phone, licence, email, address };
  }

  fillForm(data: Organizer) {
    this.form.patchValue({
      name: data.name,
      firstName: data.fullName.split(" ")[0],
      lastName: data.fullName.split(" ")[1],
      phone: data.phone,
      licence: data.licence,
      email: data.email,
      address: data.address,
    });
  }

  getOrganizer(id: number) {
    this.organizersService.getOrganizer(id).subscribe((data) => {
      this.fillForm(data);
    });
  }
}
