import { Component, Input, ViewChild, ViewContainerRef } from "@angular/core";
@Component({
  moduleId: module.id,
  selector: "icon-menu-bus",
  styleUrls: ["../icons.css"],
  template: `
    <ng-template #template>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 20 20"
        style="enable-background:new 0 0 20 20;"
        xml:space="preserve"
      >
        <g>
          <path
            class="st0"
            d="M17.7,4.8c0.2,0,0.5,0,0.7,0.1c0.5,0.1,0.8,0.5,0.8,1c0,0.9,0,1.8,0,2.7c0,0.6-0.5,1-1,1.1c-0.1,0-0.3,0-0.4,0
		 c0,0.1,0,0.2,0,0.3c0,1.8,0,3.6,0,5.4c0,1.1-0.4,1.7-1.5,2c0,0.2,0,0.5,0,0.7c0,0.5-0.3,0.8-0.8,0.8c-0.7,0-1.4,0-2.1,0
		 c-0.5,0-0.8-0.3-0.8-0.8c0-0.2,0-0.4,0-0.7c-1.7,0-3.4,0-5.2,0c0,0.2,0,0.4,0,0.6c0,0.6-0.3,0.9-0.9,0.9c-0.7,0-1.3,0-2,0
		 c-0.5,0-0.8-0.3-0.8-0.8c0-0.2,0-0.4,0-0.7c-0.1,0-0.1,0-0.2-0.1c-0.8-0.3-1.3-0.9-1.3-1.8c0-1.9,0-3.7,0-5.6c0-0.1,0-0.2,0-0.3
		 c-0.1,0-0.3,0-0.4,0c-0.6,0-1.1-0.5-1.1-1.1c0-0.9,0-1.8,0-2.6c0-0.6,0.5-1.1,1.1-1.1c0.1,0,0.2,0,0.4,0c0-0.1,0-0.2,0-0.2
		 c0-0.5,0-1.1,0-1.6c0-1.1,0.8-1.9,1.9-1.9c3.9,0,7.8,0,11.7,0c1.1,0,1.9,0.8,1.9,1.9C17.7,3.6,17.7,4.2,17.7,4.8z M9.6,4.8
		 c-0.1,0-0.2,0-0.2,0c-2,0-3.9,0-5.9,0C3.1,4.8,3,5,3,5.3C3,7,3,8.6,3,10.3c0,0.8,0.4,1.2,1.2,1.2c1.7,0,3.4,0,5.1,0
		 c0.1,0,0.2,0,0.3,0C9.6,9.3,9.6,7.1,9.6,4.8z M10.4,11.5c1.8,0,3.6,0,5.4,0c0.7,0,1.2-0.5,1.2-1.2c0-1.6,0-3.3,0-4.9
		 c0-0.4-0.1-0.5-0.5-0.5c-2,0-3.9,0-5.9,0c-0.1,0-0.1,0-0.2,0C10.4,7.1,10.4,9.3,10.4,11.5z M3,11.8c0,1.2,0,2.4,0,3.6
		 c0,0.7,0.5,1.2,1.2,1.2c3.9,0,7.7,0,11.6,0c0.7,0,1.2-0.5,1.2-1.2c0-1.1,0-2.2,0-3.3c0-0.1,0-0.1,0-0.2c-0.5,0.4-1,0.4-1.6,0.4
		 c-3.6,0-7.2,0-10.8,0C4,12.2,3.5,12.2,3,11.8z M3,4.1c4.7,0,9.3,0,14,0c0-0.3,0-0.7,0-1c0-0.8-0.4-1.2-1.3-1.2c-3.8,0-7.7,0-11.5,0
		 c-0.1,0-0.1,0-0.2,0c-0.5,0-1,0.4-1,1C3,3.3,3,3.7,3,4.1z M2.3,5.6c-0.1,0-0.3,0-0.4,0c-0.2,0-0.4,0.1-0.4,0.4c0,0.9,0,1.7,0,2.6
		 c0,0.2,0.1,0.4,0.4,0.4c0.1,0,0.2,0,0.4,0C2.3,7.8,2.3,6.7,2.3,5.6z M17.7,5.6c0,1.1,0,2.2,0,3.3c0.1,0,0.2,0,0.3,0
		 c0.2,0,0.4-0.1,0.4-0.4c0-0.8,0-1.7,0-2.5c0-0.2-0.1-0.4-0.4-0.4C18,5.6,17.9,5.6,17.7,5.6z M4.5,18.1c0.7,0,1.5,0,2.2,0
		 c0-0.2,0-0.5,0-0.7c-0.7,0-1.5,0-2.2,0C4.5,17.6,4.5,17.8,4.5,18.1z M15.5,18.1c0-0.3,0-0.5,0-0.7c-0.7,0-1.5,0-2.2,0
		 c0,0.2,0,0.5,0,0.7C14.1,18.1,14.8,18.1,15.5,18.1z"
          />
          <path
            class="st1"
            d="M9.6,4.8c0,2.2,0,4.4,0,6.6c-0.1,0-0.2,0-0.3,0c-1.7,0-3.4,0-5.1,0C3.4,11.5,3,11,3,10.3C3,8.6,3,7,3,5.3
		 C3,5,3.1,4.8,3.5,4.8c2,0,3.9,0,5.9,0C9.5,4.8,9.5,4.8,9.6,4.8z"
          />
          <path
            class="st1"
            d="M10.4,11.5c0-2.2,0-4.4,0-6.6c0.1,0,0.1,0,0.2,0c2,0,3.9,0,5.9,0C16.9,4.8,17,5,17,5.3c0,1.6,0,3.3,0,4.9
		 c0,0.7-0.5,1.2-1.2,1.2C14,11.5,12.2,11.5,10.4,11.5z"
          />
          <path
            class="st1"
            d="M3,11.8c0.5,0.4,1,0.4,1.6,0.4c3.6,0,7.2,0,10.8,0c0.6,0,1.1,0,1.6-0.4c0,0.1,0,0.2,0,0.2c0,1.1,0,2.2,0,3.3
		 c0,0.8-0.5,1.2-1.2,1.2c-3.9,0-7.7,0-11.6,0c-0.7,0-1.2-0.5-1.2-1.2C3,14.3,3,13.1,3,11.8z M5.9,15.9c0.4,0,0.8,0,1.1,0
		 c0.4,0,0.7-0.3,0.7-0.6c0.1-1-0.4-1.6-1.1-1.8c-0.5-0.1-1.1-0.2-1.6-0.4c-0.7-0.1-1.3,0.4-1.3,1.1c0,0.2,0,0.3,0,0.5
		 c0,0.7,0.5,1.2,1.2,1.2C5.2,15.9,5.6,15.9,5.9,15.9z M14.1,15.9C14.1,15.9,14.1,15.9,14.1,15.9c0.4,0,0.7,0,1.1,0
		 c0.6,0,1.1-0.5,1.1-1.1c0-0.2,0-0.4,0-0.6c0-0.7-0.6-1.2-1.4-1.1c-0.5,0.1-0.9,0.2-1.4,0.3c-0.9,0.2-1.3,0.7-1.3,1.7
		 c0,0.5,0.3,0.8,0.8,0.8C13.4,15.9,13.7,15.9,14.1,15.9z M10,13.3c-0.5,0-0.9,0-1.4,0c-0.3,0-0.4,0.1-0.4,0.4c0,0.2,0.2,0.4,0.4,0.4
		 c0.9,0,1.9,0,2.8,0c0.3,0,0.4-0.1,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4C10.9,13.3,10.5,13.3,10,13.3z M10,15.5c0.4,0,0.7,0,1.1,0
		 c0.3,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4c-0.7,0-1.4,0-2.1,0c-0.3,0-0.4,0.2-0.4,0.4c0,0.2,0.2,0.4,0.4,0.4
		 C9.3,15.5,9.6,15.5,10,15.5z"
          />
          <path
            class="st1"
            d="M3,4.1c0-0.4,0-0.8,0-1.3c0-0.5,0.5-1,1-1c0.1,0,0.1,0,0.2,0c3.8,0,7.7,0,11.5,0c0.8,0,1.3,0.4,1.3,1.2
		 c0,0.3,0,0.7,0,1C12.3,4.1,7.7,4.1,3,4.1z M10,3.4c0.5,0,1,0,1.5,0c0.5,0,1,0,1.4,0c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4
		 c-0.1,0-0.1,0-0.2,0c-1.7,0-3.4,0-5,0c-0.2,0-0.5,0-0.7,0c-0.1,0-0.3,0.2-0.3,0.3c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.2,0.1,0.3,0.1
		 C8.1,3.4,9.1,3.4,10,3.4z"
          />
          <path
            class="st1"
            d="M2.3,5.6c0,1.1,0,2.2,0,3.3c-0.1,0-0.2,0-0.4,0c-0.2,0-0.4-0.1-0.4-0.4c0-0.9,0-1.7,0-2.6
		 c0-0.2,0.1-0.4,0.4-0.4C2,5.6,2.1,5.6,2.3,5.6z"
          />
          <path
            class="st1"
            d="M17.7,5.6c0.1,0,0.2,0,0.3,0c0.2,0,0.4,0.1,0.4,0.4c0,0.8,0,1.7,0,2.5c0,0.2-0.1,0.4-0.4,0.4
		 c-0.1,0-0.2,0-0.3,0C17.7,7.8,17.7,6.7,17.7,5.6z"
          />
          <path class="st1" d="M4.5,18.1c0-0.2,0-0.5,0-0.7c0.7,0,1.4,0,2.2,0c0,0.2,0,0.5,0,0.7C5.9,18.1,5.2,18.1,4.5,18.1z" />
          <path class="st1" d="M15.5,18.1c-0.7,0-1.5,0-2.2,0c0-0.2,0-0.5,0-0.7c0.7,0,1.4,0,2.2,0C15.5,17.6,15.5,17.8,15.5,18.1z" />
          <path
            class="st0"
            d="M5.9,15.9c-0.3,0-0.7,0-1,0c-0.7,0-1.2-0.5-1.2-1.2c0-0.2,0-0.3,0-0.5c0-0.7,0.6-1.2,1.3-1.1
		 c0.5,0.1,1.1,0.2,1.6,0.4c0.7,0.2,1.3,0.8,1.1,1.8c0,0.4-0.4,0.6-0.7,0.6C6.7,15.9,6.3,15.9,5.9,15.9z M7,15.2
		 c0-0.5-0.1-0.8-0.6-0.9c-0.5-0.1-1-0.2-1.5-0.3c-0.3-0.1-0.5,0.1-0.5,0.4c0,0.2,0,0.4,0,0.6c0,0.2,0.1,0.4,0.4,0.4
		 C5.6,15.2,6.3,15.2,7,15.2z"
          />
          <path
            class="st0"
            d="M14.1,15.9c-0.4,0-0.7,0-1.1,0c-0.5,0-0.8-0.3-0.8-0.8c0-0.9,0.4-1.5,1.3-1.7c0.5-0.1,0.9-0.2,1.4-0.3
		 c0.7-0.2,1.3,0.3,1.4,1.1c0,0.2,0,0.4,0,0.6c0,0.6-0.5,1.1-1.1,1.1C14.8,15.9,14.4,15.9,14.1,15.9C14.1,15.9,14.1,15.9,14.1,15.9z
			M13,15.2c0.7,0,1.5,0,2.2,0c0.2,0,0.4-0.2,0.4-0.4c0-0.1,0-0.3,0-0.4c0-0.4-0.2-0.5-0.6-0.5c-0.4,0.1-0.9,0.2-1.3,0.3
		 C13.1,14.3,12.9,14.6,13,15.2z"
          />
          <path
            class="st0"
            d="M10,13.3c0.5,0,0.9,0,1.4,0c0.3,0,0.4,0.1,0.4,0.4c0,0.2-0.2,0.4-0.4,0.4c-0.9,0-1.9,0-2.8,0
		 c-0.3,0-0.4-0.1-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4C9.1,13.3,9.5,13.3,10,13.3z"
          />
          <path
            class="st0"
            d="M10,15.5c-0.3,0-0.7,0-1,0c-0.3,0-0.4-0.1-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4c0.7,0,1.4,0,2.1,0
		 c0.3,0,0.4,0.1,0.4,0.4c0,0.2-0.2,0.4-0.4,0.4C10.7,15.5,10.3,15.5,10,15.5z"
          />
          <path
            class="st0"
            d="M10,3.4c-0.9,0-1.9,0-2.8,0c-0.1,0-0.3,0-0.3-0.1C6.8,3.2,6.7,3,6.7,2.9c0-0.1,0.2-0.2,0.3-0.3
		 c0.2,0,0.4,0,0.7,0c1.7,0,3.4,0,5,0c0.1,0,0.1,0,0.2,0c0.2,0,0.4,0.2,0.4,0.4c0,0.2-0.2,0.4-0.4,0.4c-0.5,0-1,0-1.4,0
		 C11,3.4,10.5,3.4,10,3.4z"
          />
          <path
            class="st1"
            d="M7,15.2c-0.7,0-1.5,0-2.2,0c-0.2,0-0.4-0.1-0.4-0.4c0-0.2,0-0.4,0-0.6c0-0.3,0.2-0.4,0.5-0.4
		 c0.5,0.1,1,0.2,1.5,0.3C6.9,14.3,7.1,14.6,7,15.2z"
          />
          <path
            class="st1"
            d="M13,15.2c0-0.6,0.1-0.9,0.7-1c0.4-0.1,0.9-0.2,1.3-0.3c0.4-0.1,0.5,0.1,0.6,0.5c0,0.1,0,0.3,0,0.4
		 c0,0.2-0.1,0.4-0.4,0.4C14.4,15.2,13.7,15.2,13,15.2z"
          />
        </g>
      </svg>
    </ng-template>
  `,
})
export class IconMenuBusComponent {
  @Input() class: any = "";
  @ViewChild("template", { static: true }) template: any;
  constructor(private viewContainerRef: ViewContainerRef) {}
  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.template);
    this.viewContainerRef.element.nativeElement.remove();
  }
}
