import { ActivatedRoute } from "@angular/router";
import { Component, OnInit, ViewChild } from "@angular/core";
import { GroupTravel } from "../models/group-travel";
import { TranslateService } from "@ngx-translate/core";
import { GroupTravelsService } from "../services/group-travels.service";
import { animate, style, transition, trigger } from "@angular/animations";
import { FileUploadComponent } from "src/app/shared/components/file-upload/file-upload.component";

@Component({
	selector: "app-group-travel-details",
	templateUrl: "./group-travel.details.component.html",
	animations: [
		trigger("toggleAnimation", [
			transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
			transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
		]),
	],
})
export class GroupTravelDetailsComponent implements OnInit {
	protected id!: number;
	protected group_travel!: GroupTravel;
	public entityname = "GroupTravels";
	isShowingSidebar = false;

	constructor(private travelService: GroupTravelsService, private route: ActivatedRoute, public translate: TranslateService) { }
	ngOnInit() {
		this.route.params.subscribe((params) => {
			this.id = params["id"];
			this.fetchTravelData();
		});
	}

	fetchTravelData() {
		this.travelService.getGroupTravelData(this.id).subscribe((response) => {
			this.group_travel = response;
		});
	}
}
