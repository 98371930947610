import * as moment from "moment";
import { Injectable } from "@angular/core";
import { Payment } from "../models/payment";
import { HttpClient } from "@angular/common/http";
import { BaseHttpService } from "src/app/service/base.http.service";
import { PaymentDocument } from "../models/payment-document";

@Injectable({
  providedIn: "root",
})
export class PaymentService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  savePayment(payment: Payment) {
    payment.paymentDate = moment(payment.paymentDate, "dd.MM.yyyy").toISOString();
    return this.http.post<Payment>(`${this.apiUrl}/api/Payments`, payment);
  }

  updatePayment(payment: Payment) {
    payment.paymentDate = moment(payment.paymentDate, "dd.MM.yyyy").toISOString();
    return this.http.put<Payment>(`${this.apiUrl}/api/Payments/${payment.id}`, payment);
  }

  deletePayment(id: number) {
    return this.http.delete(`${this.apiUrl}/api/payments/${id}`);
  }
	getPaymentDocument(id: number) {
    return this.http.get<PaymentDocument>(`${this.apiUrl}/api/Payments/payment?id=${id}`);
  }
}

