<button type="button" class="btn btn-primary gap-2" (click)="toggleFileUploadPopup()">
  <icon-down></icon-down>
  <icon-folder></icon-folder>
  Files
</button>
<div *ngIf="isPopupVisible" class="absolute right-5 z-50 mt-3 w-auto min-w-[400px] transform flex-col rounded-lg border border-gray-200 bg-white pt-5 shadow-lg">
  <ul>
    <li class="py-1.5 text-blue-500 ltr:pl-4 rtl:pr-4">
      <button type="button" class="flex w-full gap-1 text-blue-500 hover:bg-gray-100" (click)="toggleTreeview2('img')">
        <icon-down class="text-blue-500"></icon-down>
        <icon-folder class="text-blue-500"></icon-folder>
        img
      </button>
      <ng-container *ngIf="treeview2.includes('img')">
        <ul class="flex gap-3 border-l hover:bg-gray-100">
          <li class="py-1.5 text-blue-500 ltr:pl-7 rtl:pr-7">pasos.jpg</li>
          <li class="py-1"><icon-download></icon-download></li>
          <li class="py-1"><icon-eye></icon-eye></li>
        </ul>
        <ul class="flex gap-3 border-l hover:bg-gray-100">
          <li class="py-1.5 text-blue-500 ltr:pl-7 rtl:pr-7">homework.mp4</li>
          <li class="py-1"><icon-download></icon-download></li>
          <li class="py-1"><icon-eye></icon-eye></li>
        </ul>
      </ng-container>
    </li>
    <li class="py-1.5 text-blue-500 ltr:pl-4 rtl:pr-4">
      <button type="button" class="flex w-full gap-1 text-blue-500 hover:bg-gray-100" (click)="toggleTreeview2('css')">
        <icon-down class="text-blue-500"></icon-down>
        <icon-folder class="text-blue-500"></icon-folder>
        css
      </button>
      <ng-container *ngIf="treeview2.includes('css')">
        <ul class="flex gap-3 border-l hover:bg-gray-100">
          <li class="py-1.5 text-blue-500 ltr:pl-7 rtl:pr-7">style.css</li>
          <li class="py-1"><icon-download></icon-download></li>
          <li class="py-1"><icon-eye></icon-eye></li>
        </ul>
      </ng-container>
    </li>
    <li class="py-1.5 text-blue-500 ltr:pl-4 rtl:pr-4">
      <button type="button" class="flex w-full gap-1 text-blue-500 hover:bg-gray-100" (click)="toggleTreeview2('js')">
        <icon-down class="text-blue-500"></icon-down>
        <icon-folder class="text-blue-500"></icon-folder>
        js
      </button>
      <ng-container *ngIf="treeview2.includes('js')">
        <ul class="flex gap-3 border-l text-blue-500 hover:bg-gray-100">
          <li class="py-1.5 ltr:pl-7 rtl:pr-7">script.js</li>
          <li class="py-1"><icon-download></icon-download></li>
          <li class="py-1"><icon-eye></icon-eye></li>
        </ul>
      </ng-container>
    </li>
    <ul class="flex gap-3 border-l text-blue-500 hover:bg-gray-100">
      <li class="py-1.5 ltr:pl-7 rtl:pr-7">script.js</li>
      <li class="py-1"><icon-download></icon-download></li>
      <li class="py-1"><icon-eye></icon-eye></li>
    </ul>

    <div class="px-4 py-1.5 text-blue-500">
      <button type="button" class="btn btn-primary flex w-full gap-1 text-white" (click)="triggerFileInput()">
        <icon-upload></icon-upload>
        Add File
      </button>
      <input type="file" #fileInput class="hidden" (change)="onFileSelected($event)" />
    </div>
  </ul>
</div>
