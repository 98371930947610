import { Observable } from "rxjs";
import { UserWorker } from "../models/worker";
import { PopupType } from "src/app/shared/enums/popup-types";
import { WorkersService } from "../services/workers.service";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { Office } from "../../office-branch/models/office-branch";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-worker-form",
  templateUrl: "./worker.form.component.html",
})
export class WorkerFormComponent implements OnInit {
  @Output() submissionDone: EventEmitter<any> = new EventEmitter();

  public form!: FormGroup;

  options = ["admin", "seller"];
  options$!: Observable<Office[]>;

  @Input() id!: number | undefined;
  constructor(public fb: FormBuilder, public workerService: WorkersService, public translate: TranslateService) {}

  initForm(worker: UserWorker): void {
    const { name, surname, email, username, password, role = "admin", officeBranch, uniqueMasterCitizenNumber, isOfficeBranchManager } = worker || ({} as UserWorker);

    this.form = this.fb.group({
      name: [name, Validators.required],
      surname: [surname, Validators.required],
      email: [email, Validators.email],
      username: [username, Validators.required],
      password: [password],
      role: [role, Validators.required],
      officeBranch: [officeBranch, Validators.required],
      uniqueMasterCitizenNumber: [uniqueMasterCitizenNumber, Validators.required],
      isOfficeBranchManager: [isOfficeBranchManager],
    });
  }

  submitForm(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    if (this.id) {
      this.workerService.updateWorker(this.id, this.fetchWorker()).subscribe(
        (response) => {
          showMessage(PopupType.Success, this.translate.instant("popup.worker_updated_successfully"));
          this.initForm({} as UserWorker);
          this.submissionDone.emit(null);
        },
        (error) => {
          showMessage(PopupType.Danger, error.toString());
        }
      );
    } else {
      this.workerService.createWorker(this.fetchWorker()).subscribe(
        (response) => {
          this.initForm({} as UserWorker);
          this.submissionDone.emit(null);
        },
        (error) => {
          showMessage(PopupType.Danger, error.toString());
        }
      );
    }
  }

  ngOnInit(): void {
    if (this.id !== undefined) {
      this.workerService.getWorkerById(this.id).subscribe((worker) => {
        // this.form.patchValue(worker);
        this.initForm(worker);
      });
    } else {
      this.initForm({} as UserWorker);
    }
    this.options$ = this.workerService.fetchOfficeBranches();
  }

  fetchWorker(): UserWorker {
    const { name, surname, email, username, password, role, officeBranch, uniqueMasterCitizenNumber, isOfficeBranchManager } = this.form.value;

    return { name, surname, email, username, password, role, officeBranchId: officeBranch.id, uniqueMasterCitizenNumber, isOfficeBranchManager } as UserWorker;
  }
}
