import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StatisticsYearlyDebt } from 'src/app/domain/statisticsmoney/models/yearlydebt';

@Component({
  selector: 'app-yearly-debt-chart',
  templateUrl: './yearly-debt-chart.component.html'
})
export class YearlyDebtChartComponent implements OnInit,OnChanges {
  @Input() simpleColumnStackedDebt: StatisticsYearlyDebt | undefined;
	simpleColumnStackedSeries: any;

  availableYears: number[] = [];
  selectedYear: number | undefined;

	constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.updateChartYearDebt();
  }
	ngOnChanges(changes: SimpleChanges): void {
    if (changes['simpleColumnStackedDebt'] && changes['simpleColumnStackedDebt'].currentValue) {
      this.updateChartYearDebt();
    }
  }


  updateChartYearDebt() {
    if (this.simpleColumnStackedDebt && this.simpleColumnStackedDebt.yearlyServiceDebtStats) {
      const years = this.simpleColumnStackedDebt.yearlyServiceDebtStats.map((item: any) => item.year);
      const ticketDebt = this.simpleColumnStackedDebt.yearlyServiceDebtStats.map((item: any) => Number(item.ticketDebt));
      const travelDebt = this.simpleColumnStackedDebt.yearlyServiceDebtStats.map((item: any) => Number(item.travelDebt));
      const groupTravelDebt = this.simpleColumnStackedDebt.yearlyServiceDebtStats.map((item: any) => Number(item.groupTravelDebt));
      const transferDebt = this.simpleColumnStackedDebt.yearlyServiceDebtStats.map((item: any) => Number(item.transferDebt));

      this.availableYears = Array.from(new Set(years));
      this.selectedYear = this.availableYears[this.availableYears.length - 1];

      const isDark = "dark" === "dark" || true;
      const isRtl = "rtl" === "rtl"; 

      this.simpleColumnStackedSeries = {
        series: [
          { name: this.translate.instant('debt.ticket_debt'), data: ticketDebt },
          { name: this.translate.instant('debt.travel_debt'), data: travelDebt },
          { name: this.translate.instant('debt.group_travel_debt'), data: groupTravelDebt },
          { name: this.translate.instant('debt.transfer_debt'), data: transferDebt }
        ],
        chart: {
          height: 350,
          type: "bar",
          zoom: { enabled: false },
          toolbar: { show: false },
        },
        dataLabels: { enabled: false },
        stroke: { show: true, width: 2, colors: ["transparent"] },
        colors: ["#2196f3", "#3b3f5c", "#ff9800", "#4caf50"],
        xaxis: {
          categories: years,
          axisBorder: { color: isDark ? "#191e3a" : "#e0e6ed" },
        },
        yaxis: {
          opposite: isRtl,
          labels: { offsetX: isRtl ? -10 : 0 },
        },
				grid: { borderColor: isDark ? "#ADD8E6" : "silver" },
				plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        tooltip: {
          theme: isDark ? "dark" : "light",
          y: { formatter: (val: any) => val },
        },
      };
    }
  }
}
