import { Component, Input} from '@angular/core';
import { Payment } from '../models/payment';
import { PaymentDocument } from '../models/payment-document';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-payment-document',
  templateUrl: './payment-document.component.html',
})
export class PaymentDocumentComponent {

  @Input() payments!: Payment[];
  @Input() showDocumentPanel?: boolean;
  @Input() paymentDocument?: PaymentDocument;

  constructor(public translate: TranslateService) {}

  parsePaymentDetails(paymentDetailsJson: string): { PayedAt: string; Amount: string }[] {
    const paymentDetails = JSON.parse(paymentDetailsJson);
    return paymentDetails.map((payment: any) => ({
      PayedAt: payment.PayedAt,
      Amount: parseFloat(payment.Amount).toFixed(2),
    }));
  }

  getCurrentDate(): Date {
    return new Date();
  }
	
}
