import * as moment from "moment";
import { Store } from "@ngrx/store";
import { Component, ElementRef, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { Sale } from "src/app/domain/sales/models/sale";
import { ActivatedRoute, Router } from "@angular/router";
import { PopupType } from "src/app/shared/enums/popup-types";
import { Ticket } from "src/app/domain/tickets/models/ticket";
import { CheckoutService } from "../services/checkout.service";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { Customer } from "../../domain/customers/models/customer";
import { Picklist } from "src/app/domain/settings/models/picklist";
import { PicklistType } from "src/app/shared/enums/picklist-types";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { animate, style, transition, trigger } from "@angular/animations";
import { DateTimeFormatter } from "src/app/shared/utils/datetime.formatter";
import { SaleCreateComponent } from "src/app/domain/sales/sale.create/sale.create.component";
import { CustomerCreateComponent } from "src/app/domain/customers/customer.create/customer.create.component";

@Component({
	templateUrl: "./other-services.checkout.component.html",
	animations: [
		trigger("toggleAnimation", [
			transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
			transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
		]),
	],
})
export class OtherServicesCheckoutComponent {
	PicklistType = PicklistType;
	@ViewChild("mainCustomer") customerComponent!: CustomerCreateComponent;
	@ViewChild("sale") saleComponent!: SaleCreateComponent;

	public id!: number;
	public customer!: Customer;
	public sale!: Sale;

	note!: string;

	public form!: FormGroup;
	public activeTab = 1;
	@ViewChildren('submitBtn') submitBtn!: ElementRef;
	@ViewChild('nameInput') nameInput!: ElementRef;



	constructor(public store: Store<AppState>, public translate: TranslateService, public checkoutService: CheckoutService, private router: Router, private route: ActivatedRoute, public fb: FormBuilder) {
		this.initForm({} as Picklist);
		this.route.params.subscribe((params) => {
			this.id = params["id"];

			if (this.router.url.includes("edit")) {
				this.fetchEntities();
			}
		});
	}
	ngOnInit(): void {

		setTimeout(() => {
			this.nameInput.nativeElement.focus();
			this.nameInput.nativeElement.select();
		}, 0);
	}
	initForm(service: Picklist | undefined) {
		this.form = this.fb.group({
			service: [service, Validators.required],
		});
	}
	handleEnter(event: KeyboardEvent, nextElementId: string): void {
		if (event.key === 'Enter') {
			event.preventDefault();
			if (nextElementId) {
				const nextElement = document.getElementById(nextElementId);
				if (nextElement) {
					nextElement.focus();
				}
			} else {
				this.submitBtn.nativeElement.focus();
			}
		}
	}
	fetchEntities() {
		this.checkoutService.fetchOtherService(this.id).subscribe((response) => {
			const { customer, plan } = response;

			this.initForm(response.service);
			this.customerComponent.initForm({ ...customer });
			this.saleComponent.initForm(DateTimeFormatter.formatPlanDates(plan));
			this.note = plan.notes;
		});
	}

	addService() {
		this.form.updateValueAndValidity();
		if (!(this.customerComponent.checkIfValid() && this.saleComponent.checkIfValid() && this.form.valid)) {
			showMessage(PopupType.Danger, this.translate.instant("popup.please_make_sure_all_forms_are_valid"));
			return;
		}
		this.sale = this.saleComponent.getSale();
		if (this.id) {
			this.checkoutService.updateOtherService(this.id, this.createReservationObject()).subscribe((r) => {
				showMessage(PopupType.Success, this.translate.instant("popup.updated_successfully"));
				this.router.navigate(["dashboard/sales"]);
			});
		} else {
			this.checkoutService.createOtherService(this.createReservationObject()).subscribe((r) => {
				showMessage(PopupType.Success, this.translate.instant("popup.created_successfully"));
				this.router.navigate(["dashboard/sales"]);
			});
		}
	}
	createReservationObject(): any {
		const { id } = this.customer;

		if (!id) {
			showMessage(PopupType.Danger, this.translate.instant("popup.customer_id_missing"));
			return;
		}


		this.sale.dueDate = moment(this.sale.dueDate, "DD.MM.YYYY").toISOString();
		const { bruto, neto, avans, dueDate , paymentTypeId} = this.sale;

		const { service } = this.form.getRawValue();
		return {
			serviceId: service?.id,
			customerId: id,
			plan: { bruto, neto, avans, dueDate, customerId: id, notes: this.note, paymentTypeId },
			planId: this.sale.id,
		};
	}

	isVisibleTab(index: number) {
		return { display: this.activeTab === index ? "block" : "none" };
	}

	async proceedToNextStage(nextStep: number | undefined = undefined) {
		this.customer = await this.customerComponent.getCustomer();
		this.activeTab = nextStep ?? this.activeTab + 1;
	}


}
