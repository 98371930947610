import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { AuthService } from "../auth/service/auth.service";

@Injectable({
  providedIn: "root",
})
export class HasRoleGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const redirectTo = (route.data["redirectTo"] as string) || "/404";
    return this.authService.getUserRole().pipe(
      map((role) => {
        if (route.data["role"].includes(role)) {
          return true;
        } else {
          const id = this.authService.getUserId();
          this.router.navigate([`${redirectTo}/${id}`]);

          return false;
        }
      }),
      catchError((error) => {
        console.error("Error fetching user role", error);
        this.router.navigate(["/auth/login"]);
        return of(false);
      })
    );
  }
}
