import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DailyEarningsStatistics } from 'src/app/domain/statisticsmoney/models/dailyearnings';

@Component({
  selector: 'app-daily-earnings-chart',
  templateUrl: './daily-earnings-chart.component.html'
})
export class DailyEarningsChartComponent implements OnInit, OnChanges {
  @Input() dailyearningstatistics: DailyEarningsStatistics | undefined;
  simpleColumnStackedSeries: any;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.updateChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dailyearningstatistics'] && changes['dailyearningstatistics'].currentValue) {
      this.updateChart();
    }
  }

  getDayName(dayNumber: number): string {
    return `Day ${dayNumber}`;
  }

  updateChart() {
    if (this.dailyearningstatistics && this.dailyearningstatistics.dailyServiceEarningsStats) {
      const sortedStats = this.dailyearningstatistics.dailyServiceEarningsStats.sort((a, b) => {
        return a.day - b.day;
      });

      const days = sortedStats.map(item => this.getDayName(item.day));
      const ticketEarnings = sortedStats.map(item => item.ticketEarnings);
      const travelEarnings = sortedStats.map(item => item.travelEarnings);
      const groupTravelEarnings = sortedStats.map(item => item.groupTravelEarnings);
      const transferEarnings = sortedStats.map(item => item.transferEarnings);

      const isDark = true; 
      const isRtl = false;

      this.simpleColumnStackedSeries = {
				series: [
          { name: this.translate.instant('earnings.ticket_earnings'), data: ticketEarnings },
          { name: this.translate.instant('earnings.travel_earnings'), data: travelEarnings },
          { name: this.translate.instant('earnings.group_travel_earnings'), data: groupTravelEarnings },
          { name: this.translate.instant('earnings.transfer_earnings'), data: transferEarnings }
        ],
        chart: {
          height: 350,
          type: "bar",
          zoom: { enabled: false },
          toolbar: { show: false }
        },
        dataLabels: { enabled: false },
        stroke: { show: true, width: 2, colors: ["transparent"] },
        colors: ["#2196f3", "#3b3f5c", "#ff9800", "#4caf50"],
        xaxis: {
          categories: days,
          axisBorder: { color: isDark ? "#191e3a" : "#e0e6ed" }
        },
        yaxis: {
          opposite: isRtl,
          labels: { offsetX: isRtl ? -10 : 0 }
        },
        grid: { borderColor: isDark ? "#ADD8E6" : "silver" },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded"
          }
        },
        tooltip: {
          theme: isDark ? "dark" : "light",
          y: { formatter: (val: any) => val }
        }
      };
    }
  }
}
