import { Store } from "@ngrx/store";
import { Hotel } from "../models/hotel";
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { HotelsService } from "../services/hotels.service";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TableService } from "src/app/shared/services/table.service";
import { animate, style, transition, trigger } from "@angular/animations";
import { selectFromTo } from "src/app/store/datepicker/datetime.selector";
import { BaseTableComponent, DatatableColumn } from "src/app/shared/components/base-table/base-table.component";
import { PicklistType, EntityPicklistType } from "src/app/shared/enums/picklist-types";
import { Router } from "@angular/router";
import { map, Observable } from "rxjs";
import { Organizer } from "../../organizers/models/oragnizers";
import { MessagePortalComponent } from "src/app/portal/portal";
import { PositioningService } from "src/app/shared/services/positioning.service";
import { ModalService } from "src/app/shared/services/modal.service";
import { FlexibleConnectedPositionStrategy, OverlayRef } from "@angular/cdk/overlay";

@Component({
  templateUrl: "./hotels.table.component.html",
  animations: [
    trigger("toggleAnimation", [
      transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
      transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
    ]),
  ],
})
export class HotelsTableComponent extends BaseTableComponent<Hotel> implements OnInit {
  @ViewChild("modal") modal!: ModalComponent;
  @ViewChild("dropdownPlaceholder") dropdownPlaceholder!: ElementRef;
  @ViewChild(MessagePortalComponent) messagePortalComponent!: MessagePortalComponent;

  public form!: FormGroup;

  private overlayRef!: OverlayRef;
  private positionStrategy!: FlexibleConnectedPositionStrategy;
  editMode = false;
  hotelId = 0;
  mergeMode = false;
  term: any;
  foundHotels: Hotel[] = [];
  name: any = "";
  filteredHotels: Hotel[] = [];
  selectedHotelIds: number[] = [];
  EntityPicklistType = EntityPicklistType;
  hotels!: Observable<Hotel[]>;
  selectedHotels: Hotel[] = [];

  constructor(
    private hotelsService: HotelsService,
    public override translate: TranslateService,
    public fb: FormBuilder,
    public override store: Store<AppState>,
    public override tableService: TableService,
    public router: Router,
    private ref: ChangeDetectorRef,
    private positioningService: PositioningService,
    private modalService: ModalService
  ) {
    super(store, translate, selectFromTo, tableService, `hotels/paginated`);
  }

  // ngAfterViewChecked() {
  //   if (this.dropdownPlaceholder && this.dropdownPlaceholder.nativeElement) {
  //     this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
  //   }
  // }

  onPortalAttached() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 0);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.initForm();
    this.loadHotels();
  }
  loadHotels() {
    this.filteredHotels = [];
    this.foundHotels = [];
    this.selectedHotelIds = [];

    this.hotels = this.hotelsService.getHotels().pipe(map((organizers: Hotel[]) => organizers.filter((organizer) => organizer.id !== this.hotelId)));
  }

  initForm() {
    this.form = this.fb.group({
      name: ["", Validators.required],
    });
  }

  submitForm() {
    if (this.form.valid) {
      this.addHotelData();
    } else {
      this.form.markAllAsTouched();
    }
  }

  addHotel() {
    this.editMode = false;
    this.mergeMode = false;
    this.modal.open();
  }
  clearSearchTerm() {
    this.term = "";
  }

  editHotel(id: number) {
    this.hotelId = id;
    this.editMode = true;
    this.mergeMode = false;
    this.modal.open();
  }

  addMerge(id: number, name: any) {
    this.hotelId = id;
    this.name = name;
    this.mergeMode = true;
    this.editMode = false;
    this.modal.open();
    this.foundHotels = [];
    this.filteredHotels = [];
    this.term = "";
  }

  addHotelData() {
    if (!this.editMode) {
      this.hotelsService.addHotel({ name: this.getName() }).subscribe(
        () => {
          this.modal.close();
          //this.fetchHotels();
          showMessage(PopupType.Success, this.translate.instant("hotel_data.added_hotel_successfully"));
        },
        (error) => {
          showMessage(PopupType.Warning, this.translate.instant("hotel_data.error_adding_hotel"));
        }
      );
    } else {
      this.hotelsService.updateHotel(this.hotelId, { name: this.getName() }).subscribe(
        (res) => {
          this.modal.close();
          //this.fetchHotels();
          showMessage(PopupType.Success, this.translate.instant("hotel_data.updated_hotel_successfully"));
        },
        (err) => {
          showMessage(PopupType.Warning, this.translate.instant("hotel_data.error_updating_hotel"));
        }
      );
    }
  }

  getName() {
    const { name } = this.form.value;
    return name;
  }

  override getColumns(): void {
    this.cols = [
      { field: "id", title: this.translate.instant("hotel_info.id"), isUnique: true, hide: true } as DatatableColumn,
      { field: "name", title: this.translate.instant("hotel_info.name"), hide: false } as DatatableColumn,
      { field: "numberOfTravels", title: this.translate.instant("hotel_info.num_of_arrangements"), hide: false } as DatatableColumn,
      { field: "numberOfGroupDestinations", title: this.translate.instant("hotel_info.num_of_destinations"), hide: false } as DatatableColumn,
      { field: "numberOfPeople", title: this.translate.instant("hotel_info.numberOfPeople"), hide: false } as DatatableColumn,
      { field: "earnings", title: this.translate.instant("hotel_info.earnings"), hide: false } as DatatableColumn,
      { field: "createdAt", title: this.translate.instant("hotel_info.created_at"), hide: false } as DatatableColumn,
      { field: "actions", title: this.translate.instant("hotel_info.actions"), hide: false, sort: false } as DatatableColumn,
    ];
  }
  exportCols = [
    { field: "name", title: this.translate.instant("hotel_export.name"), hide: false, sort: false } as DatatableColumn,
    { field: "numberOfArrangements", title: this.translate.instant("hotel_export.numberOfArrangements"), hide: false } as DatatableColumn,
    { field: "numberOfDestinations", title: this.translate.instant("hotel_export.numberOfDestinations"), hide: false } as DatatableColumn,
    { field: "numberOfPeople", title: this.translate.instant("hotel_export.numberOfPeople"), hide: false } as DatatableColumn,
    { field: "earnings", title: this.translate.instant("hotel_export.earnings"), hide: false, sort: false } as DatatableColumn,
    { field: "createdAt", title: this.translate.instant("hotel_export.createdAt"), hide: false } as DatatableColumn,
  ];

  async deleteHotels(id: number) {
    const confirmed = await this.modalService.confirm();
    if (confirmed) {
      this.hotelsService.deleteHotel(id).subscribe(
        (res) => {
          this.ngOnInit();
        },
        (err) => showMessage(PopupType.Danger, err.toString())
      );
    }
  }
  mergeSelectedHotels() {
    this.selectedHotelIds = this.selectedHotels.map((person: Hotel) => person.id);

    if (this.selectedHotelIds.length > 0 && this.hotelId) {
      this.hotelsService.mergeHotels(this.hotelId, this.selectedHotelIds).subscribe((response) => {
        if (response.includes("Merged hotels")) {
          showMessage(PopupType.Success, this.translate.instant("hotel_data.hotels_merged_successfully"));
          this.modal.close();
        } else {
          showMessage(PopupType.Warning, this.translate.instant("hotel_data.error_merging_hotels"));
        }
      });
    } else {
      showMessage(PopupType.Warning, this.translate.instant("hotel_data.select_hotels_to_merge"));
    }
  }
}
