import * as moment from "moment";
import { Router } from "@angular/router";
import { DraftSale } from "../models/draft-sale";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { SalesService } from "../services/sales.service";
import { PopupType } from "src/app/shared/enums/popup-types";
import { MessagePortalComponent } from "src/app/portal/portal";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { ModalService } from "src/app/shared/services/modal.service";
import { animate, style, transition, trigger } from "@angular/animations";
import { PositioningService } from "src/app/shared/services/positioning.service";
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";

@Component({
  selector: "app-draft-table",
  templateUrl: "./draft.table.component.html",
  animations: [
    trigger("toggleAnimation", [
      transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
      transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
    ]),
  ],
})
export class DraftTableComponent implements OnInit {
  constructor(
    private modalService: ModalService,
    private positioningService: PositioningService,
    public fb: FormBuilder,
    private salesService: SalesService,
    public translate: TranslateService,
    private router: Router,
    private ref: ChangeDetectorRef
  ) {}
  @ViewChild("addTaskModal") addTaskModal!: ModalComponent;
  @ViewChild("modal2") modal!: ModalComponent;
  @ViewChild("dropdownPlaceholder") dropdownPlaceholder!: ElementRef;
  @ViewChild(MessagePortalComponent) messagePortalComponent!: MessagePortalComponent;

  defaultParams = {
    title: "",
    englishMessage: "",
    nativeMessage: "",
  };
  public loading = false;
  isShowTaskMenu = false;

  params!: FormGroup;

  public allTasks: DraftSale[] = [];
  pagedTasks: any = [];
  searchTask = "";

  pager = {
    currentPage: 1,
    totalPages: 0,
    pageSize: 10,
    startIndex: 0,
    endIndex: 0,
  };

  editorOptions = {
    toolbar: [[{ header: [1, 2, false] }], ["bold", "italic", "underline", "link"], [{ list: "ordered" }, { list: "bullet" }], ["clean"]],
  };
  onPortalAttached() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 0);
  }

  ngOnInit() {
    this.fetchDrafts();
  }

  private fetchDrafts() {
    this.loading = true;
    this.salesService.getDrafts().subscribe((response) => {
      const travel = response.map((t) => {
        const destinations = t.travelDestinations?.map((td) => td.destination?.description).join(", ") ?? "";
        const hotels = t.travelDestinations?.map((td) => td.hotel?.name).join(", ") ?? "";
        return {
          id: t.id,
          title: `${t.customer?.name} ${t.customer?.surname}  ${destinations}`,
          date: `${t.days} ${this.translate.instant("draft_table.days")} / ${t.nights} ${this.translate.instant("draft_table.nights")} `,
          description: `${moment(t.start).format("DD.MM.YYYY")} ${moment(t.end).format("DD.MM.YYYY")}`,
          descriptionText: `${hotels}`,
          status: t.plan.status,
        };
      });
      this.loading = false;
      this.allTasks = [...travel];
      this.searchTasks();
    });
  }
  prepareAnouncement(id: number) {
    this.router.navigate(["/dashboard/travels/draft/accept/" + id], {
      queryParams: {
        activeTab: 4,
      },
    });
  }

  editDraftAndPrepare(id: number) {
    this.router.navigate(["/dashboard/travels/draft/edit/" + id], {
      queryParams: {
        activeTab: 3,
      },
    });
  }

  initForm() {
    this.params = this.fb.group({
      title: [""],
      englishMessage: [""],
      nativeMessage: [""],
    });
  }

  searchTasks(isResetPage = true) {
    if (isResetPage) {
      this.pager.currentPage = 1;
    }

    this.getPager();
  }

  getPager() {
    setTimeout(() => {
      if (this.allTasks.length) {
        this.pager.totalPages = this.pager.pageSize < 1 ? 1 : Math.ceil(this.allTasks.length / this.pager.pageSize);
        if (this.pager.currentPage > this.pager.totalPages) {
          this.pager.currentPage = 1;
        }
        this.pager.startIndex = (this.pager.currentPage - 1) * this.pager.pageSize;
        this.pager.endIndex = Math.min(this.pager.startIndex + this.pager.pageSize - 1, this.allTasks.length - 1);
        this.pagedTasks = this.allTasks.slice(this.pager.startIndex, this.pager.endIndex + 1);
      } else {
        this.pagedTasks = [];
        this.pager.startIndex = -1;
        this.pager.endIndex = -1;
      }
    });
  }

  getStatusLabel(status: number) {
    switch (status) {
      case 0:
        return this.translate.instant("draft_table.pending");
        break;
      case 1:
        return this.translate.instant("draft_table.document_prepared");
        break;
      case 2:
        return this.translate.instant("draft_table.sent");
        break;
      case 3:
        return this.translate.instant("draft_table.approved");
        break;
      case 4:
        return this.translate.instant("draft_table.rejected");
        break;
    }
  }

  setDraftStatus(task: any, status: number) {
    task.status = status;
    this.searchTasks(false);
  }

  setTag(task: any, name = "") {
    this.searchTasks(false);
  }

  taskComplete(task: any = null) {
    this.searchTasks(false);
  }

  setImportant(task: any = null) {
    this.searchTasks(false);
  }

  deleteTask(task: any, type = "") {
    if (type === "delete") {
      const currtask = this.allTasks.find((d: any) => d.id === task.id);
      currtask!.status = true;
    }
    if (type === "deletePermanent") {
      this.allTasks = this.allTasks.filter((d: any) => d.id != task.id);
    } else if (type === "restore") {
      const currtask = this.allTasks.find((d: any) => d.id === task.id);
      currtask!.status = false;
    }
    this.searchTasks(false);
  }

  sendAnouncement() {
    if (!this.params.value.title) {
      showMessage(PopupType.Danger, this.translate.instant("popup.title_is_required"));
      return;
    }

    const task = this.params.value;
    task.nativeMessage = this.params.value.nativeMessage; //this.quillEditorObj.getText();
    task.englishMessage = this.params.value.englishMessage;

    this.allTasks.splice(0, 0, task);
    this.searchTasks();

    showMessage(PopupType.Success, this.translate.instant("popup.task_has_been_saved_successfully"));
    this.addTaskModal.close();
  }

  setEnglishMessage(event: any) {
    this.params.patchValue({ englishMessage: event.text });
  }

  setNativeMessage(event: any) {
    this.params.patchValue({ nativeMessage: event.text });
  }

  async deleteDraftTravel(id: number) {
    const confirmed = await this.modalService.confirm();
    if (confirmed) {
      this.salesService.deleteDraftTravel(id).subscribe((r) => {
        showMessage(PopupType.Success, this.translate.instant("popup.successfully_deleted_started_sale"));
        this.fetchDrafts();
        this.modal.close();
      });
    }
  }
}
