import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: 'app-chart-statistics',
	templateUrl: './chart-statistics.component.html',
})
export class ChartStatisticsComponent implements OnChanges {
	@Input() popularDestinationsByTicket!: { destination: string; numberOfPlans: number }[];
	@Input() popularDestinationsByTravel!: { destination: string; numberOfPlans: number }[];
	@Input() popularDestinationsByGroupTravel!: { destination: string; numberOfPlans: number }[];
	@Input() popularDestinationsByTransfer!: { destination: string; numberOfPlans: number }[];

	columnCharts: any = {};

	constructor(private translate: TranslateService) { }

	ngOnChanges(changes: SimpleChanges) {
		if (changes['popularDestinationsByTicket']) {
			this.createColumnChart('popularDestinationsByTicket', this.popularDestinationsByTicket);
		}
		if (changes['popularDestinationsByTravel']) {
			this.createColumnChart('popularDestinationsByTravel', this.popularDestinationsByTravel);
		}
		if (changes['popularDestinationsByGroupTravel']) {
			this.createColumnChart('popularDestinationsByGroupTravel', this.popularDestinationsByGroupTravel);
		}
		if (changes['popularDestinationsByTransfer']) {
			this.createColumnChart('popularDestinationsByTransfer', this.popularDestinationsByTransfer);
		}
	}



	createColumnChart(key: string, data: { destination: string; numberOfPlans: number }[]) {
		const isDark = true;
		const isRtl = false;

		const categories = data.map(item => item.destination);
		const seriesData = data.map(item => item.numberOfPlans);

		this.columnCharts[key] = {
			series: [{
				name: 'Plans',
				data: seriesData
			}],
			chart: {
				height: 350,
				type: "bar",

			},
			colors: ["#378CE7", "#67C6E3"],
			dataLabels: {
				enabled: false,
			},
			stroke: {
				show: true,
				width: 2,
				colors: ["transparent"],
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: "40%",
					endingShape: "rounded",
				},
			},
			grid: {
				borderColor: isDark ? "#378CE7" : "#67C6E3",
			},
			xaxis: {
				type: "category",
				categories: categories,
				axisBorder: {
					color: isDark ? "#378CE7" : "#67C6E3",
				},

			},
			yaxis: {
				opposite: isRtl ? true : false,
				labels: {
					offsetX: isRtl ? -10 : 0,
				},
			},
			tooltip: {
				theme: isDark ? "dark" : "light",
				y: {
					formatter: function (val: any) {
						return val;
					},
				},
			},
		};
	}
}
