<div class="space-y-8">
  <div class="panel pb-0">
    <div class="mb-5 flex flex-col gap-5 md:flex-row md:items-center">
      <div class="grid">
        <input [(ngModel)]="search" (keydown.enter)="onSearchEnter()" type="text" class="form-input" placeholder="{{ 'search_placeholder' | translate }}" />
      </div>
      <app-global-table-filters [total]="totalRecords" [withDept]="withDeptPlans" [withoutDept]="withoutDeptPlans" (selectFilter)="onSelectionChange($event)"></app-global-table-filters>

      <div class="flex items-center gap-5 ltr:ml-auto rtl:mr-auto">
        <div hlMenu class="dropdown">
          <button hlMenuButton type="button" class="flex items-center rounded-md border border-[#e0e6ed] px-4 py-2 text-sm font-semibold dark:border-[#253b5c] dark:bg-[#1b2e4b] dark:text-white-dark">
            <span class="ltr:mr-1 rtl:ml-1">{{ "table_columns" | translate }}</span>
            <icon-caret-down class="h-5 w-5" />
          </button>
          <ul *hlMenuItems @toggleAnimation class="w-max ltr:left-0 rtl:right-0">
            <ng-container *ngFor="let col of cols; index as i">
              <li>
                <div class="flex items-center px-4 py-1">
                  <label [for]="'chk-' + i" class="mb-0 cursor-pointer">
                    <input type="checkbox" [id]="'chk-' + i" class="form-checkbox" [(ngModel)]="!col.hide" (change)="updateColumn(col)" />
                    <span class="ltr:ml-2 rtl:mr-2">{{ col.title }}</span>
                  </label>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
        <app-export-table
          [exportApi]="'api/Travels/export'"
          [filterType]="filterType"
          [columns]="exportCols"
          [filename]="'Travel Table Export'"
          [sortColumn]="sortColumn"
          [sortDirection]="sortDirection"
          [search]="search"
        ></app-export-table>
      </div>
    </div>
    <div class="datatable">
      <ng-datatable
        [rows]="items"
        [columns]="cols"
        [sortable]="true"
        [isServerMode]="true"
        [pageSize]="pageSize"
        [totalRows]="totalRows"
        (changeServer)="changeServer($event)"
        skin="whitespace-nowrap table-hover"
        [paginationInfo]="'paginationInfoLabel' | translate"
        [noDataContent]="'empty_tables' | translate"
      >
        <ng-template slot="actions" let-value="data">
          <div class="dropdown inline-flex">
            <button
              type="button"
              [ngxTippy]="tooltipTemplate"
              [tippyName]="value.id"
              [tippyContext]="{ data: value }"
              [tippyProps]="{ placement: 'right', interactive: true }"
              class="btn btn-outline-primary rounded-circle"
              [routerLink]="'/dashboard/travels/' + value.id"
            >
              <div class="flex h-6 w-6 items-center gap-2">
                <icon-menu-travel></icon-menu-travel>
              </div>
            </button>
          </div>
        </ng-template>
        <ng-template slot="start" let-value="data"> {{ value.start | date : "dd.MM.yyyy" }} </ng-template>
        <ng-template slot="departureId" let-value="data"> {{ getDestinationsLabel(value) }} </ng-template>
        <ng-template slot="service" let-value="data"> {{ getServicesLabel(value) }} </ng-template>
        <ng-template slot="numberOfPassangers" let-value="data"> {{ value.numberOfPassangers }} </ng-template>
        <ng-template slot="customerId" let-value="data">
          <span>{{ value.customer.surname }}, {{ value.customer.name }}</span>
        </ng-template>
        <ng-template slot="periodOfTime" let-value="data">
          <span>{{ value.start | date : "dd.MM.yyyy" }} - {{ value.end | date : "dd.MM.yyyy" }}</span>
        </ng-template>
        <ng-template slot="createdAt" let-value="data">
          <span>{{ value.createdAt | date : "dd.MM.yyyy" }}</span>
        </ng-template>
        <ng-template slot="profit" let-value="data">
          <span [style.color]="'green'">{{ "€" + calculateProfit(value.plan.neto, value.plan.bruto) }}</span>
        </ng-template>
        <ng-template slot="dept" let-value="data">
          <span [style.color]="'red'">{{ "€" + calculateDept(value.plan.bruto, value.plan.payments) }}</span>
        </ng-template>
        <ng-template slot="departureId" let-value="data">
          <span>{{ value.departure?.description }}</span>
        </ng-template>
        <ng-template slot="details" let-value="data">
          <div class="flex w-max items-center justify-between">
            <div hlMenu class="dropdown">
              <a hlMenuButton href="javascript:;">
                <icon-horizontal-dots class="rotate-90 opacity-70" />
              </a>
              <div #dropdownPlaceholder *hlMenuItems>
                <app-message-portal #messagePortalComponent @toggleAnimation (portalAttached)="onPortalAttached()">
                  <li>
                    <a href="javascript:;" *hlMenuItem="let menuItem" [routerLink]="'/dashboard/travels/' + value.id">
                      <icon-info-circle class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                      {{ "details_quick_label" | translate }}
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" *hlMenuItem="let menuItem" [routerLink]="'/dashboard/travels/edit/' + value.id">
                      <icon-pencil-paper class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                      {{ "edit_quick_label" | translate }}
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" *hlMenuItem="let menuItem" (click)="deleteTravel(value.id)">
                      <icon-trash-lines class="shrink-0 ltr:mr-2 rtl:ml-2" />
                      {{ "delete_quick_label" | translate }}
                    </a>
                  </li>
                </app-message-portal>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-datatable>
    </div>
  </div>
  <ng-template #tooltipTemplate let-name let-data="data">
    <app-travel-quick-view [data]="data"></app-travel-quick-view>
  </ng-template>
</div>
