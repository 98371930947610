import { Component, AfterViewInit, OnDestroy, ViewChild, ElementRef, Inject, Output, EventEmitter } from "@angular/core";
import { CdkPortal, DomPortalOutlet } from "@angular/cdk/portal";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-message-portal",
  template: `
    <ng-template cdk-portal>
      <div #overlay class="overlay">
        <ul #dropdown class="ul-up hidden w-max ltr:right-0 rtl:left-0">
          <ng-content></ng-content>
        </ul>
      </div>
    </ng-template>
  `,
})
export class MessagePortalComponent implements AfterViewInit, OnDestroy {
  @ViewChild(CdkPortal, { static: true }) portal!: CdkPortal;
  @ViewChild("dropdown") dropdown!: ElementRef;
  @Output() portalAttached = new EventEmitter<void>();

  private portalOutlet!: DomPortalOutlet;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngAfterViewInit() {
    this.portalOutlet = new DomPortalOutlet(this.document.body);
    this.portalOutlet.attach(this.portal);
    this.portalAttached.emit(); // Emit event when attached
  }

  ngOnDestroy() {
    this.portalOutlet.detach();
  }

  setPosition(position: { top: number; left: number }) {
    const dropdownElement = this.dropdown.nativeElement as HTMLElement;
    dropdownElement.style.top = `${position.top}px`;
    dropdownElement.style.left = `${position.left}px`;
    dropdownElement.style.display = `block`;
    dropdownElement.style.transform = `translateX(-100%)`;
  }
}
