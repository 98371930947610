<form class="space-y-5 py-5" [formGroup]="form" (ngSubmit)="submitForm()" novalidate>
  <div class="grid grid-cols-1 gap-5 md:grid-cols-2">
    <div [ngClass]="form.controls['destination'].touched ? (form.controls['destination'].errors ? 'has-error' : 'has-success') : ''">
      <label for="customDestination">{{ "travel_create.destination" | translate }}</label>
      <app-autocomplete-remote
        [type]="EntityPicklistType.Destinations"
        labelName="description"
        placeholder="{{ 'travel_create.search_for_destination' | translate }}"
        [selectControl]="form"
        labelForId="customDestination"
        formControlName="destination"
        [addCallback]="utilService.destinationAdd('destination', form).bind(utilService)"
      ></app-autocomplete-remote>
      <ng-container *ngIf="form.controls['destination'].touched && !form.controls['destination'].errors">
        <p class="mt-1 text-[#1abc9c]">{{ "travel_create.looks_good" | translate }}</p>
      </ng-container>
      <ng-container *ngIf="form.controls['destination'].touched && form.controls['destination'].errors">
        <p class="mt-1 text-danger">{{ "travel_create.please_provide_destination" | translate }}</p>
      </ng-container>
    </div>
    <div [ngClass]="form.controls['hotel'].touched ? (form.controls['hotel'].errors ? 'has-error' : 'has-success') : ''">
      <label for="customHotel">{{ "travel_create.hotel" | translate }}</label>
      <app-autocomplete-remote
        [type]="EntityPicklistType.Hotels"
        labelName="name"
        placeholder="{{ 'travel_create.search_for_hotel' | translate }}"
        [selectControl]="form"
        labelForId="customHotel"
        formControlName="hotel"
        [addCallback]="utilService.hotelsAdd('hotel', form).bind(utilService)"
      ></app-autocomplete-remote>
      <ng-container *ngIf="form.controls['hotel'].touched && !form.controls['hotel'].errors">
        <p class="mt-1 text-[#1abc9c]">{{ "travel_create.looks_good" | translate }}</p>
      </ng-container>
      <ng-container *ngIf="form.controls['hotel'].touched && form.controls['hotel'].errors">
        <p class="mt-1 text-danger">{{ "travel_create.please_provide_hotel" | translate }}</p>
      </ng-container>
    </div>
  </div>
  <div class="grid grid-cols-1 gap-5 md:grid-cols-4">
    <div class="md:col-span-2" [ngClass]="form.controls['start'].touched ? (form.controls['start'].errors ? 'has-error' : 'has-success') : ''">
      <label for="customStart">{{ "travel_create.start" | translate }}</label>
      <input id="customStart" type="text" class="form-input" formControlName="start" class="form-input" placeholder="__.__.____" [textMask]="{ mask: dateMask }" />
      <ng-container *ngIf="form.controls['start'].touched && !form.controls['start'].errors">
        <p class="mt-1 text-[#1abc9c]">{{ "travel_create.looks_good" | translate }}</p>
      </ng-container>
      <ng-container *ngIf="form.controls['start'].touched && form.controls['start'].errors">
        <p class="mt-1 text-danger">{{ "travel_create.please_provide_start" | translate }}</p>
      </ng-container>
    </div>
    <div [ngClass]="form.controls['days'].touched ? (form.controls['days'].errors ? 'has-error' : 'has-success') : ''">
      <label for="customDays">{{ "travel_create.days" | translate }}</label>
      <input id="customDays" type="text" placeholder="{{ 'travel_create.num_of_days' | translate }}" class="form-input" formControlName="days" (focusout)="setDaysNights()" />
      <ng-container *ngIf="form.controls['days'].touched && form.controls['days'].errors">
        <p class="mt-1 text-danger">{{ "travel_create.please_provide_days" | translate }}</p>
      </ng-container>
    </div>
    <div [ngClass]="form.controls['nights'].touched ? (form.controls['nights'].errors ? 'has-error' : 'has-success') : ''">
      <label for="customNights">{{ "travel_create.nights" | translate }}</label>
      <input id="customNights" type="text" placeholder="{{ 'travel_create.num_of_nights' | translate }}" class="form-input" formControlName="nights" />
      <ng-container *ngIf="form.controls['nights'].touched && form.controls['nights'].errors">
        <p class="mt-1 text-danger">{{ "travel_create.please_provide_nights" | translate }}</p>
      </ng-container>
    </div>
  </div>
  <div class="grid grid-cols-1 gap-5 md:grid-cols-4">
    <div class="md:col-span-2" [ngClass]="form.controls['end'].touched ? (form.controls['end'].errors ? 'has-error' : 'has-success') : ''">
      <label for="customEnd">{{ "travel_create.end" | translate }}</label>
      <input id="customEnd" type="text" class="form-input" formControlName="end" placeholder="__.__.____" [textMask]="{ mask: dateMask }" />
      <ng-container *ngIf="form.controls['end'].touched && !form.controls['end'].errors">
        <p class="mt-1 text-[#1abc9c]">{{ "travel_create.looks_good" | translate }}</p>
      </ng-container>
      <ng-container *ngIf="form.controls['end'].touched && form.controls['end'].errors">
        <p class="mt-1 text-danger">{{ "travel_create.please_provide_end" | translate }}</p>
      </ng-container>
    </div>
    <div class="md:col-span-2" [ngClass]="form.controls['service'].touched ? (form.controls['service'].errors ? 'has-error' : 'has-success') : ''">
      <label for="customService">{{ "travel_create.service" | translate }}</label>
      <app-select-remote id="customService" [type]="PicklistType.Service" [selectControl]="form" labelForId="customService" formControlName="service"></app-select-remote>
      <ng-container *ngIf="form.controls['service'].touched && !form.controls['service'].errors">
        <p class="mt-1 text-[#1abc9c]">{{ "travel_create.looks_good" | translate }}</p>
      </ng-container>
      <ng-container *ngIf="form.controls['service'].touched && form.controls['service'].errors">
        <p class="mt-1 text-danger">{{ "travel_create.please_provide_service" | translate }}</p>
      </ng-container>
    </div>
    <!-- </div> -->

    <div class="md:col-span-2" [ngClass]="form.controls['roomDescription'].touched ? (form.controls['roomDescription'].errors ? 'has-error' : 'has-success') : ''">
      <label for="roomType">{{ "travel_create.room_type" | translate }}</label>
      <textarea id="roomType" rows="3" class="form-textarea" placeholder="{{ 'travel_create.enter_room_type' | translate }}" formControlName="roomDescription"></textarea>
      <ng-container *ngIf="form.controls['roomDescription'].touched && form.controls['roomDescription'].errors">
        <p class="mt-1 text-danger">{{ "travel_create.please_provide_room_type" | translate }}</p>
      </ng-container>
    </div>

    <div class="mt-3 grid grid-cols-1 md:col-span-4">
      <button type="submit" class="btn btn-primary">{{ "travel_create.save" | translate }}</button>
    </div>
  </div>
</form>
