import { Store } from "@ngrx/store";
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { AppState } from "src/app/store/index.selector";
import { animate, style, transition, trigger } from "@angular/animations";

import { TranslateService } from "@ngx-translate/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { dateFormatValidator } from "src/app/shared/validators/date.validator";
import * as moment from "moment";
import { Sale, SaleBase } from "../models/sale";
import { brutoLessThanNetoValidator } from "src/app/shared/validators/sale.validator";
import { PicklistType } from "src/app/shared/enums/picklist-types";
import { Subscription } from "rxjs";

@Component({
  selector: "app-sale-create",
  templateUrl: "./sale.create.component.html",
  animations: [
    trigger("toggleAnimation", [
      transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
      transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
    ]),
  ],
})
export class SaleCreateComponent implements OnInit {
  public netoFieldType = "password";
  public form!: FormGroup;
  public isSubmitForm = false;
  public formattedDate!: string;
  public withAvans = 0;
  public sale: SaleBase = {} as SaleBase;
  PicklistType = PicklistType;
  public paymentTypeSubscription: Subscription | undefined;

  @Input() initialBruto!: number;
  @Input() initialNeto!: number;
  @Input() initialAvans!: number;

  @Input() initialSaleData: SaleBase = {} as SaleBase;

  @Output() submitSale: EventEmitter<Sale> = new EventEmitter<Sale>();

  constructor(public fb: FormBuilder, public store: Store<AppState>, public translate: TranslateService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["initialBruto"] || changes["initialNeto"] || changes["initialAvans"]) {
      this.initForm({
        bruto: this.initialBruto,
        neto: this.initialNeto,
        avans: this.initialAvans,
      } as Sale);
    }
  }
  initFormIntern(sale: SaleBase, withAvans = false) {
    const lastDayOfYear = moment().endOf("year");
    this.formattedDate = lastDayOfYear.format("DD.MM.YYYY");

    const {
      status = withAvans,
      bruto = this.form ? this.form.controls["bruto"].value : this.initialBruto,
      neto = this.form ? this.form.controls["neto"].value : "",
      avans = this.form ? this.form.controls["avans"].value : "",
      dueDate = this.form ? this.form.controls["dueDate"].value : this.formattedDate,
      paymentType = this.form ? this.form.controls["paymentType"].value : "",
      paymentTypeId = 2,
    } = sale || {};

    this.form = this.fb.group(
      {
        bruto: [bruto, Validators.required],
        neto: [neto, Validators.required],
        avans: [avans, status ? Validators.required : []],
        dueDate: [dueDate, status ? { validators: [Validators.required, dateFormatValidator()], updateOn: "blur" } : []],
        paymentType: [paymentType, { validators: [Validators.required], updateOn: "blur" }],
        paymentTypeId: [null],
      },

      { validators: brutoLessThanNetoValidator }
    );
    this.paymentTypeSubscription = this.form.get("paymentType")?.valueChanges.subscribe((value) => {
      const paymentTypeIdControl = this.form.get("paymentTypeId");
      if (paymentTypeIdControl) {
        paymentTypeIdControl.setValue(value?.id || null);
        paymentTypeIdControl.updateValueAndValidity();
      }
    });
  }
  ngOnDestroy() {
    if (this.paymentTypeSubscription) {
      this.paymentTypeSubscription.unsubscribe();
    }
  }

  dateMask = [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/];

  reveal() {
    this.netoFieldType = "text";
  }

  hide() {
    this.netoFieldType = "password";
  }

  checkIfValid(): boolean {
    this.form.updateValueAndValidity();
    return this.form.valid;
  }

  getSale(): Sale {
    return { ...this.sale, ...this.form.getRawValue(), withAvans: this.withAvans };
  }

  submitForm() {
    this.isSubmitForm = true;
    this.submitSale.emit(this.form.getRawValue());
  }

  setWithAvans(value: number) {
    this.withAvans = value;
    this.initFormIntern({} as SaleBase, Boolean(this.withAvans));
  }

  initForm(sale: SaleBase = {} as SaleBase) {
    this.sale = sale;
    this.withAvans = this.sale.avans != null && this.sale.avans > 0 ? 1 : 0;
    this.initFormIntern(sale, Boolean(this.withAvans));
  }

  ngOnInit(): void {
    this.initForm(this.initialSaleData);
    this.setWithAvans(1);
  }
}
