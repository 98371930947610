<div>
  <div class="mb-5 flex flex-col gap-5 md:flex-row md:items-center">
    <h5 class="text-lg font-semibold dark:text-white-light">{{ "office-branch.title" }}</h5>
    <div>
      <input [(ngModel)]="search" (keydown.enter)="onSearchEnter()" type="text" class="form-input" placeholder="{{ 'search_placeholder' | translate }}" />
    </div>
    <div class="flex items-center gap-2 ltr:ml-auto rtl:mr-auto">
      <button type="button" class="btn btn-outline-success flex" (click)="openModalForCreating()">
        <icon-plus class="ltr:mr-2 rtl:ml-2" />
        {{ "office-branch.add_office-branch" | translate }}
      </button>
      <div>
        <modal #addOfficeLocationModal class="modal-top overflow-allowed">
          <ng-template #modalHeader>
            <div class="!py-5" *ngIf="!isEdit">
              {{ "office-branch.add_office-branch" | translate }}
            </div>
            <div class="!py-5" *ngIf="isEdit">
              {{ "office-branch.edit_office-branch" | translate }}
            </div>
          </ng-template>
          <ng-template #modalBody>
            <div class="grid grid-cols-1 gap-5 md:grid-cols-1" *ngIf="branchId">
              <app-office-branch-location-form [id]="branchId" (submissionDone)="closeLocationsModal()"></app-office-branch-location-form>
            </div>
          </ng-template>
          <ng-template #modalFooter></ng-template>
        </modal>
        <modal #createOfficeModal class="modal-top wider-modal">
          <ng-template #modalHeader>
            <div class="!py-5" *ngIf="!isEdit">
              {{ "office-branch.add_office-branch" | translate }}
            </div>
            <div class="!py-5" *ngIf="isEdit">
              {{ "office-branch.edit_office-branch" | translate }}
            </div>
          </ng-template>
          <ng-template #modalBody>
            <div class="grid grid-cols-1 gap-5 md:grid-cols-1">
              <app-office-branch-form [id]="branchId" (submissionDone)="closeModal()" #officeBranchForm></app-office-branch-form>
            </div>
          </ng-template>
          <ng-template #modalFooter></ng-template>
        </modal>
        <modal #deleteConfirmationModal class="custom-modal">
          <ng-template #modalBody>
            <div class="flex items-center justify-center text-base font-medium text-[#1f2937] dark:text-white-dark/70">
              <div class="flex h-16 w-16 items-center justify-center rounded-full bg-[#f1f2f3] dark:bg-white/10">
                <icon-bell-larger></icon-bell-larger>
              </div>
            </div>
            <div class="flex items-center justify-center p-5">
              <p>{{ "office-branch.delete_office-branch" | translate }}</p>
            </div>

            <div class="flex items-center justify-center gap-5">
              <button type="button" class="btn btn-outline-danger w-16" (click)="deleteOfficeBranch()">Yes</button>
              <button type="button" class="btn btn-primary w-16" (click)="deleteConfirmationModal.close()">No</button>
            </div>
          </ng-template>
        </modal>
      </div>

      <div hlMenu class="dropdown">
        <button hlMenuButton type="button" class="flex items-center rounded-md border border-[#e0e6ed] px-4 py-2 text-sm font-semibold dark:border-[#253b5c] dark:bg-[#1b2e4b] dark:text-white-dark">
          <span class="ltr:mr-1 rtl:ml-1">{{ "table_columns" | translate }}</span>
          <icon-caret-down class="h-5 w-5" />
        </button>
        <ul *hlMenuItems @toggleAnimation class="w-max ltr:left-0 rtl:right-0">
          <ng-container *ngFor="let col of cols; index as i">
            <li>
              <div class="flex items-center px-4 py-1">
                <label [for]="'chk-' + i" class="mb-0 cursor-pointer">
                  <input type="checkbox" [id]="'chk-' + i" class="form-checkbox" [(ngModel)]="!col.hide" (change)="updateColumn(col)" />
                  <span class="ltr:ml-2 rtl:mr-2">{{ col.title }}</span>
                </label>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>

  <div class="datatable">
    <ng-datatable
      [rows]="items"
      [columns]="cols"
      [sortable]="true"
      [search]="search"
      [pageSize]="pageSize"
      [totalRows]="totalRows"
      [isServerMode]="true"
      (changeServer)="changeServer($event)"
      skin="whitespace-nowrap table-hover"
      [paginationInfo]="'paginationInfoLabel' | translate"
      [noDataContent]="'empty_tables' | translate"
    >
      <ng-template slot="bankAccount" let-value="data">
        {{ value.bankAccount }}
      </ng-template>
      <ng-template slot="name" let-value="data">
        <span>{{ value.name }}</span>
      </ng-template>
      <ng-template slot="city" let-value="data">
        {{ value.city }}
      </ng-template>
      <ng-template slot="email" let-value="data">
        {{ value.email }}
      </ng-template>
      <ng-template slot="guid" let-value="data">
        {{ value.guid }}
      </ng-template>
      <ng-template slot="municipality" let-value="data">
        {{ value.municipality }}
      </ng-template>
      <ng-template slot="phoneNumber" let-value="data">
        {{ value.city }}
      </ng-template>
      <ng-template slot="phoneNumber" let-value="data">
        {{ value.city }}
      </ng-template>
      <ng-template slot="pib" let-value="data">
        {{ value.pib }}
      </ng-template>
      <ng-template slot="street" let-value="data">
        {{ value.street }}
      </ng-template>
      <ng-template slot="actions" let-value="data">
        <div class="flex items-center gap-1">
          <div>
            <a type="button" class="btn btn-dark h-10 w-10 rounded-full p-0" ngxTippy="Edit" (click)="openModalForEditing(value.id)">
              <icon-pencil />
            </a>
          </div>
          <div>
            <a type="button" ngxTippy="Delete" class="btn btn-dark h-10 w-10 rounded-full p-0" (click)="openModalForDeleting(value.id)">
              <icon-trash-lines />
            </a>
          </div>
          <div>
            <a type="button" ngxTippy="Delete" class="btn btn-dark h-10 w-10 rounded-full p-0" (click)="openModalAddingOfficeLocation(value.id)">
              <icon-map-pin />
            </a>
          </div>
        </div>
      </ng-template>
    </ng-datatable>
  </div>
</div>
