import * as moment from "moment";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import { Travel } from "../models/travel";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { UtilService } from "src/app/shared/services/util.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { animate, style, transition, trigger } from "@angular/animations";
import { dateFormatValidator } from "src/app/shared/validators/date.validator";
import { PicklistType, EntityPicklistType } from "src/app/shared/enums/picklist-types";
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";

@Component({
  selector: "app-travel-create",
  templateUrl: "./travel.create.component.html",
  animations: [
    trigger("toggleAnimation", [
      transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
      transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
    ]),
  ],
})
export class TravelCreateComponent implements OnInit, AfterViewInit {
  public id: number | undefined;
  travel: Travel | undefined;

  PicklistType = PicklistType;
  EntityPicklistType = EntityPicklistType;

  public form!: FormGroup;
  public isSubmitForm = false;

  public travelTypeSubscription: Subscription | undefined;
  @Input() showAvans = false;
  @Output() notify: EventEmitter<{ start: string; end: string }> = new EventEmitter();
  @ViewChild("startInput") startInput!: ElementRef;

  constructor(public store: Store<AppState>, public translate: TranslateService, public fb: FormBuilder, public utilService: UtilService) {}

  initForm(travel: Travel) {
    const { id = this.id, start, end, typeId = 5, type, days, nights, organizer, departure } = travel || ({} as Travel);
    this.id = id;
    this.travel = travel;

    this.form = this.fb.group({
      start: [start, { validators: [Validators.required, dateFormatValidator()], updateOn: "blur" }],
      end: [end, { validators: [Validators.required, dateFormatValidator()], updateOn: "blur" }],
      type: [type, { validators: [Validators.required], updateOn: "blur" }],
      days: [days, { validators: [Validators.required], updateOn: "blur" }],
      nights: [nights, { validators: [Validators.required], updateOn: "blur" }],
      organizer: [organizer, Validators.required],
      departure: [departure],
      avans: ["", Validators.required],
    });

    this.travelTypeSubscription = this.form.get("type")?.valueChanges.subscribe((value) => {
      const departControl = this.form.get("depart");
      if (departControl) {
        if (value.id !== 7) {
          departControl.clearValidators();
        } else {
          departControl.setValidators([Validators.required]);
        }
        departControl.updateValueAndValidity();
      }
    });
  }

  setDaysNights() {
    if (!this.form.controls["start"].errors && !!this.form.controls["start"].value) {
      const start = moment(this.form.controls["start"].value, "DD.MM.YYYY", true);
      const newEndDate = start.add(this.form.controls["days"].value, "days");

      this.form.controls["end"].setValue(newEndDate.format("DD.MM.YYYY"));
    }
  }

  dateMask = [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/];

  submitForm() {
    this.isSubmitForm = true;
  }

  checkIfValid(): boolean {
    this.form.updateValueAndValidity();
    return this.form.valid;
  }

  ngOnInit(): void {
    this.initForm({} as Travel);
  }

  getTravel(): Travel {
    return { ...this.form.getRawValue(), id: this.id };
  }

  ngOnDestroy() {
    if (this.travelTypeSubscription) {
      this.travelTypeSubscription.unsubscribe();
    }
  }

  onEndBlur() {
    this.notify.emit({ start: this.form.controls["start"].value, end: this.form.controls["end"].value });
  }

  ngAfterViewInit() {
    this.startInput.nativeElement.focus();
  }
}
