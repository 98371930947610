import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { AuthService } from "../auth/service/auth.service";
import { PackageConfig } from "../auth/model/user";
import { PopupType } from "../shared/enums/popup-types";
import { showMessage } from "../shared/utils/toast.popup";

@Injectable({
  providedIn: "root",
})
export class PackageRightsGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const redirectTo = (route.data["redirectTo"] as string) || "/404";
    const packageRequirment = route.data["packageRequirment"] as keyof PackageConfig;

    return this.authService.getPackageConfiguration().pipe(
      map((packageConfig: PackageConfig) => {
        if (packageConfig[packageRequirment] === true) {
          return true;
        } else {
          this.router.navigate([redirectTo]);
          return false;
        }
      }),
      catchError((error) => {
        showMessage(PopupType.Danger, error);

        this.router.navigate(["/auth/login"]);
        return of(false);
      })
    );
  }
}
