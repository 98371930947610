<div>
  <div class="relative flex h-[60%] gap-5 pt-5 sm:h-[40%]">
    <div class="panel h-[60%] flex-1 overflow-auto p-0">
      <div class="flex h-[50%] flex-col">
        <div class="flex w-full flex-col gap-4 p-4 sm:flex-row sm:items-center">
          <div class="flex items-center ltr:mr-3 rtl:ml-3">
            <h4 class="bold">{{ "draft_table.started_sales" | translate }}</h4>
          </div>
          <div class="flex flex-1 items-center justify-center sm:flex-auto sm:justify-end">
            <p class="ltr:mr-3 rtl:ml-3">{{ pager.startIndex + 1 + "-" + (pager.endIndex + 1) + " of " + allTasks.length }}</p>
            <button
              type="button"
              [disabled]="pager.currentPage === 1"
              class="rounded-md bg-[#f4f4f4] p-1 enabled:hover:bg-primary-light disabled:cursor-not-allowed disabled:opacity-60 dark:bg-white-dark/20 enabled:dark:hover:bg-white-dark/30 ltr:mr-3 rtl:ml-3"
              (click)="pager.currentPage = pager.currentPage - 1; searchTasks(false)"
            >
              <icon-caret-down class="h-5 w-5 rotate-90 rtl:-rotate-90" />
            </button>
            <button
              type="button"
              [disabled]="pager.currentPage === pager.totalPages"
              class="rounded-md bg-[#f4f4f4] p-1 enabled:hover:bg-primary-light disabled:cursor-not-allowed disabled:opacity-60 dark:bg-white-dark/20 enabled:dark:hover:bg-white-dark/30"
              (click)="pager.currentPage = pager.currentPage + 1; searchTasks(false)"
            >
              <icon-caret-down class="h-5 w-5 -rotate-90 rtl:rotate-90" />
            </button>
          </div>
        </div>
        <div class="h-px w-full border-b border-[#e0e6ed] dark:border-[#1b2e4b]"></div>
        <ng-container *ngIf="pagedTasks.length">
          <div class="table-responsive min-h-[250px] grow overflow-y-auto sm:min-h-[300px]">
            <table class="table-hover">
              <tbody>
                <ng-container *ngFor="let task of pagedTasks">
                  <tr class="group cursor-pointer" [ngClass]="{ 'bg-white-light/30 dark:bg-[#1a2941]': task.status === 'complete' }">
                    <td class="w-1 px-2 py-0">
                      <div class="badge m-0 h-full w-8 p-0">
                        <icon-menu-travel class="h-full w-full" />
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="whitespace-nowrap text-base font-semibold group-hover:text-primary" [ngClass]="{ 'line-through': task.status === 'complete' }">
                          {{ task.title }}
                        </div>
                        <div class="line-clamp-1 min-w-[300px] overflow-hidden text-white-dark" [ngClass]="{ 'line-through': task.status === 'complete' }">
                          {{ task.descriptionText }}
                        </div>
                      </div>
                    </td>
                    <td class="w-1">
                      <div class="flex items-center space-x-2 ltr:justify-end rtl:justify-start rtl:space-x-reverse">
                        <ng-container *ngIf="task.status >= 0">
                          <div hlMenu class="dropdown">
                            <a
                              href="javascript:;"
                              class="badge whitespace-nowrap rounded-full capitalize hover:top-0 hover:text-white"
                              [ngClass]="{
                                'badge-outline-primary hover:bg-primary': task.status === 0,
                                'badge-outline-warning hover:bg-warning': task.status === 1,
                                'badge-outline-danger hover:bg-danger': task.status === 2,
                                'badge-outline-info hover:bg-info': task.status === 3,
                                'badge-outline-dark hover:bg-dark': task.status === 4
                              }"
                            >
                              {{ getStatusLabel(task.status) }}
                            </a>
                          </div>
                        </ng-container>
                      </div>
                    </td>
                    <td class="w-1">
                      <p class="whitespace-nowrap font-medium text-white-dark" [ngClass]="{ 'line-through': task.status === 'complete' }">
                        {{ task.date }}
                      </p>
                    </td>
                    <td class="w-1">
                      <div class="flex w-max items-center justify-between">
                        <div hlMenu class="dropdown">
                          <a hlMenuButton href="javascript:;">
                            <icon-horizontal-dots class="rotate-90 opacity-70" />
                          </a>
                          <div #dropdownPlaceholder *hlMenuItems>
                            <app-message-portal #messagePortalComponent @toggleAnimation (portalAttached)="onPortalAttached()">
                              <li>
                                <a href="javascript:;" *hlMenuItem="let menuItem" (click)="editDraftAndPrepare(task.id)">
                                  <icon-pencil-paper class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                                  {{ "draft_table.edit_and_send_again" | translate }}
                                </a>
                              </li>
                              <li>
                                <a href="javascript:;" *hlMenuItem="let menuItem" (click)="deleteDraftTravel(task.id)">
                                  <icon-trash-lines class="shrink-0 ltr:mr-2 rtl:ml-2" />
                                  {{ "draft_table.delete" | translate }}
                                </a>
                              </li>

                              <li>
                                <a href="javascript:;" *hlMenuItem="let menuItem" (click)="prepareAnouncement(task.id)">
                                  <icon-checks></icon-checks>
                                  {{ "draft_table.announcement_accepted" | translate }}
                                </a>
                              </li>
                            </app-message-portal>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </ng-container>
        <ng-container *ngIf="!pagedTasks.length">
          <div class="flex h-full min-h-[400px] items-center justify-center text-lg font-semibold sm:min-h-[300px]">No data available</div>
        </ng-container>
      </div>
    </div>
  </div>

  <modal #addTaskModal class="no-footer">
    <ng-template #modalHeader>
      <div class="!font-medium">{{ "draft_table.send_announcement" | translate }}</div>
    </ng-template>
    <ng-template #modalBody>
      <form [formGroup]="params" class="text-sm" (submit)="sendAnouncement()">
        <div class="mb-5">
          <label for="title">Title</label>
          <input id="title" type="text" placeholder="Enter Task Title" class="form-input" formControlName="title" />
        </div>
        <div class="mb-5">
          <label for="nativeMessage">Description</label>
          <textarea id="nativeMessage" rows="3" class="form-textarea" placeholder="" formControlName="nativeMessage"></textarea>
        </div>
        <div class="mb-5">
          <label for="englishMessage">Description</label>
          <textarea id="englishMessage" rows="3" class="form-textarea" placeholder="" formControlName="englishMessage"></textarea>
        </div>
        <div class="mt-8 flex items-center justify-end ltr:text-right rtl:text-left">
          <button type="button" class="btn btn-outline-danger" (click)="addTaskModal.close()">Cancel</button>
          <button type="submit" class="btn btn-primary ltr:ml-4 rtl:mr-4">{{ params.value.id ? "Update" : "Add" }}</button>
        </div>
      </form>
    </ng-template>
  </modal>
</div>
