import * as moment from "moment";
import { Store } from "@ngrx/store";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { TravelsService } from "../services/travels.service";
import { TravelDestination } from "../models/travel-destination";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { animate, style, transition, trigger } from "@angular/animations";
import { dateFormatValidator } from "src/app/shared/validators/date.validator";
import { PicklistType, EntityPicklistType } from "src/app/shared/enums/picklist-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { PopupType } from "src/app/shared/enums/popup-types";
import { UtilService } from "src/app/shared/services/util.service";

@Component({
	selector: "app-travel-destination-create",
	templateUrl: "./travel-destination.create.component.html",
	animations: [
		trigger("toggleAnimation", [
			transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
			transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
		]),
	],
})
export class TravelDestinationCreateComponent implements OnInit {
	@Input() defaultDestination: TravelDestination = {} as TravelDestination;
	@Input() id: number | null = null;
	@Output() submitDestination: EventEmitter<TravelDestination> = new EventEmitter<TravelDestination>();

	PicklistType = PicklistType;
	EntityPicklistType = EntityPicklistType;
	public form!: FormGroup;
	public isSubmitForm = false;

	constructor(public store: Store<AppState>, public translate: TranslateService, public fb: FormBuilder, public utilService: UtilService) { }

	initForm(travel: TravelDestination | undefined) {
		const { travelId = this.id, destination, hotel, start, end, service, days, nights, roomDescription } = this.defaultDestination || ({} as TravelDestination);
		this.id = travelId;

		this.form = this.fb.group({
			destination: [destination, { validators: [Validators.required], updateOn: "blur" }],
			hotel: [hotel, { validators: [Validators.required], updateOn: "blur" }],
			start: [start, { validators: [Validators.required, dateFormatValidator()], updateOn: "blur" }],
			end: [end, { validators: [Validators.required, dateFormatValidator()], updateOn: "blur" }],
			service: [service, { validators: [Validators.required], updateOn: "blur" }],
			days: [days, { validators: [Validators.required], updateOn: "blur" }],
			nights: [nights, { validators: [Validators.required], updateOn: "blur" }],
			roomDescription: [roomDescription],
		});
	}

	setDaysNights() {
		if (!this.form.controls["start"].errors && !!this.form.controls["start"].value) {
			const start = moment(this.form.controls["start"].value, "DD.MM.YYYY", true);
			const newEndDate = start.add(this.form.controls["days"].value, "days");

			this.form.controls["end"].setValue(newEndDate.format("DD.MM.YYYY"));
		}
	}

	dateMask = [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/];

	checkIfValid(): boolean {
		this.form.updateValueAndValidity();
		return this.form.valid;
	}

	ngOnInit(): void {
		this.initForm({} as TravelDestination);
	}

	getTravel(): TravelDestination {
		return { ...this.form.getRawValue(), travelId: this.id };
	}

	submitForm(): void {
		if (!this.form.valid) {
			this.form.markAllAsTouched();
			showMessage(PopupType.Warning, this.translate.instant("popup.please_fill_all_required_fields"));
			return;
		}


    const rawValues = this.form.getRawValue();
    const { start, end, days, nights, notes, service, destination, hotel, roomDescription } = rawValues;
    const editedDestination = {
      ...this.defaultDestination,
      start,
      end,
      days,
      nights,
      notes,
      service,
      serviceId: service.id,
      destination,
      hotel,
      roomDescription,
    };
    this.submitDestination.emit(editedDestination);
  }

	setStartAndEnd(event: any) {
		this.form.controls["start"].setValue(event.start);
		this.form.controls["end"].setValue(event.end);

	}
}
