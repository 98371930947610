import { Store } from "@ngrx/store";
import { User } from "./model/user";
import { Router } from "@angular/router";
import { Component } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { AuthService } from "./service/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { AppService } from "src/app/service/app.service";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { animate, style, transition, trigger } from "@angular/animations";
import { resetUser, setUser } from "../store/auth/auth.actions";

@Component({
  moduleId: module.id,
  templateUrl: "./login.html",
  animations: [
    trigger("toggleAnimation", [
      transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
      transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
    ]),
  ],
  providers: [AuthService],
})
export class LoginComponent {
  store: any;
  loading = false;
  protected loginForm: FormGroup;
  private destroy$: Subject<void> = new Subject<void>();
  constructor(public router: Router, private fb: FormBuilder, public storeData: Store<any>, private appSetting: AppService, private authService: AuthService, public translate: TranslateService) {
    this.initStore();
    this.loginForm = fb.group({
      email: new FormControl(""),
      password: new FormControl(""),
    });
  }

  async initStore() {
    this.storeData.dispatch(resetUser());
    this.storeData
      .select((d) => d.index)
      .subscribe((d) => {
        this.store = d;
      });
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  login() {
    if (this.loginForm.valid) {
      this.loading = true;
      this.authService
        .authenticate(this.loginForm.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (user: User) => {
            this.storeData.dispatch(setUser({ user }));
            this.router.navigate(["/dashboard/sales"]);
          },
          (_) => (this.loading = false)
        );
    }
  }

  changeLanguage(item: any) {
    this.translate.use(item.code);
    this.appSetting.toggleLanguage(item);
    if (this.store.locale?.toLowerCase() === "ae") {
      this.storeData.dispatch({ type: "toggleRTL", payload: "rtl" });
    } else {
      this.storeData.dispatch({ type: "toggleRTL", payload: "ltr" });
    }
    window.location.reload();
  }
}
