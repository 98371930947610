import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { OnInit, Directive } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { GenericResponse } from "../../models/generics";
import { TableService } from "../../services/table.service";

@Directive()
export abstract class BaseTableComponent<T> implements OnInit {
  public items: T[] = [];
  public cols: DatatableColumn[] = [];
  public search = "";
  public loading = false;

  public originalTransfers: any[] = [];

  public pageSize = 10;
  public totalRows = 1;
  public pageNumber = 1;

  public from!: Date;
  public to!: Date | undefined;

  public filterType = "None";
  public sortColumn!: string;
  public sortDirection!: string;
  datepick$: Observable<{ from: Date; to: Date | undefined }>;
  withDeptPlans!: number;
  withoutDeptPlans!: number;
  totalRecords!: number;

  constructor(public store: Store<AppState>, public translate: TranslateService, public datepickSelector: any, public tableService: TableService, public apiUrl: string) {
    this.datepick$ = this.store.select(datepickSelector);
  }

  ngOnInit(): void {
    this.getColumns();
    // this.fetchItems();
    this.datepick$.subscribe((el) => {
      this.from = el.from;
      this.to = el.to;
      this.fetchItems();
    });
  }

  changeServer(data: any) {
    const { sort_column, sort_direction } = data;
    this.sortColumn = sort_column;
    this.sortDirection = sort_direction;
    this.pageSize = data.pagesize;
    this.pageNumber = data.current_page;
    this.fetchItems();
  }

  onSearchEnter(): void {
    this.pageNumber = 1;
    this.fetchItems();
  }

  onSelectionChange(val: string): void {
    this.filterType = val;
    this.pageNumber = 1;
    this.fetchItems();
  }

  protected fetchItems() {
    this.loading = true;
    this.tableService
      .getItemsWithHeaders<T>(this.apiUrl, this.pageNumber, this.pageSize, this.filterType, this.from, this.to, this.sortColumn, this.sortDirection, this.search)
      .subscribe((response: GenericResponse<T>) => {
        this.loading = false;
        this.items = response.body;
        const paginationHeader = response.headers.get("X-Pagination");
        if (paginationHeader) {
          const pagination = JSON.parse(paginationHeader || "");
          this.pageNumber = pagination.CurrentPage;
          this.pageSize = pagination.PageSize;
          this.totalRecords = pagination.TotalRows ?? 0;
          this.totalRows = pagination.TotalPages ?? 0;
          this.withDeptPlans = pagination.Dept ?? 0;
          this.withoutDeptPlans = pagination.WithoutDept ?? 0;
        }
      });
  }

  getTransfersData() {
    this.tableService.getTransfers().subscribe((res) => {
      this.items = res;
      this.originalTransfers = res;
    });
  }

  updateColumn(col: DatatableColumn) {
    col.hide = !col.hide;
    this.cols = [...this.cols];
  }

  abstract getColumns(): void;
}

export interface DatatableColumn {
  condition: string;
  field: string;
  filter: boolean;
  hide: boolean;
  html: boolean;
  isUnique: boolean;
  search: boolean;
  sort: boolean;
  title: string;
  type: string;
}
