<div class="mb-6 flex flex-wrap items-center justify-center gap-4 lg:justify-end print:hidden">
  <button type="button" (click)="isShowingSidebar = !isShowingSidebar" class="btn btn-primary gap-2">
    <icon-notes></icon-notes>
    {{ "show_file_history" | translate }}
  </button>
  <app-file-upload [id]="id"></app-file-upload>
  <app-file-history [isShowingSidebar]="isShowingSidebar"></app-file-history>
</div>
<div *ngIf="transfer">
  <div class="panel relative rounded-md bg-white shadow-md dark:bg-gray-900">
    <div class="grid grid-cols-2 gap-5 gap-x-2 sm:grid-cols-4">
      <div class="col-span-2 flex grid grid-cols-2 rounded-lg p-4 sm:col-span-4 sm:grid-cols-4">
        <div class="col-span-2 grid grid-cols-2 sm:col-span-4">
          <div class="col-span-2 flex flex-col pb-4 sm:col-span-4">
            <div class="text-xl font-bold">
              {{ "transfer_details_label" | translate }}
            </div>
          </div>
        </div>

        <div class="col-span-1 flex flex-col sm:col-span-2 print:hidden">
          <div class="text-xl italic text-white-dark">
            {{ "transfer_details.name" | translate }}
          </div>
          <div class="text-xl">{{ transfer.name }}</div>
        </div>

        <div class="col-span-1 flex flex-col sm:col-span-2 print:hidden">
          <div class="mt-2 flex flex-col">
            <div class="ltr:mr-2 rtl:ml-2">{{ "transfer_details.active" | translate }}:</div>
            <div *ngIf="transfer.active; else notActive" class="badge mt-1 inline-flex w-auto self-start bg-success">
              {{ "transfer_details.active" | translate }}
            </div>
            <ng-template #notActive>
              <div class="badge mt-1 inline-flex w-auto self-start bg-slate-200 text-white">
                {{ "transfer_details.deleted" | translate }}
              </div>
            </ng-template>
          </div>
        </div>

        <div class="col-span-1 flex flex-col py-2 sm:col-span-1">
          <div>{{ "transfer_details.departure_from" | translate }} :</div>
          <div>{{ transfer.departure.description }}</div>
        </div>
        <div class="col-span-1 flex flex-col py-2 sm:col-span-3">
          <div>{{ "transfer_details.destination" | translate }} :</div>
          <div>{{ transfer.destination.description }}</div>
        </div>

        <div class="col-span-1 flex flex-col py-2 sm:col-span-1">
          <div>{{ "transfer_details.departure_date" | translate }}:</div>
          <div>{{ transfer.departureDate | date : "dd.MM.yyyy HH:mm" }}</div>
        </div>
        <div class="col-span-1 flex flex-col py-2 sm:col-span-1">
          <div>{{ "transfer_details.arrival_date" | translate }}:</div>
          <div>{{ transfer.arrivalDate | date : "dd.MM.yyyy HH:mm" }}</div>
        </div>
        <div class="col-span-2 flex flex-col py-2 sm:col-span-1">
          <div>{{ "transfer_details.capacity" | translate }}:</div>
          <div>{{ transfer.capacity }}</div>
        </div>

        <div class="col-span-2 flex flex-col py-2 sm:col-span-1">
          <div>{{ "transfer_details.type_of_vehicle" | translate }}:</div>
          <div>{{ transfer.vehicleOption.title }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="my-5 block space-y-4 overflow-x-auto rounded-lg border border-white-dark/20 p-4" *ngIf="passangers.length > 0">
    <div class="text-[20px] text-dark">{{ "transfer_details.all_passengers" | translate }}:</div>
    <div class="grid grid-cols-1 justify-center sm:grid-cols-1">
      <ng-container *ngFor="let item of passangers; index as i">
        <div
          class="flex grid grid-cols-2 items-center justify-between gap-5 gap-x-2 rounded-xl p-3 font-semibold text-gray-500 shadow-[0_0_4px_2px_rgb(31_45_61_/_10%)] transition-all duration-300 hover:scale-[1.01] hover:text-primary dark:bg-[#1b2e4b] sm:min-w-[625px] sm:grid-cols-4"
        >
          <div class="user-profile grid-cols-1 justify-self-center sm:grid-cols-1 sm:justify-self-start">
            <img [src]="item.imageUrl ? item.imageUrl : 'https://i.imgur.com/BjP9MgO.png'" alt="" class="h-8 w-8 rounded-md object-cover" />
          </div>

          <div class="grid-cols-2 px-2 sm:grid-cols-1">{{ item.name }} {{ item.surname }}</div>
          <div class="grid-cols-1 px-2 sm:grid-cols-1">
            {{ "transfer_details.phone_number" | translate }}:
            {{ item.phoneNumber ? item.phoneNumber : "N/A" }}
          </div>
          <div class="grid-cols-1 px-2 sm:grid-cols-1">
            {{ "transfer_details.date_of_birth" | translate }}:
            {{ item.dateOfBirth ? (item.dateOfBirth | date : "dd.MM.yyyy") : "N/A" }}
          </div>

          <ng-template #backUp> </ng-template>
        </div>
      </ng-container>
    </div>
  </div>

  <app-passengers-table [transferId]="id"></app-passengers-table>
</div>
