import * as moment from "moment";
import { Component, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Sale } from "src/app/domain/sales/models/sale";
import { ActivatedRoute, Router } from "@angular/router";
import { PopupType } from "src/app/shared/enums/popup-types";
import { Ticket } from "src/app/domain/tickets/models/ticket";
import { CheckoutService } from "../services/checkout.service";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { animate, style, transition, trigger } from "@angular/animations";
import { DateTimeFormatter } from "src/app/shared/utils/datetime.formatter";
import { Customer, PassangerTypes } from "../../domain/customers/models/customer";
import { SaleCreateComponent } from "src/app/domain/sales/sale.create/sale.create.component";
import { TicketCreateComponent } from "src/app/domain/tickets/ticket.create/ticket.create.component";
import { CustomerCreateComponent } from "src/app/domain/customers/customer.create/customer.create.component";
import { PassangersListComponent } from "src/app/domain/passangers/passangers.list/passangers.list.component";

@Component({
	templateUrl: "./ticket.checkout.component.html",
	animations: [
		trigger("toggleAnimation", [
			transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
			transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
		]),
	],
})
export class TicketCheckoutComponent {
	@ViewChild("mainCustomer") customerComponent!: CustomerCreateComponent;
	@ViewChild("passangerList") passangerList!: PassangersListComponent;
	@ViewChild("ticket") ticketComponent!: TicketCreateComponent;
	@ViewChild("sale") saleComponent!: SaleCreateComponent;

	public customer!: Customer;
	public ticket!: Ticket;
	public sale!: Sale;
	public id!: number;

	note!: string;

	public activeTab = 1;
	public type = "flight";

	constructor(private router: Router, private route: ActivatedRoute, public translate: TranslateService, public checkoutService: CheckoutService) {
		this.route.params.subscribe((params) => {
			this.id = params["id"];

			if (this.router.url.includes("/edit")) {
				this.fetchEntities();
			}
		});
	}

	fetchEntities() {
		this.checkoutService.fetchTicket(this.id).subscribe((response) => {
			const { passangers, customer, plan } = response;
			const customerPassangersIndex = passangers.findIndex((p) => p.id === customer.id);
			const { reservationNumber } = passangers[customerPassangersIndex];

			if (customerPassangersIndex !== -1) {
				passangers.splice(customerPassangersIndex, 1);
			}
			this.passangerList.push(passangers);

			this.customerComponent.initForm(DateTimeFormatter.formatCustomerDates({ ...customer, reservationNumber }));
			this.ticketComponent.initForm(DateTimeFormatter.formatTicketDates(response));
			this.saleComponent.initForm(DateTimeFormatter.formatPlanDates(plan));
			this.note = plan.notes ?? "";
		});
	}

	proceedToNextStage(nextStep: number | undefined = undefined) {
		if (this.activeTab === 1) {
			this.customer = this.passangerList.getPassangers().find((e) => e.lifeStage === PassangerTypes.mainCustomer) as Customer;
			console.log(this.passangerList.getPassangers());
			if (this.customer.name === undefined || this.customer.surname === undefined) {
				showMessage(PopupType.Danger, this.translate.instant("popup.main_customer_is_required"));
				return;
			}
		}
		this.ticket = this.ticketComponent.getTicket();

		this.activeTab = nextStep ?? this.activeTab + 1;
	}

	isVisibleTab(index: number) {
		return { display: this.activeTab === index ? "block" : "none" };
	}

	submitTicket() {
		if (this.saleComponent.checkIfValid() && this.ticketComponent.checkIfValid()) {
			this.sale = this.saleComponent.getSale();
			this.ticket = this.ticketComponent.getTicket();

			showMessage(PopupType.Success, this.translate.instant("ticket_edited_successfully"));
			const { id } = this.ticket;

			if (id) {
				this.checkoutService.updateTicket(this.ticket.id, this.createReservationObject()).subscribe((r) => {
					this.router.navigate(["dashboard/sales"]);
				});
			} else {
				this.checkoutService.createTicket(this.createReservationObject()).subscribe((r) => {
					showMessage(PopupType.Success, this.translate.instant("ticket_created_successfully"));
					this.router.navigate(["dashboard/sales"]);
				});
			}
		} else {
			showMessage(PopupType.Danger, this.translate.instant("forms_invalid_ticket_sale_checkout"));
		}
	}

	createReservationObject(): any {
		const { oneWay, departureDate, type, arrivalDate, departure, destination, company, reservationDate } = this.ticket;
		const { id, reservationNumber } = this.customer;
		const { bruto, neto, avans, dueDate } = this.sale;

		const destinationId: number | undefined = destination ? destination.id : undefined;
		const departureId: number = departure.id;
		const companyId: number = company.id;

		const passangers = this.passangerList.getPassangers().map((e) => {
			return { customerId: e.id, reservationNumber: e.reservationNumber, lifeStage: e.lifeStage };
		});

		// add buyer as default passanger
		passangers.push({ customerId: id, reservationNumber: reservationNumber, lifeStage: PassangerTypes.adults });
		this.sale.dueDate = moment(this.sale.dueDate, "DD.MM.YYYY").toISOString();

		if (this.sale.withAvans === 0) {
			this.sale.avans = undefined;
		}

		const newReservation = {
			oneWay,
			departureDate: moment(departureDate, "DD.MM.YYYY hh:mm").toISOString(),
			arrivalDate: oneWay ? null : moment(arrivalDate, "DD.MM.YYYY").toISOString(),
			reservationNumber: reservationNumber,
			reservationDate: moment(reservationDate, "DD.MM.YYYY").toISOString(),
			type,
			customerId: id,
			plan: { bruto, neto, avans, dueDate, customerId: id, notes: this.note },
			planId: this.sale.id,
			passangers,
			departureId,
			destinationId,
			companyId,
		};

		return newReservation;
	}
}
