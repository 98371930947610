import { TranslateService } from '@ngx-translate/core';
import { DailyDebtStatistics } from './../../../domain/statisticsmoney/models/dailydebt';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-daily-debt-chart',
  templateUrl: './daily-debt-chart.component.html'
})
export class DailyDebtChartComponent implements OnInit, OnChanges {
	@Input() dailydebtstatistics: DailyDebtStatistics | undefined;
  simpleColumnStackedSeries: any;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.updateChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dailydebtstatistics'] && changes['dailydebtstatistics'].currentValue) {
      this.updateChart();
			
    }
  }
	getDayName(dayNumber: number): string {
    return `Day ${dayNumber}`;
  }

  updateChart() {
    if (this.dailydebtstatistics && this.dailydebtstatistics.dailyServiceDebtStats) {
      const sortedStats = this.dailydebtstatistics.dailyServiceDebtStats.sort((a, b) => {
        return a.day - b.day;
      });

      const days = sortedStats.map(item => this.getDayName(item.day));
      const ticketDebt = sortedStats.map(item => item.ticketDebt);
      const travelDebt = sortedStats.map(item => item.travelDebt);
      const groupTravelDebt = sortedStats.map(item => item.groupTravelDebt);
      const transferDebt= sortedStats.map(item => item.transferDebt);

      const isDark = true;
      const isRtl = false; 

      this.simpleColumnStackedSeries = {
        series: [
          { name: this.translate.instant('debt.ticket_debt'), data: ticketDebt },
          { name: this.translate.instant('debt.travel_debt'), data: travelDebt },
          { name: this.translate.instant('debt.group_travel_debt'), data: groupTravelDebt },
          { name: this.translate.instant('debt.transfer_debt'), data: transferDebt }
        ],
        chart: {
          height: 350,
          type: "bar",
          zoom: { enabled: false },
          toolbar: { show: false }
        },
        dataLabels: { enabled: false },
        stroke: { show: true, width: 2, colors: ["transparent"] },
        colors: ["#2196f3", "#3b3f5c", "#ff9800", "#4caf50"],
        xaxis: {
          categories: days,
          axisBorder: { color: isDark ? "#191e3a" : "#e0e6ed" }
        },
        yaxis: {
          opposite: isRtl,
          labels: { offsetX: isRtl ? -10 : 0 }
        },
        grid: { borderColor: isDark ? "#ADD8E6" : "silver" },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded"
          }
        },
        tooltip: {
          theme: isDark ? "dark" : "light",
          y: { formatter: (val: any) => val }
        }
      };
    }
  }
}


