import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
	selector: 'app-file-upload',
	templateUrl: './file-upload.component.html',
	styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
	@Input() id!: number;
	treeview2: string[] = [];
	@ViewChild('fileInput', { static: false }) fileInput!: ElementRef;
	isPopupVisible = false;

	constructor() { }

	ngOnInit() {
	}

	toggleTreeview2(name: string) {
		if (this.treeview2.includes(name)) {
			this.treeview2 = this.treeview2.filter((d: string) => d !== name);
		} else {
			this.treeview2.push(name);
		}
	}

	toggleFileUploadPopup() {
		this.isPopupVisible = !this.isPopupVisible;
	}

	triggerFileInput() {
		this.fileInput.nativeElement.click();
	}

	onFileSelected(event: Event) {
		const input = event.target as HTMLInputElement;
		if (input.files && input.files.length > 0) {
			const file = input.files[0];
			console.log('Selected file:', file.name);
			// Handle the file upload logic here
		}
	}
}