import { Sale } from "../models/sale";
import { Payment } from "../models/payment";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { SalesService } from "../services/sales.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { PaymentService } from "../services/payment.service";
import { PaymentDocument } from "../models/payment-document";
import { BehaviorSubject, combineLatest, map, Observable } from "rxjs";
import { animate, style, transition, trigger } from "@angular/animations";
import { PaymentFormComponent } from "../payment.form/payment.form.component";
import { DocumentService } from "src/app/shared/services/documents.service";
import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";

@Component({
  selector: "app-sale-details",
  templateUrl: "./sale.details.component.html",
  animations: [
    trigger("toggleAnimation", [
      transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
      transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
    ]),
  ],
})
export class SaleDetailsComponent {
  @Input() hideStatus = false;
  public sale!: Sale;
  public payments!: Payment[];
  public totalDepth$!: Observable<number>;
  public editPayment!: Payment | undefined;

  messageRS = `Molim Vas za potrvdu. Racun je u dodatku emaila. `;
  messageEN = `Please confirm. Payment is in the email attachment. `;

  showDocumentPanel?: boolean = false;
  public paymentDocument?: PaymentDocument;
  form!: FormGroup;
  emailString?: string = "";
  invalidEmailMessage: string | null = null;
  additionalEmail?: string = "";
  private _id!: number;
  @ViewChild("modal") modal!: ModalComponent;
  @Output() notifyWhenEditAndSend: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild("appPaymentForm") appPaymentForm!: PaymentFormComponent;

  @Input() set id(value: number) {
    this._id = value;
    this.fetchSaleData();
  }
  get id(): number {
    return this._id;
  }
  private saleSubject = new BehaviorSubject<Sale | undefined>(undefined);
  sale$: Observable<Sale | undefined> = this.saleSubject.asObservable();

  constructor(public saleService: SalesService, public translate: TranslateService, private paymentService: PaymentService, private fb: FormBuilder) {
    this.totalDepth$ = combineLatest([this.sale$]).pipe(
      map(([sale]) => {
        if (!sale) return 0;
        const avans = sale.avans || 0;
        const totalPayments = sale.payments.reduce((acc: number, payment: Payment) => acc + (payment.payment || 0), 0);
        return sale.bruto - avans - totalPayments;
      })
    );
    this.form = this.fb.group({
      emails: this.fb.array([]),
    });
  }

  fetchSaleData() {
    this.saleService.getSaleDetails(this.id).subscribe((response) => {
      this.sale = response;
      this.payments = this.sale.payments.map((p) => {
        return { ...p, guid: crypto.randomUUID() };
      });
      this.saleSubject.next(this.sale);
    });
  }

  toEditPayment(item: Payment) {
    this.editPayment = undefined;
    setTimeout(() => {
      this.editPayment = item;
    });
  }

  submitPayment() {
    const newPayment = this.appPaymentForm.getPayment();
    this.paymentService.updatePayment(newPayment).subscribe((response) => {
      this.editPayment = undefined;
      this.fetchSaleData();
    });
  }

  discard() {
    this.editPayment = undefined;
  }

  submitNewPayment() {
    const newPayment = this.appPaymentForm.getPayment();
    this.paymentService.savePayment({ ...newPayment, planId: this.id }).subscribe((response) => {
      this.editPayment = undefined;
      this.fetchSaleData();
    });
  }

  setNewPayment() {
    this.editPayment = {} as Payment;
  }

  removePayment(item: any) {
    this.paymentService.deletePayment(item.id).subscribe((res) => {
      this.editPayment = undefined;
      this.fetchSaleData();
    });
  }
  showDocument(item: any) {
    if (this.paymentDocument?.id === item.id) {
      this.showDocumentPanel = !this.showDocumentPanel;
    } else {
      this.paymentService.getPaymentDocument(item.id).subscribe((response) => {
        this.paymentDocument = response;
        this.showDocumentPanel = true;
      });
    }
  }
  printPayment() {
    DocumentService.printReciept("payment");
  }

  sendPayment() {
    this.modal.open();
    this.notifyWhenEditAndSend.emit();
  }
  onEditPaymentChange(editPayment: Payment | undefined) {
    this.editPayment = editPayment;
  }

  finishSending() {
    this.modal.close();
  }
}
