<div hlMenu class="dropdown">
  <button hlMenuButton type="button" class="flex items-center rounded-md border border-[#e0e6ed] px-4 py-2 text-sm font-semibold dark:border-[#253b5c] dark:bg-[#1b2e4b] dark:text-white-dark">
    <span class="ltr:mr-1 rtl:ml-1">{{ "actions" | translate }}</span>
    <icon-caret-down class="h-5 w-5" />
  </button>
  <ul *hlMenuItems @toggleAnimation class="w-max rounded-md bg-white shadow-lg dark:bg-[#1b2e4b] ltr:left-0 rtl:right-0">
    <li class="border-t border-gray-200 px-4 py-2 dark:border-gray-700">
      <div class="flex flex-col space-y-2">
        <app-export-to-excel-btn [jsonData]="jsonData" [fileName]="fullFilename" *ngIf="jsonData !== undefined" (destroyCallback)="destroyJson($event)"></app-export-to-excel-btn>
        <button type="button" class="btn btn-primary btn-sm flex w-full items-center justify-center" (click)="exportTableNew('excel')">
          <icon-code class="ltr:mr-2 rtl:ml-2" />
          EXCEL
        </button>
        <button type="button" class="btn btn-primary btn-sm flex w-full items-center justify-center" (click)="exportTableNew('print')">
          <icon-printer class="ltr:mr-2 rtl:ml-2" />
          PRINT
        </button>
      </div>
    </li>
  </ul>
</div>
