import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { map, catchError, switchMap } from "rxjs/operators";
import { AuthService } from "../auth/service/auth.service";
import { error } from "console";

@Injectable({
  providedIn: "root",
})
export class HasIdGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const workerId = route.paramMap.get("id");
    const userId = this.authService.getUserId();

    return this.authService.getUserRole().pipe(
      map((role) => {
        if (route.data["role"].includes(role) || userId.toString() === workerId) {
          return true;
        } else {
          this.router.navigate(["/404"]);
          return false;
        }
      }),
      catchError((error) => {
        console.error("Error fetching user role", error);
        this.router.navigate(["/auth/login"]);
        return of(false);
      })
    );
  }
}
