import { Transfer } from "../models/transfer";
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Customer } from "../../customers/models/customer";
import { TransfersService } from "../services/transfers.service.service";
import { FileUploadComponent } from "src/app/shared/components/file-upload/file-upload.component";

@Component({
	selector: "app-transfers.details",
	templateUrl: "./transfers.details.component.html",
})
export class TransfersDetailsComponent implements OnInit {
	isShowingSidebar = false;
	protected id!: number;
	protected transfer!: Transfer;
	protected passangers: Customer[] = [];
	protected transferPlanIds: number[] = [];
	constructor(private transferService: TransfersService, private route: ActivatedRoute, public translate: TranslateService) { }
	ngOnInit() {
		this.route.params.subscribe((params) => {
			this.id = parseInt(params["id"]);
			if (this.id > 0) {
				this.fetchTransferData();
				//this.fetchPassangers();
			}
		});
	}


	fetchTransferData() {
		this.transferService.getTransferData(this.id).subscribe((response) => {
			this.transfer = response;
			console.log(this.transfer);
			this.transferPlanIds = this.transfer.transferPlans.map(plan => plan.id);

		});
	}

}
