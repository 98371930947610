import { Customer } from './../../customers/models/customer';
import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Transfer, TransferPlan } from '../../transfers/models/transfer';



@Component({
	selector: 'app-transfer-quick-view',
	templateUrl: './transfer-quick-view.component.html',
	styleUrls: ['./transfer-quick-view.component.css']
})
export class TransferQuickViewComponent {
	@Input() data!: Transfer;

	constructor(public translate: TranslateService) { }
	getReservationNumber(pass: Customer[]) {
		if (pass) {
			const arr = pass.map((passenger) => passenger.reservationNumber);
			return arr.join(", ");
		}
		return "";

	}
	calculateTotalPassangers(transferPlans: TransferPlan[]) {
		let count = 0;
		transferPlans.forEach((transferPlan) => {
			count += transferPlan.transferPassangers.length;
		});
		return count;
	}
}
