import { Component, Input, OnInit } from "@angular/core";
import { TransferData } from "../models/transfer";
import { TransfersService } from "../services/transfers.service.service";
import { Customer, PassangerTypes } from "../../customers/models/customer";
import { TranslateService } from "@ngx-translate/core";
import { DatatableColumn } from "src/app/shared/components/base-table/base-table.component";

@Component({
  selector: "app-passengers-table",
  templateUrl: "./passengers-table.component.html",
})
export class PassengersTableComponent implements OnInit {
  @Input() transferId: number | undefined;
  public exportApiUrl!: string;
  transferData!: TransferData[];
  currentPage = 1;
  pageSize = 2;
  paginatedData: any[] = [];
  rows: Customer[] = [];
  jsonData = this.rows;

  ngOnInit(): void {
    this.getTransferPassangers();
  }

  constructor(private transferService: TransfersService, private translate: TranslateService) {}

  getTransferPassangers() {
    if (this.transferId !== undefined) {
      this.transferService.getTransferPassangers(this.transferId).subscribe((response) => {
        if (response && Array.isArray(response)) {
          this.transferData = response;
          this.transferData.forEach((element) => {
            if (element.transferPassangers && Array.isArray(element.transferPassangers)) {
              element.transferPassangers.forEach((passanger) => {
                passanger.customer.groupNumber = element.id;
                passanger.customer.passangerState = passanger.passangerState;
                passanger.customer.lifeStage = passanger.passangerState as PassangerTypes;
                this.rows.push(passanger.customer);
              });
            }
          });

          this.exportApiUrl = `api/Transfers/transfer/${this.transferId}`;
          this.fetchTravelData();
        } else {
          console.error("error", response);
        }
      });
    }
  }

  cols = [
    { field: "id", title: this.translate.instant("transfer_table.id") } as DatatableColumn,
    { field: "name", title: this.translate.instant("transfer_table.firstname") } as DatatableColumn,
    { field: "surname", title: this.translate.instant("transfer_table.lastname") } as DatatableColumn,
    { field: "dateOfBirth", title: this.translate.instant("transfer_table.date_of_birth") } as DatatableColumn,
    { field: "address", title: this.translate.instant("transfer_table.address") } as DatatableColumn,
    { field: "phoneNumber", title: this.translate.instant("transfer_table.phone_number") } as DatatableColumn,
    { field: "lifeStage", title: this.translate.instant("transfer_table.ilife_stage") } as DatatableColumn,
    { field: "passangerState", title: this.translate.instant("transfer_table.passanger_state") } as DatatableColumn,
    { field: "totalPrice", title: this.translate.instant("transfer_table.total_price") } as DatatableColumn,
    { field: "groupNumber", title: this.translate.instant("transfer_table.group_number") } as DatatableColumn,
  ];
  public exportCols = [
    { field: "id", title: this.translate.instant("passanger_export.id") } as DatatableColumn,
    { field: "customerNameAndSurname", title: this.translate.instant("passanger_export.customerNameAndSurname") } as DatatableColumn,
    { field: "customerPhoneNumber", title: this.translate.instant("passanger_export.customerPhoneNumber") } as DatatableColumn,
    { field: "departure", title: this.translate.instant("passanger_export.departure") } as DatatableColumn,
    { field: "destination", title: this.translate.instant("passanger_export.destination") } as DatatableColumn,
    { field: "status", title: this.translate.instant("passanger_export.status") } as DatatableColumn,
    { field: "saleDate", title: this.translate.instant("passanger_export.saleDate") } as DatatableColumn,
    { field: "user", title: this.translate.instant("passanger_export.user") } as DatatableColumn,
  ];
  fetchTravelData() {
    this.updatePaginatedData();
  }

  updatePaginatedData(): void {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = Math.min(startIndex + this.pageSize, this.transferData.length);
    this.paginatedData = this.transferData.slice(startIndex, endIndex);
  }

  goToPage(page: number): void {
    this.currentPage = page;
    this.updatePaginatedData();
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages()) {
      this.currentPage++;
      this.updatePaginatedData();
    }
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updatePaginatedData();
    }
  }

  totalPages(): number {
    return this.transferData && this.transferData.length > 0 ? Math.ceil(this.transferData.length / this.pageSize) : 0;
  }

  openVoucher() {
    return Math.ceil(this.transferData.length / this.pageSize);
  }
}
