<div class="space-y-8">
  <div class="panel pb-0">
    <div class="mb-5 flex flex-col gap-5 md:flex-row md:items-center">
      <h5 class="text-lg font-semibold dark:text-white-light"></h5>
      <div class="flex items-center gap-5 ltr:ml-auto rtl:mr-auto">
        <div hlMenu class="dropdown">
          <button hlMenuButton type="button" class="flex items-center rounded-md border border-[#e0e6ed] px-4 py-2 text-sm font-semibold dark:border-[#253b5c] dark:bg-[#1b2e4b] dark:text-white-dark">
            <span class="ltr:mr-1 rtl:ml-1">{{ "table_columns" | translate }}</span>
            <icon-caret-down class="h-5 w-5" />
          </button>
          <ul *hlMenuItems @toggleAnimation class="w-max ltr:left-0 rtl:right-0">
            <ng-container *ngFor="let col of cols; index as i">
              <li>
                <div class="flex items-center px-4 py-1">
                  <label [for]="'chk-' + i" class="mb-0 cursor-pointer">
                    <input type="checkbox" [id]="'chk-' + i" class="form-checkbox" [(ngModel)]="!col.hide" (change)="updateColumn(col)" />
                    <span class="ltr:ml-2 rtl:mr-2">{{ col.title }}</span>
                  </label>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
        <div>
          <input [(ngModel)]="search" type="text" class="form-input" placeholder="Search..." />
        </div>
      </div>
    </div>

    <div class="datatable">
      <ng-datatable
        [rows]="items"
        [columns]="cols"
        [sortable]="true"
        [search]="search"
        [totalRows]="totalRows"
        [pageSize]="pageSize"
        skin="whitespace-nowrap table-hover"
        [noDataContent]="'empty_tables' | translate"
      >
        <ng-template slot="departureDate" let-value="data"> {{ value.start | date : "dd.MM.yyyy" }} </ng-template>
        <ng-template slot="destinationsCustom" let-value="data">
          <span>{{ getDestinations(value.destinations) }}</span>
        </ng-template>
      </ng-datatable>
    </div>
  </div>
</div>
