<div>
  <div class="panel">
    <div class="mb-5">
      <div class="mb-4 flex flex-col items-center justify-center sm:flex-row sm:justify-between">
        <div class="mb-4 sm:mb-0">
          <div class="text-center text-lg font-semibold ltr:sm:text-left rtl:sm:text-right">Calendar</div>
          <div class="mt-2 flex flex-wrap items-center justify-center sm:justify-start">
            <div class="flex items-center ltr:mr-4 rtl:ml-4">
              <div class="h-2.5 w-2.5 rounded-sm bg-primary ltr:mr-2 rtl:ml-2"></div>
              <div>{{ "travel-label" | translate }}</div>
            </div>
            <div class="flex items-center ltr:mr-4 rtl:ml-4">
              <div class="h-2.5 w-2.5 rounded-sm bg-info ltr:mr-2 rtl:ml-2"></div>
              <div>{{ "ticket-label" | translate }}</div>
            </div>
            <div class="flex items-center ltr:mr-4 rtl:ml-4">
              <div class="h-2.5 w-2.5 rounded-sm bg-success ltr:mr-2 rtl:ml-2"></div>
              <div>{{ "grouptravel-label" | translate }}</div>
            </div>
            <div class="flex items-center">
              <div class="h-2.5 w-2.5 rounded-sm bg-danger ltr:mr-2 rtl:ml-2"></div>
              <div>{{ "transfer-label" | translate }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="calendar-wrapper">
        <full-calendar #calendar [options]="calendarOptions" [deepChangeDetection]="true">
          <ng-template #eventContent let-arg>
            <div class="fc-event-main-frame flex items-center px-1 py-0.5 text-white">
              <div class="fc-event-time px-0.5 font-semibold">{{ arg.timeText }}</div>
              <div class="fc-event-title-container flex items-center">
                <ng-container *ngIf="arg.event.extendedProps.eventType === 'start'">
                  <icon-top-right></icon-top-right>
                </ng-container>
                <ng-container *ngIf="arg.event.extendedProps.eventType === 'end'">
                  <icon-bottom-right></icon-bottom-right>
                </ng-container>
                <ng-container *ngIf="arg.event.extendedProps.type.toLowerCase() === 'travel'">
                  <icon-suitcase></icon-suitcase>
                </ng-container>
                <ng-container *ngIf="arg.event.extendedProps.type.toLowerCase() === 'ticket'">
                  <icon-airplane></icon-airplane>
                </ng-container>
                <ng-container *ngIf="arg.event.extendedProps.type.toLowerCase() === 'grouptravel'">
                  <icon-group-travel></icon-group-travel>
                </ng-container>
                <ng-container *ngIf="arg.event.extendedProps.type.toLowerCase() === 'transfer'">
                  <icon-transfer></icon-transfer>
                </ng-container>

                <div class="fc-event-title fc-sticky px-0.5 !font-medium">{{ arg.event.title }}</div>
              </div>
            </div>
          </ng-template>
        </full-calendar>
      </div>
    </div>
  </div>
  <modal #isAddEventModal class="no-footer">
    <ng-template #modalHeader>
      <div class="!font-medium">{{ selectedEvent ? 'Event Details' : 'No Event Selected' }}</div>
    </ng-template>
    <ng-template #modalBody>
      <div *ngIf="selectedEvent" class="grid grid-cols-2 gap-5 gap-x-2 sm:gap-x-5">
        <div class="mb-5">
          <label>{{ "eventdetails.eventtype" | translate }}:</label>
          <div class="text-primary">{{ selectedEvent.extendedProps.entity_name }}</div>
        </div>
        <div class="mb-5">
          <label>{{ "eventdetails.eventtitle" | translate }}:</label>
          <div class="text-primary">{{ selectedEvent.title }}</div>
        </div>
        <div class="mb-5">
          <label>{{ "eventdetails.eventstart" | translate }}:</label>
          <div class="text-primary">{{ selectedEvent.extendedProps.event_starts | date : "dd.MM.yyyy" }}</div>
        </div>
        <div class="mb-5">
          <label>{{ "eventdetails.eventend" | translate }}:</label>
          <div class="text-primary">{{ selectedEvent.extendedProps.event_ends | date : "dd.MM.yyyy" }}</div>
        </div>
        <div class="mb-5">
          <label>{{ "eventdetails.departure" | translate }}:</label>
          <div class="text-primary">{{ selectedEvent.extendedProps.departure.description }}</div>
        </div>
        <div class="mb-5">
          <label>{{ "eventdetails.destination" | translate }}:</label>
          <div class="text-primary">{{ selectedEvent.extendedProps.destination.description }}</div>
        </div>

        <div class="mb-5">
          <label>{{ "eventdetails.organizername" | translate }}:</label>
          <div class="text-primary">{{ selectedEvent.extendedProps.organizer.name }}</div>
        </div>
        <div class="mb-5">
          <label>{{ "eventdetails.organizerinfo" | translate }}:</label>
          <div class="text-primary">{{ selectedEvent.extendedProps.organizer.firstName }}{{ selectedEvent.extendedProps.organizer.lastName }}</div>
          <div class="text-primary">{{ selectedEvent.extendedProps.organizer.phone }}</div>
        </div>
        <div class="mb-5">
          <label>{{ "eventdetails.email" | translate }}:</label>
          <div class="text-primary">{{ selectedEvent.extendedProps.organizer.email }}</div>
        </div>
        <div class="mb-5">
          <label>{{ "eventdetails.hotel" | translate }}:</label>
          <div class="text-primary">{{ selectedEvent.extendedProps.hotel.name }}</div>
        </div>
      </div>
      <div *ngIf="!selectedEvent">
        <div class="text-danger">{{ "eventdetails.noeventselected" | translate }}</div>
      </div>
      <div class="mt-8 flex items-center justify-end gap-2">
        <button type="button" class="btn btn-info gap-2" (click)="navigateToDetails(selectedEvent.extendedProps.entityId,selectedEvent.extendedProps.entity_name)">
          {{ "eventdetails.details" | translate }}
        </button>
        <button type="button" class="btn btn-outline-danger" (click)="isAddEventModal.close()">Close</button>
      </div>
    </ng-template>
  </modal>
</div>
