<div *ngIf="isShowingSidebar">
  <nav
    class="fixed bottom-0 top-0 z-[51] w-full max-w-[600px] bg-white p-4 shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] transition-all duration-700 ease-in-out dark:bg-[#0e1726]"
    [ngClass]="{ 'ltr:right-0 rtl:left-0': isShowingSidebar, 'ltr:-right-[400px] rtl:-left-[400px]': !isShowingSidebar }"
  >
    <a
      href="javascript:;"
      class="absolute bottom-0 top-0 my-auto flex h-10 w-12 cursor-pointer items-center justify-center bg-primary text-white ltr:-left-12 ltr:rounded-bl-full ltr:rounded-tl-full rtl:-right-12 rtl:rounded-br-full rtl:rounded-tr-full"
      (click)="isShowingSidebar = !isShowingSidebar"
    >
      <icon-settings class="h-5 w-5 animate-[spin_3s_linear_infinite]" />
    </a>
    <ng-scrollbar class="relative h-full overflow-x-hidden ltr:-mr-3 ltr:pr-3 rtl:-ml-3 rtl:pl-3">
      <div>
        <div class="relative pb-5 text-center">
          <a href="javascript:;" class="absolute top-0 opacity-30 hover:opacity-100 dark:text-white ltr:right-0 rtl:left-0" (click)="isShowingSidebar = false">
            <icon-x class="h-5 w-5" />
          </a>
          <h4 class="mb-1 text-2xl font-semibold text-gray-800 dark:text-white">{{ "change_history.title" | translate }}</h4>
          <p class="text-gray-600 dark:text-gray-400">{{ "change_history.description" | translate }}</p>
        </div>

        <div class="max-w-[900px] mx-auto">
          <div class="flex" *ngFor="let entry of auditEntries">
            <div class="flex mb-4">
              <p class="text-[#3b3f5c] dark:text-white-light min-w-[58px] max-w-[100px] text-base font-semibold py-2.5 mr-2">{{entry.dateTime | date:'dd-MM-yyyy'}}</p>
              <div
                class="
                  relative
                  before:absolute before:left-1/2 before:-translate-x-1/2 before:top-[15px] before:w-2.5 before:h-2.5 before:border-2 before:border-primary before:rounded-full
                  after:absolute after:left-1/2 after:-translate-x-1/2 after:top-[25px] after:-bottom-[30px] after:w-0 after:h-auto after:border-l-2 after:border-primary after:rounded-full
                "
              ></div>
              <div class="p-2.5 self-center ltr:ml-2.5 rtl:mr-2.5">
                <p class="text-gray-800 dark:text-white font-semibold text-[13px]">{{entry.changeType}}</p>
                <p class="text-gray-600 dark:text-gray-300 text-xs font-bold self-center min-w-[100px]">{{entry.tableName}}</p>
              </div>

							<div class="p-2.5 self-center ltr:ml-2.5 rtl:mr-2.5">
								<div *ngIf="parseModifiedColumns(entry).length > 0">
									<div *ngFor="let column of parseModifiedColumns(entry)" class="mb-2">
										<div class="flex items-center">
											<p class="text-gray-800 dark:text-white font-semibold text-[13px] flex-1">
												<strong class="mr-1">{{ column.PropertyName }}:</strong> 
												<span class="text-red-500">{{ column.OldValue }}</span> 
												<span class="text-black-500">&rarr;</span> 
												<span class="text-blue-500">{{ column.NewValue }}</span>
											</p>
										</div>
									</div>
								</div>
								<div *ngIf="parseModifiedColumns(entry).length === 0">
									<p class="text-gray-600 dark:text-gray-300 text-xs italic">{{ "change_history.no_changes" | translate }}</p>
								</div>
							</div>
							
            </div>
          </div>
        </div>
      </div>
    </ng-scrollbar>
  </nav>
</div>
