// file-history.component.ts
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuditEntry } from './models/auditentry';
import { TravelsService } from 'src/app/domain/travels/services/travels.service';

@Component({
	selector: 'app-file-history',
	templateUrl: './file-history.component.html',
	styleUrls: ['./file-history.component.css']
})
export class FileHistoryComponent implements OnInit {
  @Input() isShowingSidebar = false;
	@Input() entityname: any = '';
  @Input() id!: number;
  showFileHistory = false;
  showCustomizer = false;

  travel!: string;
  //id!: any;
	auditEntries: AuditEntry[] = [];

  constructor(private route: ActivatedRoute,private travelservice:TravelsService) {}

  ngOnInit(): void {
		this.fetchAuditLogs();
		
  }
  fetchAuditLogs() {
    this.travelservice.getAuditLogs(this.entityname, this.id).subscribe(
      data => {
        this.auditEntries = data;
      }
    );
  }
  toggleFileHistory() {
    this.showFileHistory = !this.showFileHistory;
  }
	parseModifiedColumns(entry: AuditEntry): any[] {
    try {
      if (!entry.modifiedColumns) {
        return [];
      }
      const sanitizedString = entry.modifiedColumns.trim();
      if (sanitizedString === '') {
        return [];
      }
      return JSON.parse(sanitizedString);
    } catch (e) {
      return [];
    }
  }
	
}
