import * as moment from "moment";
import { Transfer } from "../models/transfer";
import { Sale } from "../../sales/models/sale";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Customer } from "../../customers/models/customer";
import { PopupType } from "src/app/shared/enums/popup-types";
import { Component, OnInit, ViewChild } from "@angular/core";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { TransfersService } from "../services/transfers.service.service";

@Component({
  selector: "app-transfer-passanger",
  templateUrl: "./transfer-passanger.component.html",
})
export class TransferPassangerComponent implements OnInit {
  @ViewChild("passangerList") passangerList: any;
  @ViewChild("mainCustomer") mainCustomer: any;
  @ViewChild("sale") sales: any;
  activeTab = 1;
  customer!: Customer;
  passenagers!: any[];
  sale!: Sale;
  transferId!: number | undefined;
  transfer!: Transfer;
  isForwardDirection!: boolean;

  constructor(private translate: TranslateService, private route: ActivatedRoute, private transferService: TransfersService) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const id = params.get("id");
      this.transferId = id ? parseInt(id) : undefined;
      this.fetchTransfer(this.transferId);
    });
	
  }
  proceedToNextStage(nextStep: number | undefined = undefined) {
    const isValid = this.mainCustomer.checkIfValid();
    if (!isValid) {
      showMessage(PopupType.Danger, this.translate.instant("transfer.fill_all_fields"));
      return;
    }
    this.activeTab = nextStep ?? this.activeTab + 1;
  }

  isVisibleTab(index: number) {
    return { display: this.activeTab === index ? "block" : "none" };
  }

  submitTransfer() {
    this.customer = this.mainCustomer.getCustomer();
    this.passenagers = this.passangerList.getPassangers();
    this.sale = this.sales.getSale();
    this.passangerList.checkValidity();
    this.addPassangersToTransfer();
  }

  fetchTransfer(id: number | undefined) {
    if (id) {
      this.transferService.getTransferData(id).subscribe((res: any) => {
        this.transfer = res;
      });
    }
  }

  toggleDirection() {
    this.isForwardDirection = !this.isForwardDirection;
  }

  addPassangersToTransfer() {
    this.sale.dueDate = moment(this.sale.dueDate, "DD.MM.YYYY").format("YYYY-MM-DDTHH:mm:ss");
    this.sale.customerId = this.customer.id;
    const data = {
      transferId: this.transferId,
      plan: this.sale,
      passangers: this.getPassangerIds(),
    };

    this.transferService.addPassangerToTransfer(this.transferId, data).subscribe(
      (res) => {
        showMessage(PopupType.Success, this.translate.instant("transfer.passangers_added_successfully"));
        showMessage(PopupType.Success, this.translate.instant("transfer.added_passangers_successfully"));
      },
      (err) => {
        showMessage(PopupType.Danger, this.translate.instant("transfer.error_while_adding_passangers"));
      }
    );
  }

  getPassangerIds(): any[] {
		const passangerIds = this.passenagers.map((p: any) => {return {customerId: p.id, lifeStage: p.lifeStage}});
		passangerIds.push({customerId: this.customer.id, lifeStage: "adults"});
    return passangerIds;
  }

  addDirection(id: number) {
    if (id === 1) this.transferId = this.transfer.returnTransfer?.id;
    else this.transferId = this.transfer.id;
  }
}
