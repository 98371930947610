import * as moment from "moment";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Sale } from "src/app/domain/sales/models/sale";
import { StyleUtil } from "src/app/shared/utils/style.util";
import { PopupType } from "src/app/shared/enums/popup-types";
import { Component, OnInit, ViewChild } from "@angular/core";
import { TravelDraft } from "src/app/domain/travels/models/travel";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { CheckoutService } from "../../services/checkout.service";
import { Customer, PassangerTypes } from "src/app/domain/customers/models/customer";
import { TravelDestination } from "src/app/domain/travels/models/travel-destination";
import { AnnouncementService } from "src/app/domain/travels/services/announcement.service";
import { TravelCreateComponent } from "src/app/domain/travels/travel.create/travel.create.component";
import { PassangersListComponent } from "src/app/domain/passangers/passangers.list/passangers.list.component";
import { TravelDestinationCreateComponent } from "src/app/domain/travels/travel-destination.create/travel-destination.create.component";

@Component({
  selector: "app-travel-checkout-create",
  templateUrl: "./travel-checkout.create.component.html",
})
export class TravelCheckoutCreateComponent implements OnInit {
  @ViewChild("mainDestination") mainDestination!: TravelDestinationCreateComponent;
  @ViewChild("passangerList") passangerList!: PassangersListComponent;
  @ViewChild("travelBase") travelComponent!: TravelCreateComponent;

  public activeTab = 1;

  public id!: number;
  public sale!: Sale;
  public travel!: TravelDraft;
  public customer!: Customer;
  public travelDestinations: TravelDestination[] = [];
  public editDestination!: TravelDestination | undefined;
  public showTravelForm = false;
  public showMainDestination = true;

  note!: string;

  public tabSizeHandler = StyleUtil.getTabWidth;

  constructor(private router: Router, public checkoutService: CheckoutService, public translate: TranslateService, private announcementService: AnnouncementService) {}

  ngOnInit() {
    setTimeout(() => {
      this.passangerList.setDefaultMainCustomer();
    });
  }

  proceedToNextStage(nextStep: number) {
    if (nextStep == 2) {
      // not sure why is there will try to figure out
      // this.customer = this.passangerList.getPassangers().find((e) => e.lifeStage === PassangerTypes.mainCustomer) as Customer;
      if (this.customer.name === undefined || this.customer.surname === undefined || this.passangerList.checkValidity() == false) {
        showMessage(PopupType.Danger, "Main customer is required or please save the passanger");
        return;
      }
      this.showTravelForm = true;
    }

    this.activeTab = nextStep;
  }

  createDraftTravel() {
    if (this.editDestination != null) {
      showMessage(PopupType.Danger, "Please save destination before proceeding");
      return;
    }

    if (this.travelDestinations.length == 0 || !this.travelComponent.checkIfValid()) {
      showMessage(PopupType.Danger, "Please add a travel and add at least main destination");
      return;
    }

    if (!this.passangerList.checkValidity()) {
      showMessage(PopupType.Danger, "Passanger Validation failed");
      return;
    }

    this.saveAsDraft();
  }

  isVisibleTab(index: number) {
    return { display: this.activeTab === index ? "block" : "none" };
  }

  createReservationObject(): any {
    const { start, end, type, days, nights, organizer, departure, avans } = this.travel;

    const { id } = this.customer;

    // Extracting necessary IDs
    const organizerId: number = organizer?.id;
    const departureId: number | undefined = departure ? departure?.id : undefined;

    let passangers = this.passangerList.getPassangers().map((e) => {
      return { customerId: e.id, lifeStage: e.lifeStage };
    });

    const mainCustomer = this.passangerList.getPassangers().find((e) => e.lifeStage === PassangerTypes.mainCustomer);
    if (mainCustomer?.mainCustomer == false) passangers = passangers.filter((e) => e.customerId !== mainCustomer?.id);

    const newReservation = {
      start: moment(start, "DD.MM.YYYY").toISOString(),
      end: moment(end, "DD.MM.YYYY").toISOString(),
      days,
      nights,
      typeId: type?.id,
      customerId: id,
      numberOfPassangers: passangers.length,
      passangers,
      departureId,
      organizerId,
      plan: {
        avans: avans,
        dueDate: moment().endOf("year"),
        bruto: 1,
        neto: 1,
      },
      travelDestinations: this.travelDestinations.map((p) => {
        const { travelId, destination, hotel, start: travelStart, end: travelEnd, service, days, nights, roomDescription } = p;
        return {
          start: moment(travelStart, "DD.MM.YYYY").toISOString(),
          end: moment(travelEnd, "DD.MM.YYYY").toISOString(),
          days,
          nights,
          roomDescription,
          serviceId: service?.id,
          destinationId: destination?.id,
          hotelId: hotel?.id,
          travelId: travelId,
        };
      }),
    };

    return newReservation;
  }

  saveAsDraft() {
    this.travel = this.travelComponent.getTravel() as TravelDraft;

    const travelDraft = this.createReservationObject();

    this.checkoutService.createTravelDraft({ ...travelDraft, ...travelDraft.plan }).subscribe((r: any) => {
      this.id = r.id;
      showMessage(PopupType.Success, this.translate.instant("ticket_edited_successfully"));
      this.router.navigate([`dashboard/travels/draft/edit/${this.id}`], {
        queryParams: {
          activeTab: 3,
        },
      });
    });
  }

  returnBack() {
    this.activeTab = this.activeTab > 0 ? --this.activeTab : 1;
  }

  // Travel Utils

  onNotify(data: any) {
    this.mainDestination.setStartAndEnd(data);
  }

  // Travel Destination Utils

  addDestination() {
    this.travelDestinations.push({
      guid: crypto.randomUUID(),
      id: undefined,
      start: undefined,
      end: undefined,
      days: undefined,
      nights: undefined,
      notes: undefined,
      service: undefined,
      destination: undefined,
      hotel: undefined,
      travelId: undefined,
      serviceId: undefined,
      destinationId: undefined,
      hotelId: undefined,
      createdAt: undefined,
      updatedAt: undefined,
      roomDescription: undefined,
      voucher: undefined,
      mainDestination: false,
    });
  }

  removeDestination(id: string | undefined) {
    this.editDestination = undefined;
    this.travelDestinations = this.travelDestinations.filter((e) => e.guid != id);
    showMessage(PopupType.Success, "Destination removed successfully");
  }

  handleDestinationForMain(destination: TravelDestination) {
    destination.mainDestination = true;
    this.travelDestinations.push(destination);
    this.showMainDestination = false;

    showMessage(PopupType.Success, "Destination saved successfully");
    this.editDestination = undefined;
  }

  handleDestination(destination: TravelDestination) {
    const updatedDestinations = this.travelDestinations.map((e) => {
      if (e.guid === destination.guid) {
        return { ...e, ...destination };
      }
      return e;
    });

    this.travelDestinations = updatedDestinations;

    showMessage(PopupType.Success, "Destination saved successfully");
    this.editDestination = undefined;
  }

  toEditDestination(destination: TravelDestination) {
    this.editDestination = undefined;
    setTimeout(() => {
      this.editDestination = destination;
    });
  }
}
