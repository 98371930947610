import { Customer } from "src/app/domain/customers/models/customer";
import { OtherServicesService } from "./../../services/other-services.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { UtilService } from "src/app/shared/services/util.service";
import { EntityPicklistType, PicklistType } from "src/app/shared/enums/picklist-types";
import { CheckoutService } from "src/app/checkouts/services/checkout.service";
import { CustomerCreateComponent } from "src/app/domain/customers/customer.create/customer.create.component";
import { SaleCreateComponent } from "src/app/domain/sales/sale.create/sale.create.component";
import { DateTimeFormatter } from "src/app/shared/utils/datetime.formatter";
import { Picklist } from "src/app/domain/settings/models/picklist";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { PopupType } from "src/app/shared/enums/popup-types";
import * as moment from "moment";
import { Sale } from "src/app/domain/sales/models/sale";
import { Plan } from "../../models/otherservices";

@Component({
  selector: "app-other-services.edit",
  templateUrl: "./other-services.edit.component.html",
})
export class OtherServicesEditComponent implements OnInit {
  form!: FormGroup;
  otherServiceId!: any;
  PicklistType = PicklistType;
  EntityPicklistType = EntityPicklistType;
  @ViewChild("mainCustomer") customerComponent!: CustomerCreateComponent;
  @ViewChild("sale") saleComponent!: SaleCreateComponent;
  note!: string;
  public activeTab = 1;
  public customer!: Customer;
  public sale!: Sale;
  public plan!: Plan;
  public someBrutoValue?: any;
  public someNetoValue?: any;
  public someAvansValue?: any;

  constructor(
    private fb: FormBuilder,
    private otherServicesService: OtherServicesService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    public utilService: UtilService,
    public checkoutService: CheckoutService
  ) {
    this.form?.get("twoWay")?.valueChanges.subscribe((value) => {
      this.form?.get("twoWay")?.setValue(!value);
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.otherServiceId = params.get("id");
      if (this.otherServiceId) {
        this.getOtherService(this.otherServiceId);
      } else {
        console.error("No ID found in the route parameters.");
      }
    });
  }

  initForm(service: any) {
    this.form = this.fb.group({
      id: [service?.id],
      amount: [service?.amount || 0, Validators.required],
      note: [service?.note],
      planId: [service?.planId, Validators.required],
      plan: this.fb.group({
        id: [service?.plan?.id],
        exchangeRate: [service?.plan?.exchangeRate],
        neto: [service?.plan?.neto],
        bruto: [service?.plan?.bruto],
        avans: [service?.plan?.avans],
        active: [service?.plan?.active],
        status: [service?.plan?.status],
        dueDate: [service?.plan?.dueDate],
        reservationDate: [service?.plan?.reservationDate],
        notes: [service?.plan?.notes],
        initialPaymentConfirmationDocument: [service?.plan?.initialPaymentConfirmationDocument],
        createdAt: [service?.plan?.createdAt],
        updatedAt: [service?.plan?.updatedAt],
        customerId: [service?.plan?.customerId],
        createdById: [service?.plan?.createdById],
        cashRegisterId: [service?.plan?.cashRegisterId],
        customer: this.fb.group({
          id: [service?.plan?.customer?.id],
          name: [service?.plan?.customer?.name],
          surname: [service?.plan?.customer?.surname],
          email: [service?.plan?.customer?.email],
          dateOfBirth: [service?.plan?.customer?.dateOfBirth],
          phoneNumber: [service?.plan?.customer?.phoneNumber],
          imageUrl: [service?.plan?.customer?.imageUrl],
          address: [service?.plan?.customer?.address],
          passport: [service?.plan?.customer?.passport],
          profession: [service?.plan?.customer?.profession],
          imbg: [service?.plan?.customer?.imbg],
          passportIssuingDate: [service?.plan?.customer?.passportIssuingDate],
          passportExpirationDate: [service?.plan?.customer?.passportExpirationDate],
          createdAt: [service?.plan?.customer?.createdAt],
          updatedAt: [service?.plan?.customer?.updatedAt],
        }),
      }),
      serviceId: [service?.serviceId, Validators.required],
      service: this.fb.group({
        id: [service?.service?.id],
        title: [service?.service?.title],
        description: [service?.service?.description],
        delimeter: [service?.service?.delimeter],
        value: [service?.service?.value],
        createdAt: [service?.service?.createdAt],
        updatedAt: [service?.service?.updatedAt],
      }),
    });
    this.form.patchValue({
      plan: {
        avans: this.plan?.avans,
        neto: this.plan?.neto,
        bruto: this.plan?.bruto,
      },
    });
    this.someBrutoValue = this.plan?.bruto;
    this.someNetoValue = this.plan?.neto;
    this.someAvansValue = this.plan?.avans;
  }

  dateTimeMask = [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, ":", /\d/, /\d/];
  dateMask = [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/];

  fetchEntities() {
    return this.form.getRawValue();
  }

  getOtherService(id: any) {
    this.otherServicesService.getOtherServiceData(id).subscribe(
      (res) => {
        this.customer = res?.plan?.customer;
        this.plan = res?.plan;
        this.initForm(res);
      },
      (error) => {
        showMessage(PopupType.Danger, error.toString());
      }
    );
  }
  get twoWay() {
    return this.form?.get("twoWay")?.value;
  }

  fetchOtherService() {
    const data = this.form.getRawValue();
  }
  async updateOtherServices() {
    const saleData = this.saleComponent.getSale();
    this.form.patchValue({
      plan: {
        bruto: saleData?.bruto,
        neto: saleData?.neto,
        avans: saleData?.avans,
        dueDate: moment(saleData?.dueDate, "DD.MM.YYYY").toISOString(),
      },
    });
    const data = this.form.getRawValue();
    this.customer = await this.customerComponent.getCustomer();
    this.sale = this.saleComponent.getSale();
    this.checkoutService.updateOtherService(this.otherServiceId, data).subscribe(
      (res) => {
        showMessage(PopupType.Success, this.translateService.instant("otherservices.updated_otherservices"));
      },
      (error) => {
        showMessage(PopupType.Danger, error.toString());
      }
    );
  }

  submitForm() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      showMessage(PopupType.Warning, this.translateService.instant("transfer.fill_all_fields"));
      return;
    }
    this.updateOtherServices();
  }

  formattedDate(date: any) {
    const parsedDateTime = moment(date, "YYYY-MM-DD HH:mm");
    const formattedDateTime = parsedDateTime.format("DD.MM.YYYY HH:mm");
    return formattedDateTime;
  }

  isVisibleTab(index: number) {
    return { display: this.activeTab === index ? "block" : "none" };
  }

  async proceedToNextStage(nextStep: number | undefined = undefined) {
    this.customer = await this.customerComponent.getCustomer();
    this.sale = await this.saleComponent.getSale();
    this.activeTab = nextStep ?? this.activeTab + 1;
  }
}
