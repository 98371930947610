<div class="space-y-8">
  <div class="panel pb-0">
    <div class="mb-5 flex flex-col gap-5 md:flex-row md:items-center">
      <div>
        <input [(ngModel)]="search" (keydown.enter)="onSearchEnter()" type="text" class="form-input" placeholder="{{ 'search_placeholder' | translate }}" />
      </div>
      <div class="flex items-center gap-2 ltr:ml-auto rtl:mr-auto">
        <button type="button" class="btn btn-outline-success flex" (click)="openModalForCreating()">
          <icon-plus class="ltr:mr-2 rtl:ml-2" />
          {{ "worker.add_worker" | translate }}
        </button>
        <div>
          <modal #createWorkerModal class="modal-top">
            <ng-template #modalHeader>
              <div class="!py-5" *ngIf="!isEdit">
                {{ "worker.add_worker" | translate }}
              </div>
              <div class="!py-5" *ngIf="isEdit">
                {{ "worker.edit_worker" | translate }}
              </div>
            </ng-template>
            <ng-template #modalBody>
              <div class="grid grid-cols-1 gap-5 md:grid-cols-1">
                <app-worker-form [id]="userId" (submissionDone)="closeModal()" #workerForm></app-worker-form>
              </div>
            </ng-template>
          </modal>
        </div>
        <div hlMenu class="dropdown">
          <button hlMenuButton type="button" class="flex items-center rounded-md border border-[#e0e6ed] px-4 py-2 text-sm font-semibold dark:border-[#253b5c] dark:bg-[#1b2e4b] dark:text-white-dark">
            <span class="ltr:mr-1 rtl:ml-1">{{ "table_columns" | translate }}</span>
            <icon-caret-down class="h-5 w-5" />
          </button>
          <ul *hlMenuItems @toggleAnimation class="w-max ltr:left-0 rtl:right-0">
            <ng-container *ngFor="let col of cols; index as i">
              <li>
                <div class="flex items-center px-4 py-1">
                  <label [for]="'chk-' + i" class="mb-0 cursor-pointer">
                    <input type="checkbox" [id]="'chk-' + i" class="form-checkbox" [(ngModel)]="!col.hide" (change)="updateColumn(col)" />
                    <span class="ltr:ml-2 rtl:mr-2">{{ col.title }}</span>
                  </label>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>

    <div class="datatable">
      <ng-datatable
        [rows]="items"
        [columns]="cols"
        [sortable]="true"
        [search]="search"
        [pageSize]="pageSize"
        [totalRows]="totalRows"
        [loading]="loading"
        [isServerMode]="true"
        (changeServer)="changeServer($event)"
        skin="whitespace-nowrap table-hover"
        [paginationInfo]="'paginationInfoLabel' | translate"
        [noDataContent]="'empty_tables' | translate"
      >
        <ng-template slot="name" let-value="data">
          <span>{{ value.surname }}, {{ value.name }}</span>
        </ng-template>
        <ng-template slot="createdAt" let-value="data">
          <span>{{ value.createdAt | date : "dd.MM.yyyy" }}</span>
        </ng-template>
        <ng-template slot="id" let-value="data">
          <span>{{ value.id }}</span>
        </ng-template>
        <ng-template slot="email" let-value="data">
          <span>{{ value.email }}</span>
        </ng-template>
        <ng-template slot="role" let-value="data">
          <span>{{ value.role }}</span>
        </ng-template>
        <ng-template slot="officeBranch" let-value="data">
          <span
            >{{ value.officeBranch.name }} <span *ngIf="value.isOfficeBranchManager" [ngxTippy]="translate.instant('worker_info.manager')"><icon-star></icon-star></span
          ></span>
        </ng-template>
        <ng-template slot="actions" let-value="data">
          <div class="flex items-center gap-1">
            <div>
              <a [routerLink]="'/dashboard/workers/' + value.id" type="button" [ngxTippy]="translate.instant('worker_info.stats')" class="btn btn-dark h-10 w-10 rounded-full p-0">
                <icon-eye />
              </a>
            </div>
            <div>
              <a type="button" class="btn btn-dark h-10 w-10 rounded-full p-0" [ngxTippy]="translate.instant('worker_info.edit')" (click)="openModalForEditing(value.id)">
                <icon-pencil />
              </a>
            </div>
            <div>
              <a type="button" [ngxTippy]="translate.instant('worker_info.delete')" class="btn btn-dark h-10 w-10 rounded-full p-0" (click)="deleteWorker(value.id)">
                <icon-trash-lines />
              </a>
            </div>
          </div>
        </ng-template>
        <ng-template slot="actions" let-value="data">
          <div class="flex w-max items-center justify-between">
            <div hlMenu class="dropdown">
              <a hlMenuButton href="javascript:;">
                <icon-horizontal-dots class="rotate-90 opacity-70" />
              </a>
              <div #dropdownPlaceholder *hlMenuItems>
                <app-message-portal #messagePortalComponent @toggleAnimation (portalAttached)="onPortalAttached()">
                  <li>
                    <a href="javascript:;" *hlMenuItem="let menuItem" [routerLink]="'/dashboard/workers/' + value.id">
                      <icon-eye class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                      {{ "details_quick_label" | translate }}
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" *hlMenuItem="let menuItem" (click)="openModalForEditing(value.id)">
                      <icon-pencil class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                      {{ "edit_quick_label" | translate }}
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" *hlMenuItem="let menuItem" (click)="deleteWorker(value.id)">
                      <icon-trash-lines class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                      {{ "delete_quick_label" | translate }}
                    </a>
                  </li>
                </app-message-portal>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-datatable>
    </div>
    <app-office-branch-table *appHasRole="['Admin']"></app-office-branch-table>
  </div>
</div>
