import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StatisticsMonthly } from 'src/app/domain/statisticsmoney/models/statisticmoneymonthly';

@Component({
  selector: 'app-montly-money-chart',
  templateUrl: './montly-money-chart.component.html',
})
export class MontlyMoneyChartComponent implements OnInit, OnChanges{

	@Input() montlyearningmoney: StatisticsMonthly | undefined;
  simpleColumnStackedSeries: any;
  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.updateChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['montlyearningmoney'] && changes['montlyearningmoney'].currentValue) {
      this.updateChart();
    }
  }
	getMonthName(monthNumber: number): string {
    return this.translate.instant(`months.${monthNumber}`);
  }

  updateChart() {
    if (this.montlyearningmoney && this.montlyearningmoney.monthlyServiceStats) {
      const monthlyStats = this.montlyearningmoney.monthlyServiceStats;
      const months = monthlyStats.map(item => this.getMonthName(item.month));
      const ticketCount = monthlyStats.map(item => item.ticketCount);
      const travelCount = monthlyStats.map(item => item.transferCount);
      const groupTravelCount = monthlyStats.map(item => item.groupTravelCount);
      const transferCount = monthlyStats.map(item => item.transferCount);

          const isDark = true; 
      const isRtl = false;

      this.simpleColumnStackedSeries = {
				series: [
          { name: this.translate.instant('statisticsChart.ticketCount'), data: ticketCount },
          { name: this.translate.instant('statisticsChart.travelCount'), data: travelCount },
          { name: this.translate.instant('statisticsChart.groupTravelCount'), data: groupTravelCount },
          { name: this.translate.instant('statisticsChart.transferCount'), data: transferCount }
        ],
        chart: {
          height: 350,
          type: "bar",
          zoom: { enabled: false },
          toolbar: { show: false }
        },
        dataLabels: { enabled: false },
        stroke: { show: true, width: 2, colors: ["transparent"] },
        colors: ["#2196f3", "#3b3f5c", "#ff9800", "#4caf50"],
        xaxis: {
          categories: months,
          axisBorder: { color: isDark ? "#191e3a" : "#e0e6ed" }
        },
        yaxis: {
          opposite: isRtl,
          labels: { offsetX: isRtl ? -10 : 0 }
        },
        grid: { borderColor: isDark ? "#ADD8E6" : "silver" },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded"
          }
        },
        tooltip: {
          theme: isDark ? "dark" : "light",
          y: { formatter: (val: any) => val }
        }
      };
    }
  }
}
