import { Component, ViewChild } from "@angular/core";
import { OtherServices } from "../../models/otherservices";
import { OtherServicesService } from "../../services/other-services.service";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { FileUploadComponent } from "src/app/shared/components/file-upload/file-upload.component";

@Component({
	selector: "app-other-services.details",
	templateUrl: "./other-services.details.component.html",
})
export class OtherServicesDetailsComponent {
	isShowingSidebar = false;
	protected id!: number;
	protected otherService!: OtherServices;

	constructor(private otherserviceService: OtherServicesService, private route: ActivatedRoute, public translate: TranslateService) { }
	ngOnInit() {
		this.route.params.subscribe((params) => {
			this.id = parseInt(params["id"]);
			if (this.id > 0) {
				this.fetchOtherServiceData();
			}
		});
	}

	fetchOtherServiceData() {
		this.otherserviceService.getOtherServiceData(this.id).subscribe((response) => {
			this.otherService = response;
		});
	}
}
