import * as moment from "moment";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import { Ticket } from "../models/ticket";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { TicketsService } from "../services/tickets.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { animate, style, transition, trigger } from "@angular/animations";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PicklistType, EntityPicklistType } from "src/app/shared/enums/picklist-types";
import { dateFormatValidator, dateTimeFormatValidator } from "src/app/shared/validators/date.validator";
import { UtilService } from "src/app/shared/services/util.service";

@Component({
  selector: "app-ticket-create",
  templateUrl: "./ticket.create.component.html",
  animations: [
    trigger("toggleAnimation", [
      transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
      transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
    ]),
  ],
})
export class TicketCreateComponent implements OnInit {
  PicklistType = PicklistType;
  EntityPicklistType = EntityPicklistType;

  public id: number | undefined;

  public form!: FormGroup;
  public ticket!: Ticket;
  public isSubmitForm = false;
  private oneWaySubscription: Subscription | undefined;

  @Input() defaultTicket: Ticket = {} as Ticket;
  @Output() submitTicket: EventEmitter<Ticket> = new EventEmitter<Ticket>();

  constructor(public fb: FormBuilder, public route: ActivatedRoute, public store: Store<AppState>, public translate: TranslateService, private ticketService: TicketsService, public utilService: UtilService) {}

  initFormIntern(ticket: Ticket | undefined = undefined) {
    const { id = this.id, oneWay = true, destination, departure, departureDate, arrivalDate, company, reservationDate = moment().format("DD.MM.YYYY"), type } = ticket || {};
    this.id = id;
    this.form = this.fb.group({
      oneWay: [oneWay],
      destination: [destination, Validators.required],
      departure: [departure, Validators.required],
      departureDate: [departureDate, { validators: [Validators.required, dateTimeFormatValidator()], updateOn: "blur" }],
      arrivalDate: [arrivalDate],
      company: [company, Validators.required],
      reservationDate: [reservationDate, { validators: [dateFormatValidator()], updateOn: "blur" }],
      type: [type],
    });
    this.oneWaySubscription = this.form.get("oneWay")?.valueChanges.subscribe((value) => {
      const arrivalDateControl = this.form.get("arrivalDate");
      if (arrivalDateControl) {
        if (value) {
          arrivalDateControl.clearValidators();
        } else {
          arrivalDateControl.setValidators([Validators.required, dateFormatValidator()]);
        }
        arrivalDateControl.updateValueAndValidity();
      }
    });
  }

  dateTimeMask = [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, ":", /\d/, /\d/];
  dateMask = [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/];

  submitForm() {
    this.isSubmitForm = true;
    this.submitTicket.emit(this.form.getRawValue());
  }

  checkIfValid(): boolean {
    this.form.updateValueAndValidity();
    return this.form.valid;
  }

  getTicket(): Ticket {
    return { ...this.ticket, ...this.form.getRawValue() };
  }

  initForm(ticket: Ticket) {
    this.ticket = ticket;
    this.initFormIntern(ticket);
  }

  ngOnInit(): void {
    this.initFormIntern({} as Ticket);
    this.route.params.subscribe((params) => {
      this.form.get("type")?.setValue(params["type"]);
    });
  }

  ngOnDestroy() {
    if (this.oneWaySubscription) {
      this.oneWaySubscription.unsubscribe();
    }
  }
}
