import { Travel } from "../models/travel";
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Customer } from "../../customers/models/customer";
import { TravelsService } from "../services/travels.service";
import { animate, style, transition, trigger } from "@angular/animations";
import { Router } from "@angular/router";
import { FormBuilder, Validators } from "@angular/forms";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { PopupType } from "src/app/shared/enums/popup-types";
import { ModalComponent } from "angular-custom-modal";
import { FileUploadComponent } from "src/app/shared/components/file-upload/file-upload.component";

@Component({
	selector: "app-travel-details",
	templateUrl: "./travel.details.component.html",
	animations: [
		trigger("toggleAnimation", [
			transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
			transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
		]),
	],
})
export class TravelDetailsComponent implements OnInit {
	isShowingSidebar = false;
	public id!: number;
	protected travel!: Travel;
	protected passangers!: Customer[];
	public planId!: number;
	public entityname = "travels";

	private constractId!: number;

	@ViewChild("modal21") modal!: ModalComponent;
	constructor(private travelService: TravelsService, private route: ActivatedRoute, private router: Router, public translate: TranslateService, public fb: FormBuilder) { }
	form = this.fb.group({
		confirmationNumber: ["", Validators.required],
		insuranceNumber: ["", Validators.required],
		sumInRSD: ["", Validators.required],
	});

	ngOnInit() {
		this.route.params.subscribe((params) => {
			this.id = parseInt(params["id"]);
			if (this.id > 0) {
				this.fetchTravelData();
				this.fetchPassangers();
			}
		});
	}




	fetchTravelData() {
		this.travelService.getTravelData(this.id).subscribe((response) => {
			this.travel = response;
			this.constractId = this.travel?.contractId;
			this.planId = this.travel.planId;
		});
	}

	fetchPassangers() {
		this.travelService.getPassangersData(this.id).subscribe((response) => {
			this.passangers = response;
		});
	}
	navigateToCreateVoucherPage() {
		this.router.navigate([`/dashboard/travels/${this.id}/voucher-list`]);
	}

	navigateToContractPage() {
		this.router.navigate([`/dashboard/travels/${this.id}/contract`]);
	}

	getAccountData() {
		const { confirmationNumber, insuranceNumber, sumInRSD } = this.form.value;
		return {
			confirmationNumber,
			insuranceNumber,
			sumInRSD,
		};
	}

	save() {
		if (this.form.invalid) {
			showMessage(PopupType.Warning, this.translate.instant("popup.please_fill_all_required_fields"));
			this.form.markAllAsTouched();
			return;
		}
		this.createAccountData();
	}
	createAccountData() {
		this.travelService.createAccountData(this.id, this.getAccountData()).subscribe(
			(response) => {
				showMessage(PopupType.Success, this.translate.instant("popup.accounting_data_created_successfully"));
				this.modal.close();
			},
			(error) => {
				showMessage(PopupType.Danger, this.translate.instant("popup.error_while_creating_accounting_data"));
			}
		);
	}

}
