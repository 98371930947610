<div class="space-y-8"></div>

<div class="panel mt-5">
  <form class="space-y-5" [formGroup]="form" (ngSubmit)="submitForm()" novalidate>
    <div class="grid grid-cols-1 gap-5 md:grid-cols-2">
      <div [ngClass]="form.controls['name'].touched ? (form.controls['name'].errors ? 'has-error' : 'has-success') : ''">
        <label for="name">{{ "group_travel_create.name" | translate }}</label>
        <input id="name" type="text" placeholder="{{ 'group_travel_create.enter_name' | translate }}" class="form-input" formControlName="name" />
        <ng-container *ngIf="form.controls['name'].touched && form.controls['name'].errors">
          <p class="mt-1 text-danger">{{ "group_travel_create.please_provide_name" | translate }}</p>
        </ng-container>
      </div>
			<div [ngClass]="form.controls['organizer'].touched ? (form.controls['organizer'].errors ? 'has-error' : 'has-success') : ''">
				<label for="customOrganizer">{{ "travel_create.organizer" | translate }}</label>
				<app-autocomplete-remote
					id="customOrganizer"
					[type]="EntityPicklistType.Organizers"
					labelName="name"
					placeholder="{{ 'travel_create.enter_organizer' | translate }}"
					[selectControl]="form"
					labelForId="customOrganizer"
					formControlName="organizer"
					[addCallback]="utilService.organizerAdd('organizer', form).bind(utilService)"
				></app-autocomplete-remote>
				<ng-container *ngIf="form.controls['organizer'].touched && !form.controls['organizer'].errors">
					<p class="mt-1 text-[#1abc9c]">{{ "travel_create.looks_good" | translate }}</p>
				</ng-container>
				<ng-container *ngIf="form.controls['organizer'].touched && form.controls['organizer'].errors">
					<p class="mt-1 text-danger">{{ "travel_create.please_provide_organizer" | translate }}</p>
				</ng-container>
			</div>
      <div [ngClass]="form.controls['departure'].touched ? (form.controls['departure'].errors ? 'has-error' : 'has-success') : ''">
        <label for="customDeparture">{{ "group_travel_create.departure" | translate }}</label>
        <app-autocomplete-remote
          [type]="EntityPicklistType.Destinations"
          labelName="description"
          placeholder="{{ 'travel_create.enter_departure' | translate }}"
          [selectControl]="form"
          labelForId="customDeparture"
          formControlName="departure"
          [addCallback]="utilService.destinationAdd('departure', form).bind(utilService)"
        ></app-autocomplete-remote>
        <ng-container *ngIf="form.controls['departure'].touched && !form.controls['departure'].errors">
          <p class="mt-1 text-[#1abc9c]">{{ "travel_create.looks_good" | translate }}</p>
        </ng-container>
        <ng-container *ngIf="form.controls['departure'].touched && form.controls['departure'].errors">
          <p class="mt-1 text-danger">{{ "travel_create.please_provide_departure" | translate }}</p>
        </ng-container>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-5 md:grid-cols-2">
      <div [ngClass]="form.controls['start'].touched ? (form.controls['start'].errors ? 'has-error' : 'has-success') : ''">
        <label for="travelStart">{{ "group_travel_create.start" | translate }}</label>
        <input id="travelStart" type="text" class="form-input" formControlName="start" placeholder="__.__.____" [textMask]="{ mask: dateMask }" />
        <ng-container *ngIf="form.controls['start'].touched && !form.controls['start'].errors">
          <p class="mt-1 text-[#1abc9c]">{{ "group_travel_create.looks_good" | translate }}</p>
        </ng-container>
        <ng-container *ngIf="form.controls['start'].touched && form.controls['start'].errors">
          <p class="mt-1 text-danger">{{ "group_travel_create.please_provide_start" | translate }}</p>
        </ng-container>
      </div>
      <div [ngClass]="form.controls['end'].touched ? (form.controls['end'].errors ? 'has-error' : 'has-success') : ''">
        <label for="travelEnd">{{ "group_travel_create.end" | translate }}</label>
        <input id="travelEnd" type="text" class="form-input" formControlName="end" placeholder="__.__.____" [textMask]="{ mask: dateMask }" />
        <ng-container *ngIf="form.controls['end'].touched && !form.controls['end'].errors">
          <p class="mt-1 text-[#1abc9c]">{{ "group_travel_create.looks_good" | translate }}</p>
        </ng-container>
        <ng-container *ngIf="form.controls['end'].touched && form.controls['end'].errors">
          <p class="mt-1 text-danger">{{ "group_travel_create.please_provide_end" | translate }}</p>
        </ng-container>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-5 md:grid-cols-2">
      <div [ngClass]="form.controls['neto'].touched ? (form.controls['neto'].errors ? 'has-error' : 'has-success') : ''">
        <label for="customNeto">{{ "group_travel_create.neto" | translate }}</label>
        <div class="flex">
          <div
            class="flex items-center justify-center border border-[#e0e6ed] bg-[#eee] px-3 font-semibold dark:border-[#17263c] dark:bg-[#1b2e4b] ltr:rounded-l-md ltr:border-r-0 rtl:rounded-r-md rtl:border-l-0"
          >
            €
          </div>
          <input
            type="number"
            pattern="[0-9]*"
            appOnlyNumber
            autocomplete="off"
            inputmode="numeric"
            id="customNeto"
            placeholder="{{ 'group_travel_create.enter_neto' | translate }}"
            formControlName="neto"
            class="form-input flex-1 rounded-l-none rounded-r border-l-0"
          />
        </div>
        <ng-container *ngIf="form.controls['neto'].touched && !form.controls['neto'].errors">
          <p class="mt-1 text-[#1abc9c]">{{ "group_travel_create.looks_good" | translate }}</p>
        </ng-container>
        <ng-container *ngIf="form.controls['neto'].touched && form.controls['neto'].errors">
          <p class="mt-1 text-danger">{{ "group_travel_create.please_fill_neto" | translate }}</p>
        </ng-container>
      </div>
      <div [ngClass]="form.controls['bruto'].touched ? (form.controls['bruto'].errors ? 'has-error' : 'has-success') : ''">
        <label for="customBruto">{{ "group_travel_create.bruto" | translate }}</label>
        <div class="flex">
          <div
            class="flex items-center justify-center border border-[#e0e6ed] bg-[#eee] px-3 font-semibold dark:border-[#17263c] dark:bg-[#1b2e4b] ltr:rounded-l-md ltr:border-r-0 rtl:rounded-r-md rtl:border-l-0"
          >
            €
          </div>
          <input
            id="customBruto"
            type="number"
            placeholder="{{ 'group_travel_create.enter_bruto' | translate }}"
            formControlName="bruto"
            class="form-input flex-1 ltr:rounded-l-none ltr:rounded-l-none rtl:rounded-r-none rtl:rounded-r-none rtl:border-l-0"
          />
        </div>
        <ng-container *ngIf="form.controls['bruto'].touched && !form.controls['bruto'].errors">
          <p class="mt-1 text-[#1abc9c]">{{ "group_travel_create.looks_good" | translate }}</p>
        </ng-container>
        <ng-container *ngIf="form.controls['bruto'].touched && form.controls['bruto'].errors && !form.controls['bruto'].errors['brutoLessThanNeto']">
          <p class="mt-1 text-danger">{{ "group_travel_create.please_fill_bruto" | translate }}</p>
        </ng-container>
        <ng-container *ngIf="form.controls['bruto'].touched && form.controls['bruto'].errors && form.controls['bruto'].errors['brutoLessThanNeto']">
          <p class="mt-1 text-danger">{{ "group_travel_create.bruto_less_then_neto" | translate }}</p>
        </ng-container>
      </div>
    </div>
		<div class="relative px-5">
			<div class="bg-gray-100 p-5 rounded-xl">
				<app-travel-destination-create (submitDestination)="handleDestinationForMain($event)"></app-travel-destination-create>
			</div>
		</div>
    <div class="mt-3 grid grid-cols-1">
			<div class="flex gap-3">
				<h3 class="font-bold">
					{{ "group_travel_create.destination" | translate }}
				</h3>
				<button (click)="addDestination()" ngxTippy="Add more destination">
					<icon-plus></icon-plus>
				</button>
			</div>
    </div>
    <div class="grid grid-cols-1">
      <!-- <h3 class="font-bold">{{ "group_travel_create.destination" | translate }}</h3>
      <hr /> -->
      <div class="mt-3 grid">
        <div class="my-5 block space-y-4 overflow-x-auto rounded-lg border border-white-dark/20 p-4" *ngIf="groupTravelDestinations.length > 0">
          <ng-container *ngFor="let item of groupTravelDestinations; index as i" id="index">
            <div
              [ngClass]="{ 'bg-green-100': editDestination && editDestination.guid === item.guid }"
              [id]="i"
              class="flex min-w-[625px] items-center justify-between rounded-xl p-3 font-semibold text-gray-500 shadow-[0_0_4px_2px_rgb(31_45_61_/_10%)] transition-all duration-300 hover:scale-[1.01] hover:text-primary dark:bg-[#1b2e4b]"
            >
              <div class="user-profile">
                <icon-location></icon-location>
              </div>
              <div *ngIf="item.destination">{{ item.destination.description }}</div>
              <div *ngIf="item.hotel">Hotel: {{ item.hotel.name }}</div>
              <div *ngIf="item.service">Usluga: {{ item.service.title }}</div>
              <div *ngIf="item.destination">{{ item.destination.description }}</div>
              <div *ngIf="!item.destination">{{ "group_travel_create.destination" | translate }} {{ i + 1 }}</div>
              <div class="px-2">{{ item.start }} - {{ item.end }}</div>
              <ng-template #backUp> </ng-template>
              <div>
                <a type="button" class="btn btn-dark h-10 w-10 rounded-full p-0" [ngxTippy]="translate.instant('group_travel_create.edit')" (click)="toEditDestination(item)">
                  <icon-pencil />
                </a>
              </div>
              <div>
                <a type="button" [ngxTippy]="translate.instant('group_travel_create.delete')" class="btn btn-dark h-10 w-10 rounded-full p-0" (click)="removeDestination(item.guid)">
                  <icon-trash-lines />
                </a>
              </div>
            </div>
          </ng-container>
        </div>
        <hr />
        <div class="relative" *ngIf="editDestination">
          <hr />
          <app-group-destinations-form [defaultDestination]="editDestination" (submitDestination)="handleDestination($event)"> </app-group-destinations-form>
        </div>
      </div>
      <div class="mt-3 grid grid-cols-1">
        <button type="submit" class="btn btn-primary">{{ "group_travel_create.create_group_travel" | translate }}</button>
      </div>
    </div>
  </form>
</div>
