import { Store } from "@ngrx/store";
import { User } from "../auth/model/user";
import { FlatpickrOptions } from "ng2-flatpickr";
import { Serbian } from "flatpickr/dist/l10n/sr";
import { Component, OnInit } from "@angular/core";
import { AppService } from "../service/app.service";
import { TranslateService } from "@ngx-translate/core";
import { Router, NavigationEnd } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { setFromTo } from "../store/datepicker/datepicker.actions";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { animate, style, transition, trigger } from "@angular/animations";
import { DateService } from "../shared/services/date.service";
import { DateTimeFormatter } from "../shared/utils/datetime.formatter";

@Component({
  moduleId: module.id,
  selector: "app-header",
  templateUrl: "./header.html",
  animations: [
    trigger("toggleAnimation", [
      transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
      transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
    ]),
  ],
})
export class HeaderComponent implements OnInit {
  store: any;
  basic!: FlatpickrOptions;
  range!: FlatpickrOptions;
  datetimeForm!: FormGroup;
  pickerType = false;
  search = false;
  user!: User;
  selectedDate: any;

  cars!: any[];
  user$ = this.storeData.select((d) => d.auth);

  constructor(
    public translate: TranslateService,
    public storeData: Store<any>,
    public router: Router,
    private appSetting: AppService,
    private sanitizer: DomSanitizer,
    public fb: FormBuilder,
    private dateService: DateService
  ) {
    this.initStore();

    this.datetimeForm = fb.group({
      basicPicker: new FormControl(),
      rangePicker: new FormControl(),
    });

    this.user$.subscribe((auth) => {
      this.user = auth.user;
    });

    const { range, lastDayOfLastYearFormatted } = this.generateDateRange();

    this.basic = {
      defaultDate: lastDayOfLastYearFormatted,
      dateFormat: "d. M Y",
      position: this.store.rtlClass === "rtl" ? "auto right" : "auto left",
      locale: Serbian,
      onChange: this.handleDateChange.bind(this),
    };

    this.range = {
      defaultDate: range,
      dateFormat: "d. M Y",
      mode: "range",
      position: this.store.rtlClass === "rtl" ? "auto right" : "auto left",
      locale: Serbian,
      onChange: this.handleDateChange.bind(this),
    };
  }

  handlePickerChange(selectedDates: Date[], dateStr: string, instance: any) {
    if (selectedDates.length === 2) {
      const from = selectedDates[0];
      const to = selectedDates[1];
      this.storeData.dispatch(setFromTo({ from, to }));
    }
  }
  handleDateChange(selectedDates: Date[]) {
    if (selectedDates.length === 2) {
      const from = selectedDates[0];
      const to = selectedDates[1];
      this.dateService.setFromToDate(from, to); // Use DateService to dispatch action
    } else if (selectedDates.length === 1) {
      const from = selectedDates[0];
      this.dateService.setFromDate(from); // Use DateService to dispatch action
    }
  }

  navigateToCalendar() {
    const currentDate = new Date();
    const month = currentDate.getMonth();
    const year = currentDate.getFullYear();

    this.router.navigate(["/dashboard/calendar"], {
      queryParams: { month: month, year: year },
    });
  }

  async initStore() {
    this.storeData
      .select((d) => d.index)
      .subscribe((d) => {
        this.store = d;
      });
  }

  ngOnInit() {
    this.setActiveDropdown();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setActiveDropdown();
      }
    });

    this.cars = [
      { id: false, name: "Filtriraj od Datuma" },
      { id: true, name: "Filtriraj Od Do" },
    ];
  }

  setActiveDropdown() {
    const selector = document.querySelector('ul.horizontal-menu a[routerLink="' + window.location.pathname + '"]');
    if (selector) {
      selector.classList.add("active");
      const all: any = document.querySelectorAll("ul.horizontal-menu .nav-link.active");
      for (let i = 0; all.length && i < all.length; i++) {
        all[i]?.classList.remove("active");
      }
      const ul: any = selector.closest("ul.sub-menu");
      if (ul) {
        let ele: any = ul.closest("li.menu").querySelectorAll(".nav-link");
        if (ele) {
          ele = ele[0];
          setTimeout(() => {
            ele?.classList.add("active");
          });
        }
      }
    }
  }

  changeLanguage(item: any) {
    this.translate.use(item.code);
    this.appSetting.toggleLanguage(item);
    if (this.store.locale?.toLowerCase() === "ae") {
      this.storeData.dispatch({ type: "toggleRTL", payload: "rtl" });
    } else {
      this.storeData.dispatch({ type: "toggleRTL", payload: "ltr" });
    }
    window.location.reload();
  }

  generateDateRange(): { range: string; lastDayOfLastYearFormatted: string } {
    const lastDayOfLastYear = new Date(new Date().getFullYear() - 1, 11, 31); // Last day of last year
    const firstDayOfNewYear = new Date(new Date().getFullYear(), 0, 1); // First day of current year

    const lastDayOfLastYearFormatted = this.formatDate(lastDayOfLastYear);
    const firstDayOfNewYearFormatted = this.formatDate(firstDayOfNewYear);
    const range = `${lastDayOfLastYearFormatted} to ${firstDayOfNewYearFormatted}`;

    return { range, lastDayOfLastYearFormatted };
  }

  formatDate(date: Date): string {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Avg", "Sep", "Okt", "Nov", "Dec"];
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    return `${day}. ${monthNames[monthIndex]} ${year}`;
  }

  navigateToProfile() {
    this.router.navigate(["/dashobard/user-profile"]);
  }
}
