import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Destination } from "../models/destination";
import { DestinationsService } from "../services/destinations.service";
import { colDef } from "@bhplugin/ng-datatable";
import { animate, style, transition, trigger } from "@angular/animations";
import { TranslateService } from "@ngx-translate/core";
import { BaseTableComponent, DatatableColumn } from "src/app/shared/components/base-table/base-table.component";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/store/index.selector";
import { TableService } from "src/app/shared/services/table.service";
import { selectFromTo } from "src/app/store/datepicker/datetime.selector";
import { map, Observable } from "rxjs";
import { ModalComponent } from "angular-custom-modal";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { PopupType } from "src/app/shared/enums/popup-types";
import { MessagePortalComponent } from "src/app/portal/portal";
import { PositioningService } from "src/app/shared/services/positioning.service";
import { ModalService } from "src/app/shared/services/modal.service";
@Component({
  templateUrl: "./destinations.table.component.html",
  animations: [
    trigger("toggleAnimation", [
      transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
      transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
    ]),
  ],
})
export class DestinationsTableComponent extends BaseTableComponent<Destination> {
  public destinations: Destination[] = [];

  foundDestination: Destination[] = [];
  filteredDestination: Destination[] = [];
  selectedDestinationIds: number[] = [];
  destination!: Observable<Destination[]>;
  mergeMode = false;
  description: any = "";
  term: any;
  destinationId = 0;
  selectedDestination: Destination[] = [];
  @ViewChild("modal") modal!: ModalComponent;
  @ViewChild("dropdownPlaceholder") dropdownPlaceholder!: ElementRef;
  @ViewChild(MessagePortalComponent) messagePortalComponent!: MessagePortalComponent;

  onPortalAttached() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 0);
  }

  constructor(
    private positioningService: PositioningService,
    private destinationsService: DestinationsService,
    public override translate: TranslateService,
    public override store: Store<AppState>,
    public override tableService: TableService,
    private modalService: ModalService
  ) {
    super(store, translate, selectFromTo, tableService, `destinations/paginated`);
  }

  override getColumns(): void {
    this.cols = [
      { field: "id", title: this.translate.instant("destination_info.id"), isUnique: true, hide: true } as DatatableColumn,
      { field: "description", title: this.translate.instant("destination_info.description"), hide: false } as DatatableColumn,
      { field: "numberOfTravels", title: this.translate.instant("destination_info.number_of_travels"), hide: false, sort: false } as DatatableColumn,
      { field: "numberOfTickets", title: this.translate.instant("destination_info.number_of_tickets"), hide: false, sort: false } as DatatableColumn,
      { field: "destinationBrutoSum", title: this.translate.instant("destination_info.destination_bruto_sum"), hide: false, sort: false } as DatatableColumn,
      { field: "destinationNetoSum", title: this.translate.instant("destination_info.destination_neto_sum"), hide: false, sort: false } as DatatableColumn,
      { field: "passangersCount", title: this.translate.instant("destination_info.passangers_count"), hide: false, sort: false } as DatatableColumn,
      { field: "actions", title: this.translate.instant("destination_info.actions"), hide: false, sort: false } as DatatableColumn,
    ];
  }
  exportCols = [
    { field: "name", title: this.translate.instant("destination_export.name"), hide: false, sort: false } as DatatableColumn,
    { field: "numberOfTravels", title: this.translate.instant("destination_export.numberOfTravels"), hide: false } as DatatableColumn,
    { field: "numberOfTickets", title: this.translate.instant("destination_export.numberOfTickets"), hide: false } as DatatableColumn,
    { field: "bruto", title: this.translate.instant("destination_export.bruto"), hide: false, sort: false } as DatatableColumn,
    { field: "neto", title: this.translate.instant("destination_export.neto"), hide: false, sort: false } as DatatableColumn,
    { field: "passangersCount", title: this.translate.instant("destination_export.passangersCount"), hide: false } as DatatableColumn,
  ];

  override ngOnInit(): void {
    super.ngOnInit();
    this.loadDestinations();
  }

  // Delete request is missing
  loadDestinations() {
    this.filteredDestination = [];
    this.foundDestination = [];
    this.selectedDestinationIds = [];
    this.destination = this.destinationsService.getDestinations().pipe(map((company: Destination[]) => company.filter((organizer) => organizer.id !== this.destinationId)));
  }
  addMerge(id: number, description: any) {
    this.destinationId = id;
    this.description = description;
    this.mergeMode = true;
    this.modal.open();
    this.foundDestination = [];
    this.filteredDestination = [];
    this.term = "";
  }
  clearSearchTerm() {
    this.term = "";
  }

  mergeSelectedDestination() {
    this.selectedDestinationIds = this.selectedDestination.map((person: Destination) => person.id);

    if (this.selectedDestinationIds.length > 0 && this.destinationId) {
      this.destinationsService.mergeDestination(this.destinationId, this.selectedDestinationIds).subscribe((response) => {
        if (response.includes("Merged destinations")) {
          showMessage(PopupType.Success, this.translate.instant("hotel_data.hotels_merged_successfully"));
          this.modal.close();
        } else {
          showMessage(PopupType.Warning, this.translate.instant("hotel_data.error_merging_hotels"));
        }
      });
    } else {
      showMessage(PopupType.Warning, this.translate.instant("hotel_data.select_hotels_to_merge"));
    }
  }
}
