import * as moment from "moment";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { GroupTravelsService } from "../services/group-travels.service";
import { EntityPicklistType } from "src/app/shared/enums/picklist-types";
import { animate, style, transition, trigger } from "@angular/animations";
import { DateTimeFormatter } from "src/app/shared/utils/datetime.formatter";
import { GroupTravel, GroupTravelDestination } from "../models/group-travel";
import { dateFormatValidator } from "src/app/shared/validators/date.validator";
import { brutoLessThanNetoValidator } from "src/app/shared/validators/sale.validator";
import { UtilService } from "src/app/shared/services/util.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-group-travel",
  templateUrl: "./group-travel.component.html",
  animations: [
    trigger("toggleAnimation", [
      transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
      transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
    ]),
  ],
})
export class GroupTravelComponent implements OnInit {
  EntityPicklistType = EntityPicklistType;
  id!: number;

  public form!: FormGroup;
  public isShowedForm = false;
  selectedIndex: number | null = null;
  groupTravelDestinations: GroupTravelDestination[] = [];
  public editDestination!: GroupTravelDestination | undefined;
  constructor(
    public fb: FormBuilder,
    public groupTravelService: GroupTravelsService,
    private router: Router,
    private route: ActivatedRoute,
    public utilService: UtilService,
    public translate: TranslateService
  ) {}

  dateMask = [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/];

  submitForm() {
    if (this.form.invalid) {
      showMessage(PopupType.Warning, "Please fill all required fields");
      this.form.markAllAsTouched();
      return;
    } else if (this.groupTravelDestinations.length === 0) {
      showMessage(PopupType.Warning, "Please add at least one destination");
      return;
    }

    if (this.id) {
      this.groupTravelService.updateGroupTravel(this.id, this.createGroupTravelObject()).subscribe((r) => {
        this.router.navigate(["dashboard/group-travels"]);
      });
    } else {
      this.groupTravelService.createGroupTravel(this.createGroupTravelObject()).subscribe((r) => {
        this.router.navigate(["dashboard/group-travels"]);
      });
    }
  }

  ngOnInit(): void {
    this.initForm({} as GroupTravel);
    this.route.params.subscribe((params) => {
      this.id = params["id"];
      if (this.router.url.includes("/edit")) {
        this.fetchEntities();
      }
    });
  }

  fetchEntities() {
    this.groupTravelService.getGroupTravelData(this.id).subscribe((response) => {
      const { groupTravelDestinations } = response;
      this.initForm(DateTimeFormatter.formatGroupTravelDates(response));
      // const { passangers, customer, plan, travelDestinations } = response;

      for (const travelDestination of groupTravelDestinations) {
        this.groupTravelDestinations.push(
          DateTimeFormatter.formatGroupTravelDestinationDates({
            ...travelDestination,
          } as GroupTravelDestination)
        );
      }
    });
  }

  initForm(groupTravel: GroupTravel) {
    const { name, departure, start, end, bruto, neto } = groupTravel || ({} as GroupTravel);
    this.form = this.fb.group(
      {
        name: [name, Validators.required],
        departure: [departure, Validators.required],
        start: [start, { validators: [Validators.required, dateFormatValidator()], updateOn: "blur" }],
        end: [end, { validators: [Validators.required, dateFormatValidator()], updateOn: "blur" }],
        bruto: [bruto, Validators.required],
        neto: [neto, Validators.required],
        organizer: [null, Validators.required],
      },
      { validators: brutoLessThanNetoValidator }
    );
  }

  addDestination() {
    this.groupTravelDestinations.push({
      id: 0,
      guid: crypto.randomUUID(),
      start: "",
      end: "",
      days: "",
      nights: "",
      notes: "",
      service: undefined,
      destination: undefined,
      hotel: undefined,
      travelId: undefined,
      serviceId: undefined,
      destinationId: undefined,
      hotelId: undefined,
      createdAt: undefined,
      updatedAt: undefined,
      roomDescription: undefined,
      voucher: undefined,
      mainDestination: false,
    });
  }

  toEditDestination(destination: GroupTravelDestination) {
    this.editDestination = undefined;
    setTimeout(() => {
      this.editDestination = destination;
    });
  }

  removeDestination(id: string) {
    this.editDestination = undefined;
    this.groupTravelDestinations = this.groupTravelDestinations.filter((e) => e.guid != id);
    showMessage(PopupType.Success, "Destination removed successfully");
  }

  handleDestination(destination: any) {
    const updatedDestinations = this.groupTravelDestinations.map((e) => {
      if (e.guid === destination.guid) {
        return { ...e, ...destination };
      }
      return e;
    });

    this.groupTravelDestinations = updatedDestinations;

    showMessage(PopupType.Success, "Destination saved successfully");
    this.editDestination = undefined;
  }

  createGroupTravelObject(): any {
    const { name, departure, start, end, bruto, neto, organizer } = this.form.getRawValue();
    const groupDestinations = this.groupTravelDestinations.map((e) => {
      return {
        start: moment(e.start, "DD.MM.YYYY").toISOString(),
        end: moment(e.end, "DD.MM.YYYY").toISOString(),
        days: e.days,
        nights: e.nights,
        notes: e.notes,
        serviceId: e.service?.id,
        destinationId: e.destination?.id,
        hotelId: e.hotel?.id,
      };
    });

    return {
      name,
      bruto,
      neto,
      start: moment(start, "DD.MM.YYYY").toISOString(),
      end: moment(end, "DD.MM.YYYY").toISOString(),
      departureId: departure?.id,
      groupTravelDestinations: groupDestinations,
      organizerId: organizer?.id,
    };
  }

  handleDestinationForMain(destination: any) {
    this.groupTravelDestinations.push(destination);
    // const updatedDestinations = this.travelDestinations.map((e) => {
    //   if (e.guid === destination.guid) {
    //     return { ...e, ...destination };
    //   }
    //   return e;
    // });

    // this.travelDestinations = updatedDestinations;

    showMessage(PopupType.Success, "Destination saved successfully");
    this.editDestination = undefined;
  }
}
