import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StatisticsYearlyEarnings } from 'src/app/domain/statisticsmoney/models/yearlyearning';

@Component({
  selector: 'app-yearly-earnings-chart',
  templateUrl: './yearly-earnings-chart.component.html'
})
export class YearlyEarningsChartComponent implements OnInit,OnChanges {
  @Input() simpleColumnStackedEarnings: StatisticsYearlyEarnings | undefined;
	simpleColumnStackedSeries: any;

	constructor(private translate: TranslateService) {}

  availableYears: number[] = [];
  selectedYear: number | undefined;


  ngOnInit(): void {
    this.updateChartYearlyEarnings();
  }
	ngOnChanges(changes: SimpleChanges): void {
    if (changes['simpleColumnStackedEarnings'] && changes['simpleColumnStackedEarnings'].currentValue) {
      this.updateChartYearlyEarnings();
    }
  }


  updateChartYearlyEarnings() {
    if (this.simpleColumnStackedEarnings && this.simpleColumnStackedEarnings.yearlyServiceEarningsStats) {
      const years = this.simpleColumnStackedEarnings.yearlyServiceEarningsStats.map((item: any) => item.year);
      const ticketEarnings = this.simpleColumnStackedEarnings.yearlyServiceEarningsStats.map((item: any) => Number(item.ticketEarnings));
      const travelEarnings = this.simpleColumnStackedEarnings.yearlyServiceEarningsStats.map((item: any) => item.travelEarnings);
      const groupTravelEarnings = this.simpleColumnStackedEarnings.yearlyServiceEarningsStats.map((item: any) => item.groupTravelEarnings);
      const transferEarnings = this.simpleColumnStackedEarnings.yearlyServiceEarningsStats.map((item: any) => item.transferEarnings);

      this.availableYears = Array.from(new Set(years));
      this.selectedYear = this.availableYears[this.availableYears.length - 1];

      const isDark = "dark" === "dark" || true;
      const isRtl = "rtl" === "rtl"; 

      this.simpleColumnStackedSeries = {
				series: [
          { name: this.translate.instant('earnings.ticket_earnings'), data: ticketEarnings },
          { name: this.translate.instant('earnings.travel_earnings'), data: travelEarnings },
          { name: this.translate.instant('earnings.group_travel_earnings'), data: groupTravelEarnings },
          { name: this.translate.instant('earnings.transfer_earnings'), data: transferEarnings }
        ],
        chart: {
          height: 350,
          type: "bar",
          zoom: { enabled: false },
          toolbar: { show: false },
        },
        dataLabels: { enabled: false },
        stroke: { show: true, width: 2, colors: ["transparent"] },
        colors: ["#2196f3", "#3b3f5c", "#ff9800", "#4caf50"],
        xaxis: {
          categories: years,
          axisBorder: { color: isDark ? "#191e3a" : "#e0e6ed" },
        },
        yaxis: {
          opposite: isRtl,
          labels: { offsetX: isRtl ? -10 : 0 },
        },
        grid: { borderColor: isDark ? "#ADD8E6" : "silver" },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        tooltip: {
          theme: isDark ? "dark" : "light",
          y: { formatter: (val: any) => val },
        },
      };
    }
  }
}
