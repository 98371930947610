import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-worker-sales-by-month",
	templateUrl: "./worker-sales-by-month.component.html",
})
export class WorkerSalesByMonthComponent implements OnChanges {
	@Input() bruto!: number;
	@Input() neto!: number;
	@Input() name!: string;
	@Input() data!: { year: number; month: number; numberOfPlans: number }[];
	@Output() monthSelected = new EventEmitter<{ year: number, month: number }>();

	columnChart: any;

	constructor(private translate: TranslateService) { }

	ngOnChanges(changes: SimpleChanges) {
		if (changes['data'] && changes['data'].currentValue) {
			const isDark = true;
			const isRtl = false;

			const dataByMonth = this.data.reduce((acc, item) => {
				const key = `${item.year}-${item.month}`;
				if (!acc[key]) {
					acc[key] = item.numberOfPlans;
				} else {
					acc[key] += item.numberOfPlans;
				}
				return acc;
			}, {} as { [key: string]: number });

			const categories = Object.keys(dataByMonth).map(key => {
				const month = parseInt(key.split('-')[1], 10);
				return this.translate.instant(`months.${month}`);
			});

			const series = [{
				name: 'Plans',
				data: Object.values(dataByMonth)
			}];

			this.columnChart = {
				series: series,
				chart: {
					height: 300,
					type: "bar",
					zoom: {
						enabled: false,
					},
					toolbar: {
						show: false,
					},
				},
				colors: ["#378CE7", "#67C6E3"],
				dataLabels: {
					enabled: false,
				},
				stroke: {
					show: true,
					width: 2,
					colors: ["transparent"],
				},
				plotOptions: {
					bar: {
						horizontal: false,
						columnWidth: "55%",
						endingShape: "rounded",
					},
				},
				grid: {
					borderColor: isDark ? "#378CE7" : "#67C6E3",
				},
				xaxis: {
					type: "category",
					categories: categories,
					axisBorder: {
						color: isDark ? "#378CE7" : "#67C6E3",
					},
				},
				yaxis: {
					opposite: isRtl ? true : false,
					labels: {
						offsetX: isRtl ? -10 : 0,
					},
				},
				tooltip: {
					theme: isDark ? "dark" : "light",
					y: {
						formatter: function (val: any) {
							return val;
						},
					},
				},
			};
		}
	}
}