import { ContractService } from "../services/contract.service";
import { Component, Input, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ModalComponent } from "angular-custom-modal";
import { Contract } from "../models/contract";

@Component({
	selector: "app-contract",
	templateUrl: "./contract.component.html",
})
export class ContractComponent {
	isShowingSidebar = false;
	@Input() contract!: Contract;
	@Input() editUrlNavigation!: string;
	public entityname = "contract";
	public id !: number;
	@ViewChild("modal") modal!: ModalComponent;

	constructor(public route: ActivatedRoute, public contractService: ContractService, public router: Router, public translate: TranslateService) { }

	editContract() {
		this.router.navigate([this.editUrlNavigation]);
	}

	sendContract() {
		this.modal.open();
	}

	printContract() {
		window.print();
	}

	finishSending() {
		this.modal.close();
	}
}
