import { Directive, Input, TemplateRef, ViewContainerRef, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { AuthService } from "../auth/service/auth.service";

@Directive({
  selector: "[appHasPackageRight]",
})
export class HasPackageRightsDirective implements OnDestroy {
  private packageConfig: any;
  private subscription: Subscription;
  private requiredFeature: any[] | undefined;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private authService: AuthService) {
    this.subscription = this.authService.getPackageConfiguration().subscribe((packageConfig: any) => {
      this.packageConfig = packageConfig;
      this.updateView();
    });
  }

  @Input()
  set appHasPackageRight(requiredFeature: any[] | undefined) {
    this.requiredFeature = requiredFeature;
    this.updateView();
  }

  private updateView() {
    // Handle when requiredFeature is undefined
    if (this.requiredFeature === undefined) {
      // console.log('Required feature is undefined, hiding content.');
      this.hide();
      return;
    }

    // Handle when requiredFeature is [undefined]
    if (this.requiredFeature.length === 1 && this.requiredFeature[0] === undefined) {
      // console.log('Required feature is [undefined], showing content.');
      this.show();
      return;
    }

    // Handle when requiredFeature is [undefined, true]
    if (this.requiredFeature.length === 2 && this.requiredFeature[0] === undefined && this.requiredFeature[1] === true) {
      // console.log('Required feature is [undefined, true], hiding content.');
      this.hide();
      return;
    }

    // Handle cases when requiredFeature has other values
    if (this.packageConfig) {
      const feature = this.requiredFeature![0];
      const disable = this.requiredFeature.length > 1 && this.requiredFeature[1] === true;

      let shouldShow = false;

      if (disable) {
        shouldShow = this.packageConfig[feature] === false;
      } else {
        shouldShow = this.packageConfig[feature] === true;
      }

      // Handle cases when there are exactly two elements in requiredFeature
      if (this.requiredFeature.length === 2) {
        const featureName = this.requiredFeature[0];
        const negate = this.requiredFeature[1] === true;

        if (negate) {
          shouldShow = this.packageConfig[featureName] !== true; // Show if the feature is not true
        } else {
          shouldShow = this.packageConfig[featureName] === true;
        }
      } else {
        shouldShow = this.packageConfig[feature] === true;
      }

      if (shouldShow) {
        this.show();
      } else {
        this.hide();
      }
    } else {
      this.show();
    }
  }

  private show() {
    if (this.viewContainer.length === 0) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  private hide() {
    if (this.viewContainer.length > 0) {
      this.viewContainer.clear();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
