<div class="panel ng-tns-c35-2 mb-4 mt-4 h-full sm:col-span-3 xl:col-span-2">
  <div class="ng-tns-c35-2 mb-5 flex items-start justify-between">
    <h5 class="ng-tns-c35-2 text-lg font-semibold dark:text-white-light">{{'worker.sale_category' | translate}}</h5>
  </div>
  <div class="ng-tns-c35-2 flex flex-col space-y-5">
    <div class="ng-tns-c35-2 flex items-center">
      <div class="ng-tns-c35-2 h-9 w-9">
        <div class="ng-tns-c35-2 flex h-9 w-9 items-center justify-center rounded-xl bg-primary/10 text-primary dark:bg-primary dark:text-white-light">
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#A0DEFF" class="bi bi-currency-exchange" viewBox="0 0 16 16">
						<path d="M0 5a5 5 0 0 0 4.027 4.905 6.5 6.5 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05q-.001-.07.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.5 3.5 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98q-.004.07-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5m16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674z"/>
					</svg>
					
        </div>
      </div>
      <div class="ng-tns-c35-2 w-full flex-initial px-3">
        <div class="w-summary-info ng-tns-c35-2 mb-1 flex justify-between font-semibold text-white-dark">
          <h6 class="ng-tns-c35-2">{{'worker.tickets_sold' | translate}} - {{tickets}}</h6>
          <p class="ng-tns-c35-2 text-xs ltr:ml-auto rtl:mr-auto">{{percentage1}}%</p>
        </div>
        <div class="ng-tns-c35-2">
          <div class="ng-tns-c35-2 h-5 w-full overflow-hidden rounded-full bg-dark-light p-1 shadow-3xl dark:bg-dark-light/10 dark:shadow-none">
            <div
              class="ng-tns-c35-2 relative h-full w-full rounded-full before:absolute before:inset-y-0 before:m-auto before:h-2 before:w-2 before:rounded-full before:bg-white ltr:before:right-0.5 rtl:before:left-0.5"
              style="background-color: #5ab2ff"
              [ngStyle]="{ 'width.%': percentage1}"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="ng-tns-c35-2 flex items-center">
      <div class="ng-tns-c35-2 h-9 w-9">
        <div class="ng-tns-c35-2 flex h-9 w-9 items-center justify-center rounded-xl bg-primary/10 text-danger dark:bg-danger dark:text-white-light">
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#A0DEFF" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
						<path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"/>
					</svg>
					
        </div>
				
      </div>
      <div class="ng-tns-c35-2 w-full flex-initial px-3">
        <div class="w-summary-info ng-tns-c35-2 mb-1 flex justify-between font-semibold text-white-dark">
          <h6 class="ng-tns-c35-2">{{ 'worker.travels_sold' | translate}} - {{travels}}</h6>
          <p class="ng-tns-c35-2 text-xs ltr:ml-auto rtl:mr-auto">{{percentage2}}%</p>
        </div>
        <div class="ng-tns-c35-2">
          <div class="ng-tns-c35-2 h-5 w-full overflow-hidden rounded-full bg-dark-light p-1 shadow-3xl dark:bg-dark-light/10 dark:shadow-none">
            <div
						class="ng-tns-c35-2 relative h-full w-full rounded-full before:absolute before:inset-y-0 before:m-auto before:h-2 before:w-2 before:rounded-full before:bg-white ltr:before:right-0.5 rtl:before:left-0.5"
						style="background-color: #5ab2ff"
              [ngStyle]="{'width.%': percentage2}"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="ng-tns-c35-2 flex items-center">
      <div class="ng-tns-c35-2 h-9 w-9">
        <div class="ng-tns-c35-2 flex h-9 w-9 items-center justify-center rounded-xl bg-primary/10 text-warning dark:bg-warning dark:text-white-light">
				
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#A0DEFF" class="bi bi-globe-europe-africa" viewBox="0 0 16 16">
						<path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0M3.668 2.501l-.288.646a.847.847 0 0 0 1.479.815l.245-.368a.81.81 0 0 1 1.034-.275.81.81 0 0 0 .724 0l.261-.13a1 1 0 0 1 .775-.05l.984.34q.118.04.243.054c.784.093.855.377.694.801-.155.41-.616.617-1.035.487l-.01-.003C8.274 4.663 7.748 4.5 6 4.5 4.8 4.5 3.5 5.62 3.5 7c0 1.96.826 2.166 1.696 2.382.46.115.935.233 1.304.618.449.467.393 1.181.339 1.877C6.755 12.96 6.674 14 8.5 14c1.75 0 3-3.5 3-4.5 0-.262.208-.468.444-.7.396-.392.87-.86.556-1.8-.097-.291-.396-.568-.641-.756-.174-.133-.207-.396-.052-.551a.33.33 0 0 1 .42-.042l1.085.724c.11.072.255.058.348-.035.15-.15.415-.083.489.117.16.43.445 1.05.849 1.357L15 8A7 7 0 1 1 3.668 2.501"/>
					</svg>					
        </div>
      </div>
      <div class="ng-tns-c35-2 w-full flex-initial px-3">
        <div class="w-summary-info ng-tns-c35-2 mb-1 flex justify-between font-semibold text-white-dark">
          <h6 class="ng-tns-c35-2">{{ 'worker.transfers_sold' | translate}} - {{transfer}}</h6>
          <p class="ng-tns-c35-2 text-xs ltr:ml-auto rtl:mr-auto">{{percentage3}}%</p>
        </div>
        <div class="ng-tns-c35-2">
          <div class="ng-tns-c35-2 h-5 w-full overflow-hidden rounded-full bg-dark-light p-1 shadow-3xl dark:bg-dark-light/10 dark:shadow-none">
            <div
              class="ng-tns-c35-2 relative h-full w-full rounded-full bg-gradient-to-r from-[#fe5f75] to-[#fc9842] before:absolute before:inset-y-0 before:m-auto before:h-2 before:w-2 before:rounded-full before:bg-white ltr:before:right-0.5 rtl:before:left-0.5"
              style="background-color: #67c6e3"
              [ngStyle]="{'width.%': percentage3}"
            ></div>
          </div>
        </div>
      </div>
    </div>
		<div class="ng-tns-c35-2 flex items-center">
      <div class="ng-tns-c35-2 h-9 w-9">
        <div class="ng-tns-c35-2 flex h-9 w-9 items-center justify-center rounded-xl bg-primary/10 text-danger dark:bg-danger dark:text-white-light">
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#A0DEFF" class="bi bi-geo-fill" viewBox="0 0 16 16">
						<path fill-rule="evenodd" d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.3 1.3 0 0 0-.37.265.3.3 0 0 0-.057.09V14l.002.008.016.033a.6.6 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.6.6 0 0 0 .146-.15l.015-.033L12 14v-.004a.3.3 0 0 0-.057-.09 1.3 1.3 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465s-2.462-.172-3.34-.465c-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411"/>
					</svg>
					
					
        </div>
      </div>
      <div class="ng-tns-c35-2 w-full flex-initial px-3">
        <div class="w-summary-info ng-tns-c35-2 mb-1 flex justify-between font-semibold text-white-dark">
          <h6 class="ng-tns-c35-2">{{ 'worker.group_travels_sold' | translate}} - {{grouptravel}}</h6>
          <p class="ng-tns-c35-2 text-xs ltr:ml-auto rtl:mr-auto">{{percentage4}}%</p>
        </div>
        <div class="ng-tns-c35-2">
          <div class="ng-tns-c35-2 h-5 w-full overflow-hidden rounded-full bg-dark-light p-1 shadow-3xl dark:bg-dark-light/10 dark:shadow-none">
            <div
              class="ng-tns-c35-2 relative h-full w-full rounded-full bg-gradient-to-r from-[#a71d31] to-[#3f0d12] before:absolute before:inset-y-0 before:m-auto before:h-2 before:w-2 before:rounded-full before:bg-white ltr:before:right-0.5 rtl:before:left-0.5"
              style="background-color: #a0deff"
              [ngStyle]="{'width.%': percentage4}"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
