import { Directive, Input, TemplateRef, ViewContainerRef, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { AuthService } from "../auth/service/auth.service";

@Directive({
  selector: "[appHasRole]",
})
export class HasRoleDirective implements OnDestroy {
  private userRole!: string;
  private subscription: Subscription;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private authService: AuthService) {
    this.subscription = this.authService.getUserRole().subscribe((role) => {
      this.userRole = role;
      this.updateView();
    });
  }

  @Input()
  set appHasRole(roles: string[] | undefined) {
    this.roles = roles;
    this.updateView();
  }

  private roles: string[] | undefined;

  private updateView() {
    if (!this.roles || this.roles.length === 0 || this.roles.includes(this.userRole)) {
      if (this.viewContainer.length === 0) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    } else {
      this.viewContainer.clear();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
