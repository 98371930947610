import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { AngJson2excelBtn } from "ang-json2excel-btn";

@Component({
  selector: "app-export-to-excel-btn",
  templateUrl: "./export-to-excel.component.html",
  styleUrls: ["./export-to-excel.component.css"],
})
export class ExportToExcelComponent implements OnInit {
  @Input() fileName!: string;
  @Input() jsonData!: { [key: string]: any }[];
  @ViewChild("angJson2Excel") angJson2Excel!: AngJson2excelBtn;

  @Output() destroyCallback = new EventEmitter<void>();

  ngOnInit(): void {
    setTimeout(() => {
      this.angJson2Excel.json2ExcelInit();
    });
    this.destroyCallback.emit();
  }
}
