import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { TravelDestination } from "../../travels/models/travel-destination";
import { GroupTravelsService } from "src/app/domain/group-travels/services/group-travels.service";
import { VoucherService } from "../../documents/voucher/services/voucher.service";

@Component({
  selector: "app-group-travel-voucher-list",
  templateUrl: "./group-travel-voucher-list.component.html",
})
export class GroupTravelVoucherListComponent implements OnInit {
  destinations: TravelDestination[] = [];
  groupTravelPlanId?: number;

  voucherTab!: number;
  selectedDestination: any;

  constructor(public groupTravelService: GroupTravelsService, private route: ActivatedRoute, private voucherService: VoucherService) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.groupTravelPlanId = Number(params.get("id"));
      this.getVoucherData();
    });
  }

  getVoucherData() {
    this.voucherService.getVoucherByGroupTravel(this.groupTravelPlanId ?? 0).subscribe((response) => {
      this.destinations = response.map((p: any) => {
        p.groupTravelDestination.voucher = p.voucher;
        return p.groupTravelDestination as TravelDestination;
      });
    });
  }

  editVoucherUrlTemplate() {
    const groupTravelPlanId = this.groupTravelPlanId;
    return (id: number) => {
      return `/dashboard/group-travels/${groupTravelPlanId}/voucher/${id}/edit`;
    };
  }
}
