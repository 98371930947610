import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { CompaniesService } from "../services/companies.service";
import { colDef } from "@bhplugin/ng-datatable";
import { Company } from "../models/company";
import { animate, style, transition, trigger } from "@angular/animations";
import { TranslateService } from "@ngx-translate/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalComponent } from "angular-custom-modal";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { PopupType } from "src/app/shared/enums/popup-types";
import { BaseTableComponent, DatatableColumn } from "src/app/shared/components/base-table/base-table.component";
import { TableService } from "src/app/shared/services/table.service";
import { selectFromTo } from "src/app/store/datepicker/datetime.selector";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/store/index.selector";
import { map, Observable } from "rxjs";
import { MessagePortalComponent } from "src/app/portal/portal";
import { PositioningService } from "src/app/shared/services/positioning.service";
import { ModalService } from "src/app/shared/services/modal.service";

@Component({
  templateUrl: "./companies.table.component.html",
  animations: [
    trigger("toggleAnimation", [
      transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
      transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
    ]),
  ],
})
export class CompaniesTableComponent extends BaseTableComponent<Company> {
  public companies: Company[] = [];
  editMode = false;
  companyId = 0;
  form!: FormGroup;
  foundCompany: Company[] = [];
  filteredCompanies: Company[] = [];
  selectedCompanyIds: number[] = [];
  company!: Observable<Company[]>;
  mergeMode = false;
  name: any = "";
  term: any;
  selectedCompany: Company[] = [];

  @ViewChild("dropdownPlaceholder") dropdownPlaceholder!: ElementRef;
  @ViewChild(MessagePortalComponent) messagePortalComponent!: MessagePortalComponent;

  onPortalAttached() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 0);
  }

  @ViewChild("modal") modal!: ModalComponent;
  constructor(
    private modalService: ModalService,
    public companiesService: CompaniesService,
    public override translate: TranslateService,
    private fb: FormBuilder,
    public override tableService: TableService,
    public override store: Store<AppState>,
    private positioningService: PositioningService,
    private ref: ChangeDetectorRef
  ) {
    super(store, translate, selectFromTo, tableService, `companies/paginated`);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.initForm();
    this.loadCompanies();
  }
  loadCompanies() {
    this.filteredCompanies = [];
    this.foundCompany = [];
    this.selectedCompanyIds = [];
    this.company = this.companiesService.getCompany().pipe(map((company: Company[]) => company.filter((organizer) => organizer.id !== this.companyId)));
  }
  addMerge(id: number, name: any) {
    this.companyId = id;
    this.name = name;
    this.mergeMode = true;
    this.editMode = false;
    this.modal.open();
    this.foundCompany = [];
    this.filteredCompanies = [];
    this.term = "";
  }
  clearSearchTerm() {
    this.term = "";
  }
  initForm() {
    this.form = this.fb.group({
      name: ["", Validators.required],
    });
  }

  addCompany() {
    this.editMode = false;
    this.modal.open();
  }

  updateCompany(id: number) {
    this.companyId = id;
    this.editMode = true;
    this.modal.open();
  }

  submitForm() {
    if (this.form.valid) {
      if (this.editMode) {
        this.updateCompanyData();
      } else {
        this.addCompanyData();
      }
    } else {
      this.form.markAllAsTouched();
    }
  }

  addCompanyData() {
    this.companiesService.addCompany(this.form.value).subscribe(() => {
      this.modal.close();
      this.fetchItems();
      showMessage(PopupType.Success, this.translate.instant("company_data.added_company_successfully"));
    });
  }

  updateCompanyData() {
    this.companiesService.updateCompany(this.companyId, this.form.value).subscribe(() => {
      this.modal.close();
      this.fetchItems();
      showMessage(PopupType.Success, this.translate.instant("company_data.updated_company_successfully"));
    });
  }

  override getColumns(): void {
    this.cols = [
      { field: "id", title: this.translate.instant("company_info.id"), isUnique: true, hide: true } as DatatableColumn,
      { field: "name", title: this.translate.instant("company_info.name"), hide: false } as DatatableColumn,
      { field: "ticketsCount", title: this.translate.instant("company_info.num_of_tickets"), hide: false, sort: false } as DatatableColumn,
      { field: "createdAt", title: this.translate.instant("company_info.created_at"), hide: false } as DatatableColumn,
      { field: "numberOfPeople", title: this.translate.instant("company_info.numberOfPeople"), hide: false } as DatatableColumn,
      { field: "earnings", title: this.translate.instant("company_info.earnings"), hide: false } as DatatableColumn,
      { field: "actions", title: this.translate.instant("company_info.actions"), hide: false, sort: false } as DatatableColumn,
    ];
  }
  exportCols = [
    { field: "name", title: this.translate.instant("company_export.name"), hide: false, sort: false } as DatatableColumn,
    { field: "numOfTickets", title: this.translate.instant("company_export.numOfTickets"), hide: false } as DatatableColumn,
    { field: "createdAt", title: this.translate.instant("company_export.createdAt"), hide: false } as DatatableColumn,
    { field: "numberOfPeople", title: this.translate.instant("company_export.numberOfPeople"), hide: false } as DatatableColumn,
    { field: "bruto", title: this.translate.instant("company_export.bruto"), hide: false, sort: false } as DatatableColumn,
    { field: "neto", title: this.translate.instant("company_export.neto"), hide: false, sort: false } as DatatableColumn,
    { field: "profit", title: this.translate.instant("company_export.profit"), hide: false } as DatatableColumn,
    { field: "purchaseDate", title: this.translate.instant("company_export.purchaseDate"), hide: false } as DatatableColumn,
    { field: "user", title: this.translate.instant("company_export.user"), hide: false } as DatatableColumn,
  ];

  async deleteCompany(id: number) {
    const confirmed = await this.modalService.confirm();
    if (confirmed) {
      this.companiesService.deleteCompany(id).subscribe(
        (res) => {
          this.ngOnInit();
        },
        (err) => showMessage(PopupType.Danger, err.toString())
      );
    }
  }
  mergeSelectedHotels() {
    this.selectedCompanyIds = this.selectedCompany.map((person: Company) => person.id);

    if (this.selectedCompanyIds.length > 0 && this.companyId) {
      this.companiesService.mergeCompany(this.companyId, this.selectedCompanyIds).subscribe((response) => {
        if (response.includes("Merged companies")) {
          showMessage(PopupType.Success, this.translate.instant("hotel_data.hotels_merged_successfully"));
          this.modal.close();
        } else {
          showMessage(PopupType.Warning, this.translate.instant("hotel_data.error_merging_hotels"));
        }
      });
    } else {
      showMessage(PopupType.Warning, this.translate.instant("hotel_data.select_hotels_to_merge"));
    }
  }
}
