import { Injectable, Injector, ComponentFactoryResolver, ApplicationRef } from "@angular/core";
import { ConfirmationDialogComponent } from "../components/confirmation-dialog/confirmation-dialog.component";

interface ConfirmationDialogOptions {
  title?: string;
  message?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

@Injectable({
  providedIn: "root",
})
export class ModalService {
  constructor(private injector: Injector, private resolver: ComponentFactoryResolver, private appRef: ApplicationRef) {}

  confirm(options: ConfirmationDialogOptions = {}): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const componentFactory = this.resolver.resolveComponentFactory(ConfirmationDialogComponent);
      const componentRef = componentFactory.create(this.injector);

      // Set the component properties
      componentRef.instance.title = options.title || "Confirmation";
      componentRef.instance.message = options.message || "Are you sure you want to proceed?";
      componentRef.instance.confirmButtonText = options.confirmButtonText || "Confirm";
      componentRef.instance.cancelButtonText = options.cancelButtonText || "Cancel";

      componentRef.instance.confirmed.subscribe((confirmed: boolean) => {
        resolve(confirmed);
        this.appRef.detachView(componentRef.hostView);
        componentRef.destroy();
      });

      this.appRef.attachView(componentRef.hostView);

      const domElem = (componentRef.hostView as any).rootNodes[0] as HTMLElement;
      document.body.appendChild(domElem);
    });
  }
}
