import { Component, Input, ViewChild, ViewContainerRef } from "@angular/core";

@Component({
  moduleId: module.id,
  selector: "icon-menu-other-services",
  styleUrls: ["../icons.css"],

  template: `
    <ng-template #template>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <g data-name="Roundtrip">
          <path
            class="st0"
            d="m119.82 195.734-9.6-17.49a290.973 290.973 0 0 0-29.386 18.514l11.631 16.21a270.451 270.451 0 0 1 27.354-17.234zM179.693 171.691l-5.15-19.275a288.984 288.984 0 0 0-32.945 10.976l7.435 18.514a269.117 269.117 0 0 1 30.66-10.215zM377.477 173.177a288.614 288.614 0 0 0-31.957-13.62l-6.696 18.794a267.457 267.457 0 0 1 29.736 12.672zM445.64 249.133a270.911 270.911 0 0 1 20.405 25.088l16.21-11.63a287.958 287.958 0 0 0-21.925-26.947zM396.55 207.212a268.38 268.38 0 0 1 25.815 19.471l12.954-15.171a286.55 286.55 0 0 0-27.75-20.928zM29.745 243.667l15.166 12.961a268.288 268.288 0 0 1 22.457-23.262l-13.49-14.692a288.336 288.336 0 0 0-24.133 24.993zM190.063 180.871l10.022.007 12.16-18.437 39.082.273-17.487 60.278a3.194 3.194 0 0 0 3.19 3.192h16.08l33.584-62.286 30.827 1.024c8.774 0 15.874-6.72 15.874-15.01.006-8.283-7.106-15.002-15.867-15.002l-31.056 1.038-33.36-61.879-16.086-.004a3.2 3.2 0 0 0-3.188 3.196l17.366 59.86-39.172.34-11.95-18.082-10.021.004a2.404 2.404 0 0 0-2.411 2.403v56.689a2.406 2.406 0 0 0 2.413 2.396zM90.726 437.93a158.817 158.817 0 0 1 3.794-42.536c6.53 1.692 35.72 9.792 34.793 19.185-.782 7.877-3.316 16.848-1.208 23.352h18.351c2.52-1.836 4.95-2.71 7.329-.21.062.07.146.14.213.21h144.91c-34.108-21.726-38.933-60.107-38.933-60.107-2.408-1.047 4.479-28.308-6.245-31.583-10.74-3.272-17.51-.65-32.718-7.435-15.228-6.787-9.525-16.269-7.025-38.899a82.466 82.466 0 0 1 6.657-25.094 159.242 159.242 0 0 1 63.071 1.56 158.121 158.121 0 0 1 122.916 158.722 95.211 95.211 0 0 1-4.167 2.84h23.994a177.982 177.982 0 0 0-138.314-181.008 178.833 178.833 0 0 0-39.68-4.485c-83.504 0-154.806 56.95-173.386 138.49a178.89 178.89 0 0 0-4.318 47.002h19.966z"
          />
        </g>
      </svg>
    </ng-template>
  `,
})
export class IconMenuOtherServicesComponent {
  @Input() class: any = "";
  @ViewChild("template", { static: true }) template: any;

  constructor(private viewContainerRef: ViewContainerRef) {}

  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.template);
    this.viewContainerRef.element.nativeElement.remove();
  }
}
