import { Office } from "../models/office-branch";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { FileUploadWithPreview } from "file-upload-with-preview";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { OfficeBranchService } from "../services/office-branch.service";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-office-branch-form",
	templateUrl: "./office-branch.form.component.html",
})
export class OfficeBranchFormComponent implements OnInit {
	@Output() submissionDone: EventEmitter<any> = new EventEmitter();

	public form!: FormGroup;
	public imageUpload!: FileUploadWithPreview;

	options = ["admin", "seller"];

	@Input() id!: number | undefined;
	constructor(public fb: FormBuilder, public officeBranchService: OfficeBranchService, public translate: TranslateService) { }

	initForm(office: Office): void {
		const { name, email, guid, phoneNumber, logoURL, siteURL: siteUrl, bankAccount, pib, senderEmail, senderName, adminEmail, password } = office || ({} as Office);

		this.form = this.fb.group({
			name: [name, Validators.required],
			guid: [guid, Validators.required],
			bankAccount: [bankAccount, Validators.required],
			PIB: [pib, Validators.required],
			phoneNumber: [phoneNumber, Validators.required],
			email: [email, Validators.email],
			siteUrl: [siteUrl, Validators.required],
			senderEmail: [senderEmail, Validators.email],
			senderName: [senderName, Validators.required],
			adminEmail: [adminEmail, Validators.email],
			password: [password, Validators.required],
		});

		setTimeout(() => {
			this.imageUpload = new FileUploadWithPreview("office-branch-logo", {
				maxFileCount: 1,
				presetFiles: logoURL ? [logoURL] : [],

				images: {
					baseImage: "/assets/images/file-preview.svg",
				},
				accept: "image/*",
				text: {
					browse: "Kliknite kako bi ste odabrali logo vase firme ",
					chooseFile: "Odaberite logo",
					label: "Vas logo",
				},
				showDeleteButtonOnImages: true,
			});
		});
		// this.imageUpload.clearButton =
	}

	submitForm(): void {
		if (!this.form.valid) {
			this.form.markAllAsTouched();
			return;
		}
		if (this.id) {
			this.fetchOffice();
			this.officeBranchService.updateOffice(this.id, this.fetchOffice()).subscribe(
				(response) => {
					if (this.imageUpload.cachedFileArray.length > 0) {
						this.officeBranchService.uploadOfficeLogo(response.id, this.imageUpload.cachedFileArray[0]).subscribe((res) => {
							showMessage(PopupType.Success, this.translate.instant("popup.office_branch_image_updated_successfully"));
						});
					}
					showMessage(PopupType.Success, this.translate.instant("popup.office_branch_updated_successfully"));
					this.initForm({} as Office);
					this.submissionDone.emit(null);
				},
				(error) => {
					showMessage(PopupType.Danger, error.toString());
				}
			);
		} else {
			this.officeBranchService.createOffice(this.fetchOffice()).subscribe(
				(response) => {
					if (this.imageUpload.cachedFileArray.length > 0) {
						this.officeBranchService.uploadOfficeLogo(response.id, this.imageUpload.cachedFileArray[0]).subscribe((res) => {
							showMessage(PopupType.Success, this.translate.instant("popup.office_branch_image_updated_successfully"));
						});
					}
					this.initForm({} as Office);
					this.submissionDone.emit(null);
				},
				(error) => {
					showMessage(PopupType.Danger, error.toString());
				}
			);
		}
	}

	ngOnInit(): void {
		if (this.id !== undefined) {
			this.officeBranchService.getOfficeById(this.id).subscribe((office) => {
				// this.form.patchValue(worker);
				this.initForm(office);
			});
		} else {
			this.initForm({} as Office);
		}
	}

	fetchOffice(): Office {
		const { name, email, guid, phoneNumber, logoURL, bankAccount, PIB, siteUrl, senderEmail, senderName, adminEmail, password } = this.form.getRawValue();

		return { name, email, guid, phoneNumber, logoURL, bankAccount, pib: PIB, siteURL: siteUrl, senderEmail, senderName, adminEmail, password } as Office;
	}
}
