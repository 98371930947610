import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DocumentService {
  static getContent(idSelector: string) {
    const content = document.getElementById(idSelector)?.outerHTML;
    const css = Array.from(document.styleSheets)
      .map((sheet) => {
        try {
          // Only process stylesheets from the same origin
          if (sheet.href && new URL(sheet.href).origin !== window.location.origin) {
            return "";
          }

          return Array.from(sheet.cssRules)
            .map((rule) => rule.cssText)
            .join("");
        } catch (e) {
          console.warn("Skipping stylesheet due to CORS or other access issues", e);
          return "";
        }
      })
      .join("");

    return { content, css };
  }

  static stringToHtmlFile(htmlContent: string, fileName: string): File {
    const blob = new Blob([htmlContent], { type: "text/html" });
    return new File([blob], fileName, { type: "text/html" });
  }

  static printReciept(idSelector: string) {
    const { css, content } = DocumentService.getContent(idSelector);
    const combinedContent = `
		<style>
			${css}
		</style>
		${content}
	`;
    const winPrint = window.open("", "", "left=0,top=0,width=1000,height=600,toolbar=0,scrollbars=0,status=0");
    winPrint?.document.write("<title>Print</title>" + combinedContent);
    winPrint?.document.close();
    winPrint?.focus();
    winPrint?.print();
  }
}
