import { Component, Input, ViewChild, ViewContainerRef } from "@angular/core";
@Component({
  moduleId: module.id,
  selector: "icon-menu-group-travel",
  styleUrls: ["../icons.css"],

  template: `
    <ng-template #template>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 20 20"
        style="enable-background:new 0 0 20 20;"
        xml:space="preserve"
      >
        <g>
          <path
            class="st0"
            d="M6.4,6.8C7.9,5.5,9.6,4.9,11.7,5c-0.1-0.4-0.3-0.7-0.3-1c-0.3-1.2,0-2.4,1-3c0.9-0.7,2.3-0.7,3.2,0
				 c0.9,0.7,1.4,1.9,0.9,3c-0.3,0.7-0.7,1.4-1.1,2.2c0.2,0.2,0.5,0.4,0.8,0.7c2.3,2.2,3,5.5,1.6,8.4c-1.3,2.8-4.4,4.5-7.3,4.1
				 c-3.3-0.4-5.8-2.8-6.3-6c-0.1-0.6-0.3-1-0.7-1.4c-0.6-0.7-1.1-1.4-1.5-2.1C1.2,8.6,1.6,7.3,2.6,6.5c1-0.8,2.4-0.7,3.5,0.2
				 C6.2,6.7,6.3,6.7,6.4,6.8z M16.7,8.4c-0.6-0.5-1.1-1-1.6-1.5c-0.1-0.1-0.4,0-0.5,0c-0.8,0.5-0.8,0.6-1.4-0.1
				 c-0.2-0.2-0.4-0.5-0.7-0.8c-0.2-0.2-0.4-0.3-0.6-0.4c-1.3-0.2-2.6,0.1-3.7,0.7C8,6.5,7.8,6.6,7.8,6.7C7.7,7.2,8.5,8,9,8
				 c0.5,0,0.8,0.2,0.7,0.7c-0.1,0.5,0.1,0.8,0.6,0.9c0.5,0.1,0.7,0.5,0.6,1c0,0.2,0,0.4,0,0.6c0.6,0.1,0.5-0.3,0.6-0.6
				 c0-0.3,0.1-0.9,0.3-0.9c0.5-0.2,1.1-0.2,1.6-0.3c0.3,0,0.7,0,0.9-0.2c0.6-0.5,1.2-1,2.1-0.6C16.4,8.5,16.4,8.5,16.7,8.4z M6.6,10.2
				 c1.4,0,2.1,1.2,1.6,2.2c-0.2,0.4-0.6,0.8,0,1.2c0.1,0.1,0,0.3,0,0.5c-0.1,1-0.5,1.8-1,2.6c-0.1,0.2-0.1,0.6,0,0.7
				 c0.4,0.4,0.9,0.6,1.5,1c0.1-0.7,0.2-1.3,0.4-1.8c0.1-0.3,0.3-0.6,0.6-0.8c0.3-0.2,0.3-0.4,0.1-0.6c-0.5-1.2-0.5-2.2,0.4-3.3
				 c0.5-0.6,0.2-1.6-0.4-1.9C9.3,9.8,9,9.6,9.1,9.1C9.1,9,9,8.8,9,8.6C7.6,9,7.7,7.6,7.1,7C6.9,7.2,6.7,7.5,6.8,7.6
				 C7.1,8.5,6.9,9.3,6.6,10.2z M14.9,13.4c-0.2,2-1.2,3.2-2.3,4.3c-0.3,0.3-0.6,0.3-0.9,0c-0.3-0.4-0.6-0.7-1-1.1
				 c-0.2-0.2-0.5-0.4-0.6-0.3c-0.2,0.1-0.4,0.4-0.5,0.6c-0.1,0.4-0.2,0.8-0.3,1.2c-0.1,0.4,0,0.6,0.4,0.7c2.8,0.7,6.1-0.8,7.4-3.5
				 c0.1-0.2,0.1-0.7-0.1-0.8C16.4,14.1,15.7,13.8,14.9,13.4z M17.7,13.8c0.3-1.4,0.2-2.8-0.4-4.3c0-0.1-0.2-0.2-0.3-0.3
				 c-0.8-0.2-1.6-0.5-2.2,0.3c-0.4,0.5-0.9,0.7-1.5,0.4c-0.1-0.1-0.4,0-0.6,0c-0.3,0-0.5,0.2-0.5,0.6c0,0.4,0.1,0.5,0.5,0.6
				 c0.8,0.1,1.3,0.6,1.8,1.2c0.2,0.3,0.5,0.5,0.9,0.6C16,13.3,16.8,13.5,17.7,13.8z M6.4,16.8c0.3-0.7,0.7-1.2,0.9-1.8
				 c0.2-0.5,0.5-1-0.2-1.4c-0.1,0-0.1-0.3,0-0.5c0.1-0.3,0.3-0.6,0.5-0.9c0.3-0.4,0.2-0.7-0.1-1.1c-0.3-0.4-0.6-0.3-1-0.2
				 c-0.9,0.3-1.2,1.2-1.9,1.8c0,0,0,0.2,0,0.2C4.8,14.4,5.3,15.6,6.4,16.8z M13.9,6.7c0.9-0.9,1.7-1.8,2-3c0.2-0.8-0.1-1.7-0.9-2.2
				 c-0.7-0.5-1.6-0.5-2.4,0c-0.7,0.5-1.1,1.4-0.9,2.2C12.2,4.9,13,5.8,13.9,6.7z M4.3,12.1c0.9-0.9,1.8-1.8,2.1-3.1
				 c0.2-0.9-0.1-1.6-0.8-2.1C4.7,6.4,4,6.4,3.2,6.8C2.5,7.3,2,8.1,2.2,8.9C2.4,10.2,3.3,11.2,4.3,12.1z M12.1,17.3
				 c0.9-0.9,1.7-1.7,2-2.9c0.3-0.9-0.1-1.8-0.8-2.3c-0.8-0.5-1.7-0.5-2.5,0c-0.7,0.5-1,1.5-0.7,2.3C10.5,15.5,11.2,16.4,12.1,17.3z"
          />
          <path
            class="st1"
            d="M16.7,8.4c-0.2,0.1-0.3,0.1-0.3,0.1c-0.9-0.3-1.4,0.1-2.1,0.6c-0.2,0.2-0.6,0.2-0.9,0.2
				 c-0.5,0.1-1.1,0.1-1.6,0.3c-0.2,0.1-0.2,0.6-0.3,0.9c0,0.4,0,0.7-0.6,0.6c0-0.2-0.1-0.4,0-0.6c0.1-0.5-0.2-0.8-0.6-1
				 C9.8,9.5,9.6,9.2,9.7,8.7C9.8,8.2,9.5,8,9,8C8.5,8,7.7,7.2,7.8,6.7c0-0.1,0.2-0.3,0.3-0.4c1.2-0.6,2.4-0.9,3.7-0.7
				 c0.2,0,0.5,0.2,0.6,0.4c0.3,0.2,0.4,0.5,0.7,0.8c0.7,0.7,0.6,0.7,1.4,0.1c0.1-0.1,0.4-0.1,0.5,0C15.6,7.3,16.1,7.8,16.7,8.4z"
          />
          <path
            class="st1"
            d="M6.6,10.2c0.4-0.9,0.6-1.7,0.2-2.6c0-0.1,0.1-0.3,0.3-0.6c0.7,0.6,0.6,2,2,1.6C9,8.8,9.1,9,9.1,9.1
				 C9,9.6,9.3,9.8,9.7,10c0.7,0.3,0.9,1.3,0.4,1.9c-0.8,1-0.9,2.1-0.4,3.3c0.1,0.3,0.2,0.4-0.1,0.6C9.3,16,9.1,16.3,9,16.6
				 c-0.2,0.5-0.2,1.1-0.4,1.8c-0.6-0.4-1.1-0.7-1.5-1c-0.1-0.1-0.1-0.5,0-0.7c0.5-0.8,0.9-1.6,1-2.6c0-0.2,0.1-0.4,0-0.5
				 c-0.6-0.4-0.2-0.8,0-1.2C8.7,11.3,7.9,10.2,6.6,10.2z"
          />
          <path
            class="st1"
            d="M14.9,13.4c0.8,0.4,1.5,0.7,2.1,1.1c0.2,0.1,0.2,0.6,0.1,0.8c-1.3,2.7-4.5,4.2-7.4,3.5
				 c-0.4-0.1-0.5-0.3-0.4-0.7c0.1-0.4,0.1-0.8,0.3-1.2c0.1-0.2,0.3-0.5,0.5-0.6c0.1-0.1,0.4,0.1,0.6,0.3c0.4,0.3,0.7,0.7,1,1.1
				 c0.3,0.3,0.6,0.3,0.9,0C13.7,16.5,14.7,15.3,14.9,13.4z"
          />
          <path
            class="st1"
            d="M17.7,13.8c-0.9-0.3-1.6-0.5-2.4-0.8c-0.3-0.1-0.7-0.4-0.9-0.6c-0.5-0.6-1-1.1-1.8-1.2
				 c-0.4-0.1-0.5-0.2-0.5-0.6c0-0.4,0.2-0.5,0.5-0.6c0.2,0,0.4-0.1,0.6,0c0.6,0.3,1.1,0.1,1.5-0.4c0.7-0.8,1.5-0.5,2.2-0.3
				 c0.1,0,0.2,0.1,0.3,0.3C17.9,10.9,18,12.4,17.7,13.8z"
          />
          <path
            class="st1"
            d="M6.4,16.8c-1.1-1.2-1.6-2.4-1.8-3.8c0-0.1,0-0.2,0-0.2c0.6-0.6,0.9-1.5,1.9-1.8c0.4-0.1,0.7-0.2,1,0.2
				 c0.3,0.4,0.4,0.7,0.1,1.1c-0.2,0.3-0.3,0.6-0.5,0.9c-0.1,0.1-0.1,0.4,0,0.5c0.7,0.4,0.3,1,0.2,1.4C7.1,15.6,6.7,16.1,6.4,16.8z"
          />
          <path
            class="st1"
            d="M13.9,6.7c-1-0.9-1.7-1.8-2.1-3c-0.2-0.8,0.2-1.7,0.9-2.2c0.7-0.5,1.6-0.5,2.4,0c0.7,0.5,1.1,1.4,0.9,2.2
				 C15.7,4.9,14.9,5.8,13.9,6.7z M12.4,3.2c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5
				 C13.1,1.7,12.4,2.3,12.4,3.2z"
          />
          <path
            class="st1"
            d="M4.3,12.1c-1-0.9-1.8-1.9-2.1-3.2C2,8.1,2.5,7.3,3.2,6.8C4,6.4,4.7,6.4,5.5,6.9c0.7,0.5,1,1.3,0.8,2.1
				 C6,10.3,5.2,11.2,4.3,12.1z M2.8,8.6c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5C3.4,7.1,2.8,7.8,2.8,8.6z
				 "
          />
          <path
            class="st1"
            d="M12.1,17.3c-0.9-0.9-1.6-1.7-2-2.8c-0.3-0.9,0-1.8,0.7-2.3c0.7-0.5,1.7-0.6,2.5,0c0.7,0.5,1.1,1.4,0.8,2.3
				 C13.8,15.5,13,16.4,12.1,17.3z M10.6,13.8c0,0.8,0.6,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5
				 C11.3,12.3,10.6,12.9,10.6,13.8z"
          />
          <path
            class="st0"
            d="M12.4,3.2c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5c0,0.8-0.7,1.5-1.5,1.5C13.1,4.7,12.4,4,12.4,3.2z
					M13.9,4.1c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.8-0.9c-0.5,0-0.9,0.3-0.9,0.8C13,3.6,13.4,4.1,13.9,4.1z"
          />
          <path
            class="st0"
            d="M2.8,8.6c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5c0,0.8-0.7,1.5-1.5,1.5C3.4,10.1,2.8,9.4,2.8,8.6z
					M4.3,9.5c0.5,0,0.9-0.5,0.9-0.9c0-0.5-0.5-0.9-1-0.8c-0.5,0-0.8,0.4-0.8,0.9C3.4,9.1,3.8,9.6,4.3,9.5z"
          />
          <path
            class="st0"
            d="M10.6,13.8c0-0.8,0.6-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5c0,0.8-0.7,1.5-1.5,1.5C11.3,15.2,10.6,14.6,10.6,13.8
				 z M11.2,13.8c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.5,0.9-0.9c0-0.5-0.3-0.8-0.9-0.9C11.6,12.9,11.2,13.3,11.2,13.8z"
          />
          <path class="st1" d="M13.9,4.1c-0.5,0-0.9-0.4-0.8-0.9c0-0.5,0.4-0.9,0.9-0.8c0.5,0,0.8,0.4,0.8,0.9C14.8,3.7,14.4,4.1,13.9,4.1z" />
          <path class="st1" d="M4.3,9.5c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.3-0.9,0.8-0.9c0.5,0,0.9,0.3,1,0.8C5.2,9.1,4.8,9.5,4.3,9.5z" />
          <path
            class="st1"
            d="M11.2,13.8c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.8,0.3,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9C11.6,14.7,11.2,14.3,11.2,13.8
				 z"
          />
        </g>
      </svg>
    </ng-template>
  `,
})
export class IconMenuGroupTravelComponent {
  @Input() class: any = "";
  @ViewChild("template", { static: true }) template: any;
  constructor(private viewContainerRef: ViewContainerRef) {}
  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.template);
    this.viewContainerRef.element.nativeElement.remove();
  }
}
